import React from 'react';
import { Container, MainContainer } from './styled';
import app from '../../images/qrapp.png';
import play from '../../images/qrplaystore.png';

export const CardDownload = (): JSX.Element => {
  return (
    <MainContainer>
      <div className='title-fav'>
        <h1>Download FEG App</h1>
      </div>
      <div className='itens'>
        <Container>
          <div className='list'>
            <img src={'/assets/qr-code-apple.png'} alt='FEG APPLE APP' />
            <button
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.fegapp',
                  '_blank'
                )
              }
            >
              ANDROID
            </button>
          </div>
        </Container>
        <Container>
          <div className='list'>
            <img src={'/assets/qr-code.png'} alt='FEG APPLE APP' />
            <button
              onClick={() =>
                window.open(
                  'https://apps.apple.com/us/app/feg-home/id6670215730',
                  '_blank'
                )
              }
            >
              APPLE
            </button>
          </div>
        </Container>
      </div>
    </MainContainer>
  );
};
