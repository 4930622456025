import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import api from 'src/services/axios';
import { BeatLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ContainerSettings = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-bottom: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  h1 {
    font-size: 22px;
  }
  button.btn-outlined {
    color: ${Theme.primary};
    background: white;
    border: none;
    :hover {
      color: ${Theme.secondary};
      background-color: ${Theme.primary};
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 26px 0;
`;

const Title = styled.h1`
  text-align: center;
  color: ${Theme.primary};
  font-size: 38px;
  margin-bottom: 6px;
`;

interface Categories {
  id: string;
  name: string;
  slug: string;
}

export default function SettingsChatBot(): React.ReactElement {
  const history = useHistory();
  const [categories, setCategories] = useState<Categories[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get('https://openai.fegsystem.com/categories?per_page=1000', {
        timeout: 0
      })
      .then((r) => {
        console.log(r.data.data);
        setCategories(r.data.data);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, []);

  return (
    <ContainerSettings>
      <ToastContainer />
      <Header>
        <Title>Knowledge Base</Title>
        <p>
          Click on each folder below to find relevant documents in regards to
          your department
        </p>
        {loading && (
          <BeatLoader
            style={{ marginTop: 20 }}
            size={25}
            color={`${Theme.primary}`}
          />
        )}
      </Header>

      <ContainerGrid>
        {categories.map((category) => {
          return (
            <>
              <Button
                variant='outlined'
                className='btn-outlined'
                onClick={() =>
                  history.push(`/knowledgebase/${category.id}/${category.slug}`)
                }
              >
                <h1>{category.name}</h1>
              </Button>
            </>
          );
        })}
      </ContainerGrid>
    </ContainerSettings>
  );
}
