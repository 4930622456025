import React, { useState, useRef, ReactNode } from 'react';
import styled from 'styled-components';

// Tipos para as props
interface PopoverProps {
  triggerContent: ReactNode;
  popoverContent: ReactNode;
}

// Estilização com styled-components
const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const PopoverTrigger = styled.div`
  display: inline-block;
  cursor: pointer;
`;

const PopoverContent = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 120%; /* Ajuste conforme necessário */
  left: 50%;

  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  z-index: 10;
  white-space: nowrap;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.2s ease, visibility 0.2s ease;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;

    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`;

// Componente Popover
const Popover: React.FC<PopoverProps> = ({
  triggerContent,
  popoverContent
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const showPopover = () => setIsVisible(true);
  const hidePopover = () => setIsVisible(false);

  return (
    <PopoverContainer onMouseEnter={showPopover} onMouseLeave={hidePopover}>
      <PopoverTrigger>{triggerContent}</PopoverTrigger>
      <PopoverContent isVisible={isVisible}>{popoverContent}</PopoverContent>
    </PopoverContainer>
  );
};

export default Popover;
