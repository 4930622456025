import React, { useEffect, useState } from 'react';
import { FaWandMagicSparkles } from 'react-icons/fa6';

import { CreateQuestionContainer } from './styled';
import DatePicker from 'react-date-picker';
import api from 'src/services/axios';
import { Theme } from 'src/styles/Theme';
import { BeatLoader } from 'react-spinners';
interface Option {
  id: number | string;
  text: string;
}

export default function CreateQuestion(): JSX.Element {
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState<Option[]>([{ id: 1, text: '' }]);
  const [correctAnswer, setCorrectAnswer] = useState<number | null | string>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [iaGenerated, setIaGenerated] = useState('');
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [notAvaliableDates, setNotAvaliableDates] = useState<Date[]>([
    new Date()
  ]);
  const [GenerateIaQuestion, setGenerateIaQuestion] = useState(false);

  const handleAddOption = () => {
    setOptions([...options, { id: options.length + 1, text: '' }]);
  };

  const handleOptionChange = (id: number | string, text: string) => {
    setOptions(options.map((opt) => (opt.id === id ? { ...opt, text } : opt)));
  };

  const formatedOption = () => {
    return options.map((opt) => ({ option: opt.text }));
  };

  const handleSaveQuestion = () => {
    if (!date) {
      alert('Please, select a date to publish the question');
      return;
    }
    if (question === '') {
      alert('Please, type a question answer');
      return;
    }
    if (options.length < 2) {
      alert('Please, add at least 2 options');
      return;
    }
    if (correctAnswer === null) {
      alert('Please, select a correct answer');
      return;
    }

    const newQuestion = {
      question: {
        question_answer: question,
        options: formatedOption(),
        correct_option_id: correctAnswer?.toString() || ''
      },
      date: date.toISOString()
    };

    api.post('/api/v1/trivia/create', newQuestion).then(() => {
      clearInputs();
      window.location.reload();
    });
  };

  function generateIaQuestion() {
    setLoading(true);
    api
      .get('https://openai.fegsystem.com/trivia', { timeout: 0 })
      .then((res) => {
        setLoading(false);
        if (res.data.result.answer) {
          setGenerateIaQuestion(true);
          setIaGenerated(res.data.result.answer);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  useEffect(() => {
    api.get('/api/v1/trivia/not-avaliable-dates').then((res) => {
      const dates = res.data.dates?.map((date: string) => new Date(date));
      console.log(dates);
      setNotAvaliableDates(dates);
    });
  }, []);

  const handleRemoveOption = (id: number | string) => {
    setOptions(options.filter((opt) => opt.id !== id));
    if (correctAnswer === id) {
      setCorrectAnswer(null);
    }
  };

  const clearInputs = () => {
    setQuestion('');
    setOptions([{ id: 1, text: '' }]);
    setCorrectAnswer(null);
    setDate(undefined);
  };

  const disableDates = ({ date }: any) => {
    // Função para adicionar um dia à data

    // Verifica se a data está na lista de datas bloqueadas (com 1 dia a mais)
    return notAvaliableDates.some((blockedDate) => {
      const blockedDateWithOneMoreDay = new Date(blockedDate);
      return (
        date.getFullYear() === blockedDateWithOneMoreDay.getFullYear() &&
        date.getMonth() === blockedDateWithOneMoreDay.getMonth() &&
        date.getDate() === blockedDateWithOneMoreDay.getDate()
      );
    });
  };

  return (
    <CreateQuestionContainer>
      <h2>Trivia</h2>

      <div className='header-create-question'>
        <label>Question:</label>
        <input
          type='text'
          className='answer input-create-question'
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder='Type your question here'
        />
      </div>

      <div>
        <label>Answer Options:</label>
        {options.map((option, index) => (
          <div key={option.id} className='input-option'>
            <input
              type='text'
              value={option.text}
              className='input-create-question'
              onChange={(e) => handleOptionChange(option.id, e.target.value)}
              placeholder={`Option ${index + 1}`}
            />
            <input
              type='radio'
              name='correctAnswer'
              checked={correctAnswer === option.id}
              onChange={() => {
                setCorrectAnswer(index + 1);
              }}
            />

            <button
              onClick={() => handleRemoveOption(option.id)}
              className='remove-btn'
            >
              Remove
            </button>
          </div>
        ))}

        <button onClick={handleAddOption} className='btn-new-option'>
          Add New Option
        </button>
      </div>

      <div>
        <p>Date to Publish:</p>
        <DatePicker
          value={date}
          onChange={(value: Date | Date[] | null) => {
            if (value instanceof Date) {
              setDate(value);
            }
          }}
          tileDisabled={disableDates}
          tileClassName={({ date, view }) => {
            if (view === 'month') {
              // Garante que estamos na visão mensal
              const day = date.getDay();
              if (day === 0 || day === 6) {
                return 'weekend'; // Adiciona uma classe personalizada
              }
            }
            return null;
          }}
        />
      </div>
      <div>
        <button onClick={() => generateIaQuestion()} className='gradiente'>
          <FaWandMagicSparkles />
          <p> Generate AI Question</p>
        </button>
        {loading && <BeatLoader color={`${Theme.primary}`} size={6} />}
      </div>
      {GenerateIaQuestion && (
        <div>
          <p>
            {iaGenerated.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>

          {!loading && (
            <button
              style={{
                padding: '10px',
                marginTop: '20px',
                backgroundColor: Theme.primary,
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '8px'
              }}
              onClick={() => generateIaQuestion()}
            >
              Re-Generate
            </button>
          )}
        </div>
      )}
      <button
        onClick={handleSaveQuestion}
        style={{
          padding: '10px',
          marginTop: '20px',
          backgroundColor: '#008947',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '8px'
        }}
      >
        Publish/Schedule Question
      </button>
    </CreateQuestionContainer>
  );
}
