import React, { useEffect, useState } from 'react';
import { Container, Grid } from './styled';
import api from 'src/services/axios';

import jwt from 'jsonwebtoken';
import { FiTrash } from 'react-icons/fi';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';


export default function GridNewsletter() {

  const [permissions2, setPermissions2] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false
  })
  const [announcements, setAnnouncements] = useState<any[]>([]);
  const [cookies, ,] = useCookies(["token"]);
  const token: any = cookies.token

  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(localStorage.getItem('token')!.toString(), '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb', (err: any, decoded: any) => {
        if (err) {
          alert(err)
        } else {
          setPermissions2(decoded.roles.announcements)
        }
      })
    } else {
      jwt.verify(token!.toString(), '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb', (err: any, decoded: any) => {
        if (err) {
          alert(err)
        } else {
          setPermissions2(decoded.roles.announcements)
        }
      })
    }
  }

  useEffect(() => {
    loadRequests()
    handlePermissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function handleDeleteAnnouncement(id: string) {
    try {

      await api.patch(`/api/v1/announcements/${id}`, {
        visibility: false
      })
      setAnnouncements(announcements.filter(e => e.id !== id));

      alert('Announcement deleted')

    } catch (error) {
      alert('Error')

    }
  }

  async function loadRequests() {
    try {
      await api.get(`api/v1/announcements/newsletter_announcements`, { timeout: 0 }).then(response => {

        setAnnouncements(response.data.results.filter((e: any) => e.is_visible === true));

      })
    } catch (error) {
      alert(error)
    }
  }

  return (
    <Container>

      {announcements &&
        announcements.map((data: any) => {
          return (
            <Grid key={Math.random()}>
              <div className='header'>
                <p>{data.createdAt}</p>
                <button style={{ display: permissions2.delete ? "" : "none" }} title="Delete Announcement" onClick={() => handleDeleteAnnouncement(data.id)} disabled={permissions2.delete ? false : true} ><FiTrash color='white' size={20} /></button>



              </div>
              <div
                className='content'

              >
                {data.url_image && <img src={data.url_image} alt='' />}

                <h1 className='text'>{data.subject}</h1>

                <button onClick={() => {
                }}>

                  <Link style={{ color: 'white' }} to={`/announcements/company/${data.id}`}> Read Full Newsletter</Link>
                </button>
              </div>
            </Grid>
          );
        })}
    </Container>
  );

}
