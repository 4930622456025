import React, { useEffect, useState } from 'react';
import { QuestionContainer } from './styled';
import { motion } from 'framer-motion';
import api from 'src/services/axios';
import DatePicker from 'react-date-picker';
interface Options {
  option: string;
  id: string;
}
interface Option {
  id: number | string;
  text: string;
}
interface Question {
  id: string;
  question_answer: string;
  options: Options[];
  correct_option_id: string;
  date: string;
  active: boolean;
}
export default function ViewQuestion({
  selectedQuestion,
  handleEditQuestion
}: {
  selectedQuestion: Question | undefined;
  handleEditQuestion: () => void;
}): JSX.Element {
  const [scheduleDate, setScheduleDate] = useState<Date | undefined>(undefined);
  const [notAvaliableDates, setNotAvaliableDates] = useState<Date[]>([
    new Date()
  ]);
  const [reschedule, setReschedule] = React.useState<boolean>(false);
  const handleDeleteQuestion = () => {
    console.log('Pergunta selecionada:', selectedQuestion);
    if (selectedQuestion) {
      api.delete(`/api/v1/trivia/delete/${selectedQuestion.id}`).then(() => {
        alert('Quizz Deleted');
        window.location.reload();
      });
    }
  };
  const formatedOption = () => {
    return selectedQuestion?.options.map((opt) => ({ option: opt.option }));
  };

  const handleSaveQuestion = () => {
    const correctOption = selectedQuestion?.options.findIndex(
      (obj) => obj.id === selectedQuestion?.correct_option_id
    );
    if (correctOption === undefined) return;
    const newQuestion = {
      question: {
        question_answer: selectedQuestion?.question_answer,
        options: formatedOption(),
        correct_option_id: correctOption + 1
      },
      date: scheduleDate
    };

    api.post('/api/v1/trivia/create', newQuestion).then(() => {
      window.location.reload();
    });
  };
  useEffect(() => {
    api.get('/api/v1/trivia/not-avaliable-dates').then((res) => {
      const dates = res.data.dates.map((date: string) => new Date(date));
      setNotAvaliableDates(dates);
    });
  }, []);

  const disableDates = ({ date }: any) => {
    // Função para adicionar um dia à data

    // Verifica se a data está na lista de datas bloqueadas (com 1 dia a mais)
    return notAvaliableDates.some((blockedDate) => {
      const blockedDateWithOneMoreDay = new Date(blockedDate);
      return (
        date.getFullYear() === blockedDateWithOneMoreDay.getFullYear() &&
        date.getMonth() === blockedDateWithOneMoreDay.getMonth() &&
        date.getDate() === blockedDateWithOneMoreDay.getDate()
      );
    });
  };

  return (
    <>
      <QuestionContainer>
        <div className='task'>
          <motion.h1 animate={{ opacity: [0.5, 1] }}>
            {selectedQuestion?.question_answer}
          </motion.h1>
          <motion.ul
            initial='hidden'
            animate='visible'
            className='card-responses'
          >
            {selectedQuestion?.options.map((option, index) => (
              <motion.li
                key={index}
                className={`responses realCorrectOption 
                            ${option.id === selectedQuestion.correct_option_id
                    ? 'correct'
                    : ''
                  }`}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <p>{option.option}</p>
              </motion.li>
            ))}
          </motion.ul>
        </div>
        {reschedule && (
          <div className='reschedule'>
            <div>
              <p>New Date to Publish:</p>
              <DatePicker
                value={scheduleDate}
                onChange={(value: Date | Date[] | null) => {
                  if (value instanceof Date) {
                    setScheduleDate(value);
                  }
                }}
                tileDisabled={disableDates}
              />
            </div>
            <button className='save' onClick={() => handleSaveQuestion()}>
              Save
            </button>
          </div>
        )}
        <div className='btn-actions'>
          <button className='btn' onClick={() => handleEditQuestion()}>
            Update Question
          </button>
          <button className='btn-delete' onClick={() => handleDeleteQuestion()}>
            Delete Question
          </button>
          <button onClick={() => setReschedule(!reschedule)}>Reschedule</button>
        </div>
      </QuestionContainer>
    </>
  );
}
