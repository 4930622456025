import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Theme } from 'src/styles/Theme';
import { BeatLoader } from 'react-spinners';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import api from 'src/services/axios';
import DocumentSources from 'src/components/DocumentSources';

const ChatContainer = styled.div`
  width: 70%;
  max-width: 1100px;
  margin: 30px auto;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);

  overflow: hidden;
  div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${Theme.light};
    button {
      width: 100px;
      height: 38px;
      border: none;
      background: white;
      color: ${Theme.primary};
      border-radius: 5px;
      font-weight: bold;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  div.content-menu {
    display: flex;
    margin: auto 0;
    button.btn-menu {
      width: min-content;
      padding: 4px;
    }
  }
  div.header div.title {
    display: flex;
    gap: 30px;
    padding: 25px 10px;
    align-items: center;
    height: 50px;
    h1 {
      color: ${Theme.primary};
      @media (max-width: 700px) {
        font-size: 18px;
      }
    }
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }
  @media (max-width: 500px) {
    margin-top: 30px;
    width: 100%;
  }
  @media (max-width: 700px) {
    width: 100%;
    max-width: 400px;
  }
`;

const ChatLog = styled.div`
  height: 450px;
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
`;

const ChatMessage = styled.div`
  display: flex;
  word-wrap: break-word;
  width: 100%;
  .active {
    margin: 0 0 0 auto;
    div {
      background-color: ${(props) =>
        props.role === 'user' ? `${Theme.primary}` : '#fff'};
      color: ${Theme.secondary};
      flex-direction: row-reverse;
    }
  }
  div {
    display: flex;
    gap: 20px;
    word-wrap: break-word;
    flex-direction: ${(props) =>
      props.role === 'user' ? 'initial' : 'row-reverse'};
    div {
      display: flex;
      background-color: #e53935;
      width: 100%;
      margin: 10px 0;
      word-wrap: break-word;
      max-width: max-content;
      background-color: ${Theme.light};
      padding: 10px;
      border-radius: ${(props) =>
        props.role === 'user' ? '10px 10px 0 10px' : '10px 10px 10px 0'};
    }
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }
`;

const ErrorMessage = styled.div`
  color: #fff;
  background-color: #e53935;
  padding: 8px;
  border-radius: 5px;
  margin-top: 5px;
`;

const UserInput = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
`;

const TextInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
`;

const SendButton = styled.button`
  padding: 8px 16px;
  background-color: ${Theme.primary};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;
const SugestedBtn = styled.button`
  padding: 8px 16px;
  background-color: ${Theme.primary};
  color: #fff;
  border: none;
  margin-top: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
`;
const SugestedContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ChatSubContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  div.log-message {
    width: 100%;
  }
  div.history-message {
    background-color: #f5f5f5;
    width: 185px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 502px;

    transition: 1s;
    button {
      margin: 10px 105px;
      width: 90%;
      height: max-content;
      text-overflow: clip;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      border: none;

      border-radius: 9px;
      background-color: white;
      color: ${Theme.primary};
      :last-of-type {
        margin-bottom: 12px;
      }
    }
  }
`;

const Popover = styled.div`
  position: absolute;
  background-color: ${Theme.primary};
  margin-top: 5px;
  color: white;
  padding: 8px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  transition: 1s;
`;

const App = () => {
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [sources, setSources] = useState([]);

  const [sugested, setSugested] = useState([]);
  const image = localStorage.getItem('image_url');
  const [openModal, setOpenModal] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [idHistory, setidHistory] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [token, setToken] = useState('');
  const [streamedData, setStreamedData] = useState('');
  const [historyMessage, setHistoryMessage] = useState([]);
  const [headersHistory, setHeadersHistory] = useState('');
  const [followUP, setFollowUP] = useState([]);
  const [loadingSources, setLoadingSources] = useState(false);
  const contentRef = useRef(null);

  function newChat() {
    setMessages([]);
    setStreamedData('');
    setHistoryMessage([]);
    setUserMessage('');
    setSources([]);
    setSugested([]);
    setidHistory(0);
    setFollowUP([]);
    setLoading(false);
    setLoadingSources(false);
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  function getAllMessages() {
    api
      .get('https://openai.fegsystem.com/conversations?per_page=1000')
      .then((resData) => setAllMessages(resData.data.data));
  }
  async function getHistory() {
    await api
      .get('https://openai.fegsystem.com/conversations?per_page=1000')
      .then((resData) => setAllMessages(resData.data.data));
  }
  useEffect(() => {
    getHistory();

    setToken(localStorage.getItem('token'));
  }, []);

  useEffect(() => {
    api
      .get(`https://openai.fegsystem.com/conversations/${idHistory}`)
      .then((resData) => {
        setHistoryMessage([]);
        setStreamedData('');
        setSources([]);
        setFollowUP([]);
        setMessages(resData.data.messages);
      })
      .catch((e) => console.log(e));
  }, [idHistory]);

  const scrollToDiv = () => {
    const elemento = contentRef.current;
    if (elemento) {
      elemento.scrollTo({
        top: Number.MAX_SAFE_INTEGER,
        behavior: 'smooth'
      });
    }
  };

  function transformarLinksEmHTML(texto) {
    // Expressão regular para encontrar links
    var regex = /(https?:\/\/[^\s]+)/g;
    var regexEmail = /([\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,6})/g;

    // Substitui os links encontrados pelo texto formatado
    var textoFormatado = texto.replace(regex, '<a href="$1">$1</a>');
    const dataFormated = textoFormatado.replace(
      regexEmail,
      '<a href="mailto:$1">$1</a>'
    );

    return dataFormated;
  }

  async function fetchDataFromStream() {
    scrollToDiv();
    if (userMessage === '') {
      return;
    }
    let userMessageRecover = userMessage;
    let idHeaderHistory;
    let firstMessage = false;
    let idMessageResponse;
    setFollowUP([]);
    setSources([]);
    setUserMessage('');
    setLoadingSources(true);
    if (!streamedData) {
      setHistoryMessage((prevHistory) => [
        ...prevHistory,
        { content: userMessageRecover, role: 'user' }
      ]);
    }
    if (streamedData) {
      setHistoryMessage((prevHistory) => [
        ...prevHistory,
        { content: streamedData, role: 'assistent' },
        { content: userMessageRecover, role: 'user' }
      ]);
      setStreamedData('');
    }
    const response = await fetch(
      `https://openai.fegsystem.com/conversations${
        headersHistory !== 0 ? `/${headersHistory}` : ''
      } `,
      {
        method: 'POST',
        body: JSON.stringify({
          prompt: userMessageRecover
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    const reader = response.body.getReader();
    const headers = response.headers;

    // Converte os headers para um objeto
    const headersObj = {};
    headers.forEach((value, name) => {
      headersObj[name] = value;
    });
    idHeaderHistory = headersObj['fegpt-conversation-id'];
    idMessageResponse = headersObj['fegpt-response-message-id'];
    if (Number(headersObj['fegpt-conversation-id']) !== headersHistory) {
      setHeadersHistory(headersObj['fegpt-conversation-id']);
      firstMessage = true;
    }
    let textDataFilter = '';
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const text = new TextDecoder().decode(value);
      textDataFilter += text;
      setStreamedData((prevData) => prevData + text);
    }
    if (firstMessage) {
      api
        .get(
          `https://openai.fegsystem.com/conversations/${idHeaderHistory}/name`
        )
        .then((r) => getHistory());
    }
    setStreamedData(transformarLinksEmHTML(textDataFilter));
    api
      .get(
        `https://openai.fegsystem.com/conversations/${idHeaderHistory}/follow-ups`
      )
      .then((res) => setFollowUP(res.data));
    api
      .get(
        `https://openai.fegsystem.com/conversations/${idHeaderHistory}/messages/${idMessageResponse}`
      )
      .then((res) => {
        setSources(res.data.sources);
      });
    setLoadingSources(false);
  }

  return (
    <ChatContainer>
      <div className='header'>
        <div className='title'>
          <img
            src={
              'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
            }
            alt='FEG LOGO'
          />
          <h1>Ask FEG </h1>
        </div>
        <div className='content-menu'>
          <button onClick={() => newChat()}>New Chat</button>
          <button
            className='btn-menu'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
              getAllMessages();
              setOpenModal(!openModal);
            }}
          >
            {openModal ? (
              <>
                <AiOutlineMenuFold size={30} />
                {isHovered && <Popover>Close chat history</Popover>}
              </>
            ) : (
              <>
                <AiOutlineMenuUnfold size={30} />
                {isHovered && <Popover>Open chat history</Popover>}
              </>
            )}
          </button>
        </div>
      </div>
      <ChatSubContainer>
        <div className='log-message'>
          <ChatLog ref={contentRef} style={{ scrollBehavior: 'smooth' }}>
            <ChatMessage role={'bot'}>
              <div className='not-active'>
                <div
                  className='container-message'
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <p>Hi there! How can I help?</p>
                </div>
                <img
                  src={
                    'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                  }
                  alt='FEG LOGO'
                />
              </div>
            </ChatMessage>

            {messages.map((message, index) => (
              <>
                <ChatMessage key={index} role={message.role}>
                  <div
                    className={`${
                      message.role !== 'user' ? 'not-active' : 'active'
                    } `}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        whiteSpace: 'pre-line'
                      }}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: transformarLinksEmHTML(message.content)
                        }}
                      />
                      {message.url && (
                        <>
                          <p
                            style={{
                              padding: 0,
                              margin: 0,
                              whiteSpace: 'pre-line'
                            }}
                          >
                            Documentation:
                          </p>
                          <a
                            target='_blanck'
                            href={`${message.url}?se = 2023-07 - 25T19 % 3A03 % 3A26Z & sp=r & sv=2021 -08-06 & sr=c & sig=s7hx2121Ot4Mc / x1EhEOue2FW6lNqVWOBL / kLMHirbU % 3D`}
                          >
                            {message.url}
                          </a>
                        </>
                      )}
                    </div>
                    {message.role !== 'user' && (
                      <img
                        src={
                          'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                        }
                        alt='FEG LOGO'
                      />
                    )}
                    {message.role === 'user' && (
                      <img
                        src={
                          image
                            ? image
                            : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                        }
                        alt='FEG LOGO'
                      />
                    )}
                  </div>
                </ChatMessage>
                {message.sources.length > 0 && (
                  <DocumentSources
                    open={true}
                    sources={message.sources}
                  ></DocumentSources>
                )}
              </>
            ))}
            {sugested.length !== 0
              ? sugested.map((suges, index) => (
                  <SugestedContainer key={index}>
                    <SugestedBtn
                      onClick={() => {
                        setUserMessage(suges);
                      }}
                    >
                      {suges}
                    </SugestedBtn>
                  </SugestedContainer>
                ))
              : ''}
            {historyMessage.map((message) => {
              return (
                <>
                  <ChatMessage key={Math.random()} role={message.role}>
                    <div
                      className={`${
                        message.role !== 'user' ? 'not-active' : 'active'
                      } `}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          whiteSpace: 'pre-line'
                        }}
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: transformarLinksEmHTML(message.content)
                          }}
                        />
                      </div>
                      {message.role !== 'user' && (
                        <img
                          src={
                            'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                          }
                          alt='FEG LOGO'
                        />
                      )}
                      {message.role === 'user' && (
                        <img
                          src={
                            image
                              ? image
                              : 'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                          }
                          alt='FEG LOGO'
                        />
                      )}
                    </div>
                  </ChatMessage>
                </>
              );
            })}
            {streamedData ? (
              <ChatMessage key={Math.random()} role={'assistent'}>
                <div className={'not-active'}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      whiteSpace: 'pre-line'
                    }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: streamedData }} />
                  </div>

                  <img
                    src={
                      'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                    }
                    alt='FEG LOGO'
                  />
                </div>
              </ChatMessage>
            ) : (
              ''
            )}
            {sources.length > 0 && (
              <DocumentSources open={true} sources={sources}>
                {followUP.map((suges) => {
                  return (
                    <SugestedContainer key={Math.random()}>
                      <SugestedBtn
                        onClick={() => {
                          setUserMessage(suges);
                        }}
                      >
                        {suges}
                      </SugestedBtn>
                    </SugestedContainer>
                  );
                })}
              </DocumentSources>
            )}
            {loadingSources && (
              <p style={{ marginLeft: 50 }}>
                Document Sources Loading...
                <BeatLoader color={Theme.primary} size={6} />
              </p>
            )}

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </ChatLog>
          <UserInput>
            <TextInput
              type='text'
              value={userMessage}
              onChange={(e) => {
                scrollToDiv();
                setUserMessage(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  fetchDataFromStream();
                }
              }}
              placeholder='Type your question here...'
            />
            <SendButton onClick={fetchDataFromStream} disabled={loading}>
              {loading ? <BeatLoader color='#fff' size={6} /> : 'Send'}
            </SendButton>
          </UserInput>
        </div>
        {openModal && (
          <div className='history-message'>
            {allMessages.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    setHeadersHistory(item.id);
                    setidHistory(item.id);
                  }}
                >
                  {item.name}
                </button>
              );
            })}
          </div>
        )}
      </ChatSubContainer>
    </ChatContainer>
  );
};

export default App;
