import React, { useState } from 'react';

import { CreateQuestionContainer } from './styled';
import api from 'src/services/axios';
interface Options {
  option: string;
  id: string;
}
interface Option {
  id: number | string;
  text: string;
}
interface Polls {
  id?: string;
  polls_answer: string;
  options: Options[];
  date_end: string;
  date_start: string;
  active: boolean;
}
export default function CreatePoll() {
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState<Option[]>([{ id: 1, text: '' }]);
  const [correctAnswer, setCorrectAnswer] = useState<number | null | string>(
    null
  );
  const [date, setDate] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState<Polls>();
  const [isEditing, setIsEditing] = useState(false);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  function formatedOption() {
    if (isEditing) {
      return options;
    } else {
      return options.map((opt) => {
        return { option: opt.text };
      });
    }
  }
  const handleAddOption = () => {
    setOptions([...options, { id: options.length + 1, text: '' }]);
  };
  const handleSaveQuestion = () => {
    const newQuestion = {
      polls_answer: question,
      options: formatedOption(),
      date_end: dateEnd,
      date_start: dateStart
    };
    api
      .post('/api/v1/polls/create', newQuestion)
      .then(() => {
        clearInputs();
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
    setIsEditing(false);
  };

  const handleRemoveOption = (id: number | string) => {
    setOptions(options.filter((opt) => opt.id !== id));
  };

  const clearInputs = () => {
    setQuestion('');
    setOptions([{ id: 1, text: '' }]);
    setCorrectAnswer(null);
    setDate('');
    setIsEditing(false);
  };

  return (
    <CreateQuestionContainer>
      <h2>Polls</h2>

      <div className='header-create-question'>
        <label>Poll:</label>
        <input
          type='text'
          value={question}
          placeholder='Type your question here'
          onChange={(e) => setQuestion(e.target.value)}
          className='answer input-create-question'
        />
      </div>

      <div>
        <label>Option Answer:</label>
        {options.map((option, index) => (
          <div key={option.id} className='input-option'>
            <input
              type='text'
              value={option.text}
              onChange={(e) => {
                const newOptions = options.map((item) =>
                  item.id === option.id
                    ? { ...item, text: e.target.value }
                    : item
                );
                setOptions(newOptions);
              }}
              className='input-create-question'
              placeholder={`Option ${index + 1}`}
            />

            {!isEditing && (
              <button
                onClick={() => handleRemoveOption(option.id)}
                className='remove-btn'
              >
                Remove
              </button>
            )}
          </div>
        ))}

        <button className='btn-new-option' onClick={() => handleAddOption()}>
          Add New Option
        </button>
      </div>
      {!isEditing && (
        <div>
          <label>Date Start:</label>
          <input
            type='date'
            value={dateStart}
            onChange={(e) => setDateStart(e.target.value)}
            className='calendar-select-date'
          />
        </div>
      )}
      {!isEditing && (
        <div>
          <label>Date End:</label>
          <input
            type='date'
            value={dateEnd}
            onChange={(e) => setDateEnd(e.target.value)}
            className='calendar-select-date'
          />
        </div>
      )}

      <button
        style={{
          padding: '10px',
          marginTop: '20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '8px'
        }}
        onClick={handleSaveQuestion}
      >
        Publish/Shedule Poll
      </button>

      {isEditing && <button>Cancel</button>}
    </CreateQuestionContainer>
  );
}
