import React, { useEffect, useState } from 'react';
import {
  CardContainer,
  PontuationContainer,
  QuestionContainer
} from './styled';
import api from 'src/services/axios';
import troph from 'src/images/troph.png';
import { AnimatePresence, motion } from 'framer-motion';

interface Options {
  option: string;
  id: string;
}

interface Question {
  id: string;
  question_answer: string;
  options: Options[];
  correct_option_id: string;
  date: string;
  active: boolean;
}
interface ResponseQuestion {
  user_id: string;
  task_id: string;
  responses_task_id: string;
}

interface Ranking {
  name: string;
  correct: number;
  total: number;
  url_image: string;
  polls_answered: number;
}
export default function Trivia(): JSX.Element {
  const [trivia, setTrivia] = useState<Question>();
  const [answers, setAnswers] = useState<ResponseQuestion>();
  const [responseTrivia, setResponseTrivia] = useState(false);
  const [userScore, setUserScore] = useState(0);
  const [ranking, setRanking] = useState<Ranking[]>([]);
  const [realCorrectOption, setRealCorrectOption] = useState('');
  const [userResponseHistory, setUserResponseHistory] = useState('');
  const [userId, setUserId] = useState('');
  const [historyQuestion, setHistoryQuestion] = useState(false);
  const [noHaveQuestion, setNoHaveQuestion] = useState(false);

  function getTrivia(userIdParam: string) {
    api
      .post('/api/v1/trivia/question', {
        customers_id: userIdParam ? userIdParam : userId
      })
      .then((res) => {
        if (res.data?.history_question?.id) {
          setHistoryQuestion(true);
          setTrivia(res.data.question);
          setRealCorrectOption(res.data.question.correct_option_id);
          setUserResponseHistory(
            res.data.history_question.response_user_question_id
          );
        } else if (res.data.question) {
          setTrivia(res.data.question);
        } else {
          setNoHaveQuestion(true);
        }
      });
  }
  function getTriviaStatus(userId: string) {
    api.post('/api/v1/trivia/status', { user_id: userId }).then((res) => {
      setUserScore(res.data.usersRanking.position);
      setRanking(res.data.usersRanking.ranking);
    });
  }
  useEffect(() => {
    async function getUserId() {
      const userId = await localStorage.getItem('id');
      if (userId) {
        getTrivia(userId);
        setUserId(userId);
        getTriviaStatus(userId);
      }
    }
    getUserId();
  }, []);
  const currentDate = new Date();

  // Função para calcular o último dia do mês a partir de uma data
  const getLastDayOfMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Os meses começam em 0 no objeto Date
    const lastDate = new Date(year, month, 0); // Dia 0 do próximo mês é o último dia do mês atual
    return lastDate.toISOString().split('T')[0]; // Retorna a data no formato YYYY-MM-DD
  };

  // Calcula o último dia do mês atual
  const lastDay = getLastDayOfMonth(currentDate);

  const handleOptionChange = (questionId: string, optionId: string) => {
    setAnswers({
      user_id: 'test',
      task_id: questionId,
      responses_task_id: optionId
    });
  };

  const handleSubmit = () => {
    api
      .post('/api/v1/trivia/pontuation-user', {
        response_user_question_id: answers?.responses_task_id,
        question_id: trivia?.id,
        customers_id: userId
      })
      .then((res) => {
        window.location.reload();
      });
  };
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.26
      }
    }
  };
  function QuestionListEnum(num: number) {
    let questEnum = '';
    switch (num) {
      case 0:
        questEnum = 'A';
        break;
      case 1:
        questEnum = 'B';
        break;
      case 2:
        questEnum = 'C';
        break;
      case 3:
        questEnum = 'D';
        break;
      case 4:
        questEnum = 'E';
        break;
      case 5:
        questEnum = 'F';
        break;
      default:
    }
    return questEnum;
  }

  const hasSelectedOption = answers?.responses_task_id !== undefined;
  return (
    <AnimatePresence>
      <CardContainer>
        {historyQuestion ? (
          <>
            <QuestionContainer>
              {trivia ? (
                <>
                  <div className='task'>
                    <motion.h1 animate={{ scale: [1, 1.2, 1] }}>
                      {trivia?.question_answer}
                    </motion.h1>
                    <motion.ul
                      variants={container}
                      initial='hidden'
                      animate='visible'
                      className='card-responses'
                    >
                      {trivia.options.map((option, index) => (
                        <motion.li
                          variants={item}
                          key={index}
                          className={`responses ${userResponseHistory === option.id &&
                              userResponseHistory !== realCorrectOption
                              ? 'blueTest'
                              : ''
                            } 
                          ${realCorrectOption === option.id
                              ? 'correct'
                              : 'incorrect'
                            }`}
                          animate={{
                            scale:
                              answers?.responses_task_id === option.id
                                ? 1.05
                                : 1
                          }} // Animação ao clique
                          transition={{ type: 'spring', stiffness: 300 }}
                        >
                          <p>
                            {QuestionListEnum(index)} - {option.option}
                          </p>
                        </motion.li>
                      ))}
                    </motion.ul>
                    {realCorrectOption === userResponseHistory ? (
                      <p className='text-correction-true'>
                        Congrats! You got the question correct and 1 point. Come
                        back tomorrow for a new Trivia question.
                      </p>
                    ) : (
                      <p className='text-correction-false'>
                        Sorry, you got it wrong! Come back tomorrow for a new
                        Trivia question!
                      </p>
                    )}
                  </div>
                </>
              ) : noHaveQuestion ? (
                <p>No questions available</p>
              ) : (
                <p>loading questions...</p>
              )}
            </QuestionContainer>
          </>
        ) : (
          <>
            <QuestionContainer>
              {trivia ? (
                <>
                  <div className='task'>
                    <motion.h1 animate={{ opacity: [0, 1] }}>
                      {trivia?.question_answer}
                    </motion.h1>
                    <motion.ul
                      variants={container}
                      initial='hidden'
                      animate='visible'
                      className='card-responses'
                    >
                      {trivia.options.map((option, index) => (
                        <motion.li
                          variants={item}
                          key={index}
                          className={`responses ${!responseTrivia &&
                              answers?.responses_task_id === option.id
                              ? 'selected'
                              : ''
                            } ${responseTrivia
                              ? realCorrectOption === option.id
                                ? 'correct'
                                : option.id === answers?.responses_task_id
                                  ? 'blueTest'
                                  : 'incorrect'
                              : ''
                            }`}
                          onClick={() =>
                            handleOptionChange(trivia.id, option.id)
                          }
                        >
                          <p>
                            {QuestionListEnum(index)} - {option.option}
                          </p>
                        </motion.li>
                      ))}
                    </motion.ul>
                    {responseTrivia &&
                      realCorrectOption === answers?.responses_task_id ? (
                      <p className='text-correction-true'>
                        Congrats! You got the question correct and 1 point. Come
                        back tomorrow for a new Trivia question.
                      </p>
                    ) : responseTrivia && answers?.responses_task_id ? (
                      <p className='text-correction-false'>
                        Sorry, you got it wrong! Come back tomorrow for a new
                        Trivia question!
                      </p>
                    ) : (
                      ''
                    )}
                    {hasSelectedOption && !responseTrivia && (
                      <motion.button
                        animate={{ scale: [1, 1.2, 1.3, 1] }}
                        onClick={handleSubmit}
                        className='submit-button'
                      >
                        Send Responses 🎊
                      </motion.button>
                    )}
                  </div>
                </>
              ) : noHaveQuestion ? (
                <p>No questions available</p>
              ) : (
                <p>loading questions...</p>
              )}
            </QuestionContainer>
          </>
        )}

        <PontuationContainer>
          <div className='header-pontuation'>
            <div className='header-information'>
              <h1 className='score-board-header'>Scoreboard</h1>
              <p>This scoreboard expires on {lastDay}</p>
            </div>

            <motion.img
              animate={{ scale: [1, 1.2, 1] }}
              src={troph}
              alt=''
              className='img-troph'
            />
          </div>

          <div className='scoreboard-container'>
            <header className='header'>
              <h1>FEG HOME SCOREBOARD</h1>
              <div className='user-score'>
                <p>Your Rank: {userScore}</p>
              </div>
            </header>

            <table className='score-table'>
              <thead>
                <tr>
                  <th>POS</th>
                  <th>USER NAME</th>
                  <th>POLLS</th>
                  <th>PTS</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {ranking &&
                  Object.values(ranking).map((data, index) => (
                    <motion.tr
                      key={Math.random()}
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1, duration: 0.5 }}
                    >
                      <motion.td className='img-pontuation'>
                        <div className={`position-place place-${index + 1}`}>
                          <p>{index + 1}</p>
                          <img src={data.url_image} alt='' />
                        </div>
                      </motion.td>
                      <motion.td>{data.name}</motion.td>
                      <motion.td>{data.polls_answered}</motion.td>
                      <motion.td>{data.correct}</motion.td>

                      <motion.td>{data.total}</motion.td>
                    </motion.tr>
                  ))}
              </tbody>
            </table>
          </div>
        </PontuationContainer>
      </CardContainer>
    </AnimatePresence>
  );
}
