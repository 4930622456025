import React, { useEffect, useState } from 'react';
import {
  CardContainer,
  NoHavePollContainer,
  QuestionContainer
} from './styled';
import api from 'src/services/axios';
import troph from 'src/images/troph.png';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

interface Options {
  option: string;
  id: string;
}

interface Question {
  id: string;
  question_answer: string;
  options: Options[];
  correct_option_id: string;
  date: string;
  active: boolean;
}
interface ResponseQuestion {
  user_id: string;
  task_id: string;
  responses_task_id: string;
}

interface Ranking {
  name: string;
  correct: number;
  total: number;
  url_image: string;
}
export const CardTask = () => {
  const [trivia, setTrivia] = useState<Question>();
  const [answers, setAnswers] = useState<ResponseQuestion>();
  const [responseTrivia, setResponseTrivia] = useState(false);
  const [userScore, setUserScore] = useState(0);
  const [ranking, setRanking] = useState<Ranking[]>([]);
  const [userRanking, setUserRanking] = useState(0);
  const [realCorrectOption, setRealCorrectOption] = useState('');
  const [userResponseHistory, setUserResponseHistory] = useState('');
  const [userId, setUserId] = useState('');
  const [historyQuestion, setHistoryQuestion] = useState(false);
  const [noHaveQuestion, setNoHaveQuestion] = useState(false);
  const nav = useHistory();
  function getTrivia(userIdParam: string) {
    api
      .post('/api/v1/trivia/question', {
        customers_id: userIdParam ? userIdParam : userId
      })
      .then((res) => {
        if (res.data?.history_question?.id) {
          setHistoryQuestion(true);
          setTrivia(res.data.question);
          setRealCorrectOption(res.data.question.correct_option_id);
          setUserResponseHistory(
            res.data.history_question.response_user_question_id
          );
        } else if (res.data.question) {
          setTrivia(res.data.question);
        } else {
          setNoHaveQuestion(true);
        }
      });
  }
  function getTriviaStatus() {
    api.post('/api/v1/trivia/status', { user_id: 'test' }).then((res) => {
      setUserScore(res.data.user_score);
      setRanking(res.data.pontuation);
      setUserRanking(res.data.user_rank);
      console.log('Ranking:', res.data);
    });
  }
  useEffect(() => {
    async function getUserId() {
      const userId = await localStorage.getItem('id');
      if (userId) {
        getTrivia(userId);
        setUserId(userId);
      }
    }
    getUserId();
    getTriviaStatus();
  }, []);

  const handleOptionChange = (questionId: string, optionId: string) => {
    setAnswers({
      user_id: 'test',
      task_id: questionId,
      responses_task_id: optionId
    });
  };

  const handleSubmit = () => {
    setResponseTrivia(true);
    console.log('answers:', answers);
    api
      .post('/api/v1/trivia/pontuation-user', {
        response_user_question_id: answers?.responses_task_id,
        question_id: trivia?.id,
        customers_id: userId
      })
      .then((res) => {
        getTriviaStatus();
        setRealCorrectOption(res.data.correct_question);
      });
  };
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.26
      }
    }
  };
  function QuestionListEnum(num: number) {
    let questEnum = '';
    switch (num) {
      case 0:
        questEnum = 'A';
        break;
      case 1:
        questEnum = 'B';
        break;
      case 2:
        questEnum = 'C';
        break;
      case 3:
        questEnum = 'D';
        break;
      default:
    }
    return questEnum;
  }

  const hasSelectedOption = answers?.responses_task_id !== undefined;
  return (
    <AnimatePresence>
      <h1 style={{ margin: 0, marginLeft: 18, marginTop: 8 }}>Daily Trivia</h1>
      <CardContainer>
        {historyQuestion ? (
          <>
            <QuestionContainer>
              {trivia ? (
                <>
                  <div className='task'>
                    <motion.h1 animate={{ scale: [1, 1.2, 1] }}>
                      {trivia?.question_answer}
                    </motion.h1>
                    <motion.ul
                      variants={container}
                      initial='hidden'
                      animate='visible'
                      className='card-responses'
                    >
                      {trivia.options.map((option, index) => (
                        <motion.li
                          variants={item}
                          key={index}
                          className={`responses ${userResponseHistory === option.id &&
                              userResponseHistory !== realCorrectOption
                              ? 'blueTest'
                              : ''
                            } 
                          ${realCorrectOption === option.id
                              ? 'correct'
                              : 'incorrect'
                            }`}
                          animate={{
                            scale:
                              answers?.responses_task_id === option.id
                                ? 1.05
                                : 1
                          }} // Animação ao clique
                          transition={{ type: 'spring', stiffness: 300 }}
                        >
                          <p>
                            {QuestionListEnum(index)} - {option.option}
                          </p>
                        </motion.li>
                      ))}
                    </motion.ul>
                    {realCorrectOption === userResponseHistory ? (
                      <p className='text-correction-true'>
                        Congrats! You got the question correct and 1 point. Come
                        back tomorrow for a new Trivia question.
                      </p>
                    ) : (
                      <p className='text-correction-false'>
                        Sorry, you got it wrong! Come back tomorrow for a new
                        Trivia question!
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <p>loading questions...</p>
              )}
            </QuestionContainer>
          </>
        ) : (
          <>
            <QuestionContainer>
              {trivia ? (
                <>
                  <div className='task'>
                    <motion.h1 animate={{ scale: [1, 1.2, 1] }}>
                      {trivia?.question_answer}
                    </motion.h1>
                    <motion.ul
                      variants={container}
                      initial='hidden'
                      animate='visible'
                      className='card-responses'
                    >
                      {trivia.options.map((option, index) => (
                        <motion.li
                          variants={item}
                          key={index}
                          className={`responses ${!responseTrivia &&
                              answers?.responses_task_id === option.id
                              ? 'selected'
                              : ''
                            } ${responseTrivia
                              ? realCorrectOption === option.id
                                ? 'correct'
                                : option.id === answers?.responses_task_id
                                  ? 'blueTest'
                                  : 'incorrect'
                              : ''
                            }`}
                          onClick={() =>
                            handleOptionChange(trivia.id, option.id)
                          }
                        >
                          <p>
                            {QuestionListEnum(index)} - {option.option}
                          </p>
                        </motion.li>
                      ))}
                    </motion.ul>
                    {responseTrivia &&
                      realCorrectOption === answers?.responses_task_id ? (
                      <p className='text-correction-true'>
                        Congrats! You got the question correct and 1 point. Come
                        back tomorrow for a new Trivia question.
                      </p>
                    ) : responseTrivia && answers?.responses_task_id ? (
                      <p className='text-correction-false'>
                        Sorry, you got it wrong! Come back tomorrow for a new
                        Trivia question!
                      </p>
                    ) : (
                      ''
                    )}
                    {hasSelectedOption && !responseTrivia && (
                      <motion.button
                        animate={{ scale: [1, 1.2, 1.3, 1] }}
                        onClick={handleSubmit}
                        className='submit-button'
                      >
                        Send Responses 🎊
                      </motion.button>
                    )}
                  </div>
                </>
              ) : noHaveQuestion ? (
                <NoHavePollContainer>
                  <p>
                    There are no questions available at the moment. Come back
                    tomorrow!
                  </p>
                  <button onClick={() => nav.push('/trivia')}>
                    View Scoreboard
                  </button>
                </NoHavePollContainer>
              ) : (
                <p>loading questions...</p>
              )}
            </QuestionContainer>
          </>
        )}
      </CardContainer>
    </AnimatePresence>
  );
};
