import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import formatDate from 'src/utils/formatDate';
import { FiBell } from 'react-icons/fi';

import api from '../../services/axios';

import { Theme } from 'src/styles/Theme';
interface notifyProps {
  id: string;
  content: string;
  user_id_receptor: string;
  createdAt: string;
  type: string;
  is_open: boolean;
  link_desktop?: string;
}

const Notifications = (props: any) => {
  const [modal, setModal] = useState<boolean>(false);
  const screen = window.screen.width;
  const [notify, setNotify] = useState<notifyProps[]>([]);
  const idUser = localStorage.getItem('id');
  const nav = useHistory();
  useEffect(() => {
    api.get(`/api/v1/notify/find/${idUser}`).then((response) => {
      setNotify(response.data);
    });
  }, []);

  function clearAll() {
    api.get(`/api/v1/notify/clear/${idUser}`).then(() => {
      setNotify([]);
    });
  }
  function clearOne(notifyUser: notifyProps) {
    api
      .post('/api/v1/notify/clear-one', {
        id: notifyUser.id,
        user_id_receptor: notifyUser.user_id_receptor
      })
      .then(() => {
        setNotify(notify.filter((notify) => notify.id !== notifyUser.id));
      });
    if (notifyUser.link_desktop) {
      const devBaseUrl = 'https://develop.fegsystem.com';
      const prodBaseUrl = 'https://www.systemfeg.com';

      const baseUrl = window.location.origin.includes('develop')
        ? devBaseUrl
        : prodBaseUrl;

      if (notifyUser.link_desktop.startsWith(baseUrl)) {
        const newPath = notifyUser.link_desktop.replace(baseUrl, '');
        nav.push(newPath);
      } else {
        console.warn(
          'A URL não corresponde à URL principal esperada:',
          notifyUser.link_desktop
        );
      }
    }
  }

  const myModal2 = (
    <div style={{ display: modal ? 'flex' : 'none' }} className='modal2'>
      <div className='notification-row'>
        {notify.map((notify) => (
          <div
            onClick={() => clearOne(notify)}
            key={notify.id}
            className={`notification ${notify.is_open ? 'notification-open' : ''
              }`}
          >
            <h3 style={{ color: Theme.primary }}>{notify.content}</h3>
            <p style={{ fontWeight: 'bold', color: '#303030', fontSize: 12 }}>
              {formatDate(notify.createdAt)}
            </p>
            <hr style={{ marginBottom: 10, marginTop: 15 }} />
          </div>
        ))}
      </div>
      {notify.length > 0 ? (
        <button onClick={clearAll} className='clear-all'>
          Clear All
        </button>
      ) : (
        <>
          <div className='clear-all'>No Notification</div>
          <button onClick={() => setModal(false)} className='clear-all'>
            Close
          </button>
        </>
      )}
    </div>
  );

  return (
    <div className='notifications-button'>
      <button
        onClick={() => {
          if (screen < 700) {
            props.handleClickCloseSidebar();
          }
          if (modal) {
            clearAll();
          }

          setModal(!modal);
        }}
        title='Notifications'
        className={`${notify.length > 0 ? 'image' : ''}`}
      >
        <FiBell color={props.color} size={25} />
      </button>

      {myModal2}
    </div>
  );
};
export default Notifications;
