import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Theme } from 'src/styles/Theme';
import { BeatLoader } from 'react-spinners';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import api from 'src/services/axios';
import { useHistory } from 'react-router-dom';

const ChatContainer = styled.div`
  width: 70%;
  max-width: 300px;
  margin: 30px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f0;
  position: fixed;
  bottom: 60px;
  right: 20px;
  overflow: hidden;
  z-index: 99;
  div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${Theme.primary};
    button {
      width: 100px;
      height: 38px;
      border: none;
      background: white;
      color: ${Theme.primary};
      border-radius: 5px;
      font-weight: bold;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  div.content-menu {
    display: flex;
    margin: auto 0;
    button.btn-menu {
      width: min-content;
      padding: 4px;
    }
  }
  div.header div.title {
    display: flex;
    gap: 30px;
    padding: 25px 10px;
    align-items: center;
    height: 50px;
    h1 {
      color: ${Theme.secondary};
    }
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }
  @media (max-width: 500px) {
    margin-top: 30px;
    width: 100%;
  }
  @media (max-width: 700px) {
    width: 100%;
    max-width: 400px;
  }
`;

const ChatFloat = styled.div`
  position: fixed;
  z-index: 99;
  bottom: 20px;
  right: 80px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid ${Theme.grey};
  }
  img {
    width: 100%;
    border-radius: 50%;
  }
`;

const ChatLog = styled.div`
  height: 300px;
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
`;

const ChatMessage = styled.div`
  display: flex;
  word-wrap: break-word;
  width: 100%;
  .active {
    margin: 0 0 0 auto;
    div {
      background-color: ${(props) =>
        props.role === 'user' ? `${Theme.primary}` : '#fff'};
      color: ${Theme.secondary};
      flex-direction: row-reverse;
    }
  }
  div {
    display: flex;
    gap: 20px;
    word-wrap: break-word;
    flex-direction: ${(props) =>
      props.role === 'user' ? 'initial' : 'row-reverse'};
    div {
      display: flex;
      background-color: #e53935;
      width: 100%;
      margin: 10px 0;
      word-wrap: break-word;
      max-width: max-content;
      background-color: ${(props) =>
        props.role === 'user' ? '#dcf8c6' : '#fff'};
      padding: 10px;
      border-radius: ${(props) =>
        props.role === 'user' ? '10px 10px 0 10px' : '10px 10px 10px 0'};
    }
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }
`;

const ErrorMessage = styled.div`
  color: #fff;
  background-color: #e53935;
  padding: 8px;
  border-radius: 5px;
  margin-top: 5px;
`;

const UserInput = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
`;

const TextInput = styled.input`
  flex-grow: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
`;

const SendButton = styled.button`
  padding: 8px 16px;
  background-color: ${Theme.primary};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    opacity: 0.9;
  }
`;

const ChatSubContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  div.log-message {
    width: 100%;
  }
  div.history-message {
    position: absolute;
    width: 100%;
    background-color: #f5f5f0;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 400px;
    transition: 1s;
    button {
      margin: 10px 105px;
      width: 90%;

      height: 100%;
      min-height: 50px;
      cursor: pointer;
      font-weight: bold;
      border: none;
      border-radius: 9px;
      background-color: white;
      color: ${Theme.primary};
      :last-of-type {
        margin-bottom: 12px;
      }
    }
  }
`;
const SugestedBtn = styled.button`
  padding: 8px 16px;
  background-color: ${Theme.primary};
  color: #fff;
  border: none;
  margin-top: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
`;
const SugestedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 52px;
`;

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [sources, setSources] = useState([]);
  const [sugested, setSugested] = useState([]);
  const image = localStorage.getItem('image_url');
  const [openModal, setOpenModal] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [idHistory, setidHistory] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [larguraDaJanela, setLarguraDaJanela] = useState(window.innerWidth);
  const [token, setToken] = useState('');
  const [streamedData, setStreamedData] = useState('');
  const [historyMessage, setHistoryMessage] = useState([]);
  const [headersHistory, setHeadersHistory] = useState('');
  const [followUP, setFollowUP] = useState([]);
  const history = useHistory();

  async function fetchDataFromStream() {
    let userMessageRecover = userMessage;
    let idHeaderHistory;
    let firstMessage = false;
    setFollowUP([]);
    setUserMessage('');
    if (!streamedData) {
      setHistoryMessage((prevHistory) => [
        ...prevHistory,
        { content: userMessageRecover, role: 'user' }
      ]);
    }
    if (streamedData) {
      setHistoryMessage((prevHistory) => [
        ...prevHistory,
        { content: streamedData, role: 'assistent' },
        { content: userMessageRecover, role: 'user' }
      ]);
      setStreamedData('');
    }
    const response = await fetch(
      `https://openai.fegsystem.com/conversations${
        headersHistory !== 0 ? `/${headersHistory}` : ''
      } `,
      {
        method: 'POST',
        body: JSON.stringify({
          prompt: userMessageRecover
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );
    const reader = response.body.getReader();
    const headers = response.headers;

    // Converte os headers para um objeto
    const headersObj = {};
    headers.forEach((value, name) => {
      headersObj[name] = value;
    });
    idHeaderHistory = headersObj['fegpt-conversation-id'];
    if (Number(headersObj['fegpt-conversation-id']) !== headersHistory) {
      setHeadersHistory(headersObj['fegpt-conversation-id']);
      firstMessage = true;
      console.log(headersObj['fegpt-conversation-id']);
    }
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const text = new TextDecoder().decode(value);
      setStreamedData((prevData) => prevData + text);
    }
    if (firstMessage) {
      api
        .get(
          `https://openai.fegsystem.com/conversations/${idHeaderHistory}/name`,
          { timeout: 0 }
        )
        .then((r) => getHistory());
    }
    api
      .get(
        `https://openai.fegsystem.com/conversations/${idHeaderHistory}/follow-ups`,
        { timeout: 0 }
      )
      .then((res) => {
        setFollowUP(res.data);
      });
  }

  function newChat() {
    setMessages([]);
    setStreamedData('');
    setHistoryMessage([]);
    setUserMessage('');
    setSources([]);
    setSugested([]);
    setidHistory(0);
    setFollowUP([]);
  }
  useEffect(() => {
    function handleResize() {
      setLarguraDaJanela(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  async function getHistory() {
    await api
      .get('https://openai.fegsystem.com/conversations?per_page=1000', {
        timeout: 0
      })
      .then((resData) => setAllMessages(resData.data.data))
      .catch((e) => console.log(e));
  }
  useEffect(() => {
    getHistory();
    setToken(localStorage.getItem('token'));
  }, []);

  useEffect(() => {
    api
      .get(`https://openai.fegsystem.com/conversations/${idHistory}`, {
        timeout: 0
      })
      .then((resData) => setMessages(resData.data.messages))
      .catch((e) => console.log(e));
  }, [idHistory]);

  function handleChatFloat() {
    if (larguraDaJanela < 700) {
      history.push('/chat-fegpt');
    } else {
      setOpenChat(!openChat);
    }
  }

  return (
    <>
      <ChatFloat>
        <button onClick={() => history.push('/chat-fegpt')}>
          <img
            src={
              'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
            }
            alt='FEG LOGO'
          />
        </button>
      </ChatFloat>
      {openChat && (
        <ChatContainer>
          <div className='header'>
            <div className='title'>
              <img
                src={
                  'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                }
                alt='FEG LOGO'
              />
            </div>
            <div className='content-menu'>
              <button onClick={() => newChat()}>New Chat</button>

              <button
                className='btn-menu'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  setOpenModal(!openModal);
                }}
              >
                {openModal ? (
                  <>
                    <AiOutlineMenuFold size={30} />
                  </>
                ) : (
                  <>
                    <AiOutlineMenuUnfold size={30} />
                  </>
                )}
              </button>
            </div>
          </div>
          <ChatSubContainer>
            <div className='log-message'>
              <ChatLog>
                <ChatMessage role={'bot'}>
                  <div className='not-active'>
                    <div
                      className='container-message'
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <p>Hi there! How can I help?</p>
                    </div>
                    <img
                      src={
                        'https://s3.us-east-2.amazonaws.com/systemfeg.com/image/shortcuts/desktop/e82612b7-3670-4c60-932a-eff7c5ff77ae.png'
                      }
                      alt='FEG LOGO'
                    />
                  </div>
                </ChatMessage>

                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </ChatLog>
              <UserInput>
                <TextInput
                  type='text'
                  value={userMessage}
                  onChange={(e) => setUserMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      fetchDataFromStream();
                    }
                  }}
                  placeholder='Type your question here...'
                />
                <SendButton onClick={fetchDataFromStream} disabled={loading}>
                  {loading ? <BeatLoader color='#fff' size={6} /> : 'Send'}
                </SendButton>
              </UserInput>
            </div>
            {openModal && (
              <div className='history-message'>
                {allMessages.map((item, index) => {
                  // eslint-disable-next-line react/jsx-key
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        setOpenModal(false);
                        setidHistory(item.id);
                      }}
                    >
                      {item.name}
                    </button>
                  );
                })}
              </div>
            )}
          </ChatSubContainer>
        </ChatContainer>
      )}
    </>
  );
};

export default Chat;
