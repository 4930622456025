import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import {
  FiHome,
  FiUserCheck,
  FiUsers,
  FiPlusSquare,
  FiCalendar,
  FiBookOpen,
  FiPieChart,
  FiMapPin,
  FiMonitor,
  FiShield,
  FiAlertCircle,
  FiAlertOctagon,
  FiMenu,
  FiFolder
} from 'react-icons/fi';
import { MdInfo } from "react-icons/md";
import { RiTaskFill } from "react-icons/ri";
import { BiMessageDetail } from 'react-icons/bi'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { MdMessage } from 'react-icons/md';
import { GoTasklist } from "react-icons/go";

import { MdNotificationsActive } from "react-icons/md";

import { RiChat4Line, RiChatSettingsLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom';
import { WorkOutlineOutlined } from '@material-ui/icons';
import jwt from 'jsonwebtoken';
import { useCookies } from 'react-cookie';
import Monograma from '../../images/Monograma.png';
import logo from '../../images/feghome.com.png'
import miniLogo from '../../images/miniLogoFeg.png'
import Loginbutton from '../LoginButton';
import Notifications from '../Notifications';
import Tooltip from './Tooltip';
import axios from '../../services/axios';
import PrivacyPolitc from '../PrivacyPolicy';
import PasswordExpired from '../PasswordExpired';
import useWindowWith from 'src/utils/width';
import { useLocation } from 'react-router-dom';
import { MdApps } from 'react-icons/md';
import Popover from '../Popover';


const SideBar = (props: any) => {
  const nav = useHistory();
  const [actived, setActived] = useState(window.location.pathname);
  const [cookies, ,] = useCookies(['token']);
  const token: any = cookies.token;
  const repeatTooltip: any = cookies.repeatTooltip;
  const [firstAccess, setFirstAccess] = useState(false);
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(true);
  const [superAdmin, setSuperAdmin] = useState(false);
  const width = useWindowWith();
  const [fegpt, setFegpt] = useState(true);
  const [activedDown, setActivedDown] = useState('');
  const [admin, setAdmin] = useState(true);
  const [activedDownAdmin, setActivedDownAdmin] = useState('');
  const location = useLocation();

  const [permissions, setPermissions] = useState({
    announcements: { list: false, read: false, write: false },
    events: { list: false, read: false, write: false },
    groups: { list: false, read: false, write: false },
    locations: { list: false, read: false, write: false },
    users: { list: false, read: false, write: false },
    analytics: { list: false, read: false, write: false }
  });
  const [permissions2, setPermissions2] = useState({
    home_page: { view: false, add: false, edit: false, delete: false },
    announcements: { view: false, add: false, edit: false, delete: false },
    user_validation: { view: false, add: false, edit: false, delete: false },
    user_management: { view: false, add: false, edit: false, delete: false },
    location: { view: false, add: false, edit: false, delete: false },
    events: { view: false, add: false, edit: false, delete: false },
    analytics: { view: false, add: false, edit: false, delete: false },
    shortcuts: { view: false, add: false, edit: false, delete: false },
    admin_trivia: { view: false, add: false, edit: false, delete: false },
    permissions_management: {
      view: false,
      add: false,
      edit: false,
      delete: false
    },
    map_jobtitle: { view: false, add: false, edit: false, delete: false },
    chat_feg: { view: false, add: false, edit: false, delete: false },
    chat_feg_docs: { view: false, add: false, edit: false, delete: false },
    chat_feg_logs: { view: false, add: false, edit: false, delete: false },
    chat_feg_repository: { view: false, add: false, edit: false, delete: false },
  });
  useEffect(() => {
    handlePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function openNav(act: string) {
    setFegpt(!fegpt)
    activedDown === act ? setActivedDown("") : setActivedDown(act)

  }
  function openNavAdmin(act: string) {
    setAdmin(!admin)
    activedDownAdmin === act ? setActivedDownAdmin("") : setActivedDownAdmin(act)

  }
  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        async (err: any, decoded: any) => {
          if (err) {
            alert(err);
          } else {
            console.log(decoded);
            localStorage.setItem('location_id', decoded.location_id)
            setPermissions(decoded.modules);
            setPermissions2(decoded.roles);

            const customer_id = localStorage.getItem('id');
            const response = await axios
              .get(`api/v1/accounts/${customer_id}`)
              .then((response) => response.data.results);
            if (response.group_id === '128e5e58-3c0a-4e63-9be1-d0b6fc2f871e') {
              setSuperAdmin(true);
            }
            setFirstAccess(response.first_access);
            setPasswordExpired(response.password_expired_time);
            setPrivacyPolicy(response.accepted_privacy_policy);
            if (response.first_access) {
              axios.put(`api/v1/accounts/${customer_id}/user/first_access`);
            } else if (repeatTooltip === 'true') {
              setFirstAccess(true);
            }
          }
        }
      );
      return;
    }
    jwt.verify(
      token!.toString(),
      '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
      async (err: any, decoded: any) => {
        if (err) {
          alert(err);
        } else {
          setPermissions(decoded.modules);
          setPermissions2(decoded.roles);

          const customer_id = localStorage.getItem('id');
          const first_access = await axios
            .get(`api/v1/accounts/${customer_id}`)
            .then((response) => response.data.results.first_access);
          setFirstAccess(first_access);
          if (first_access) {
            axios.put(`api/v1/accounts/${customer_id}/user/first_access`);
          } else if (repeatTooltip === 'true') {
            setFirstAccess(true);
          }
        }
      }
    );
  }
  const handleNav = (url: any) => {
    setActived(url);
    nav.push(url);
    setActivedDownAdmin('')
    setActivedDown('')
    props.setSidebarOpen(false)
  };
  function handleOpenSidebar() {

    if (!props.sidebarOpen) {
      setAdmin(false)
      setFegpt(false)
    }
    if (props.sidebarOpen) {
      console.log('test sidebar')
      setAdmin(false)
      setFegpt(false)
      setActivedDown('')
      setActivedDown('')
    }
  }

  function setTooltipPathName(name: string) {
    if (name) setActived(name);
  }

  function handleAccessPermissions() {
    console.log('test tooltip')
    /*if (!privacyPolicy) {
      return <PrivacyPolitc setPrivacyTerm={setPrivacyPolicy} />;
    }*/
    if (passwordExpired) {
      return <PasswordExpired setPasswordExpired={setPasswordExpired} />;
    }
    /*if (firstAccess) {
      return <Tooltip setTooltipPath={setTooltipPathName} />;
    }*/

    return <></>;
  }
  useEffect(() => {
    handleOpenSidebar()
    console.log('test tooltip sidebar', props.sidebarOpen)
  }, [
    props.sidebarOpen,
  ])

  return (
    <>
      <div
        className={`sidebar ${props.sidebarOpen && 'sidebar-active'}`}
        id='sidebar'
      >
        <div className='content'>

          <span className='logo'>
            <img id='monograma' onClick={() => nav.push('/new-home')} style={{ cursor: 'pointer' }} className={`${!props.sidebarOpen && 'actived'}`} src={props.sidebarOpen ? logo : miniLogo} alt='FEG LOGO' />

            <FiMenu
              size={20}
              onClick={() => {
                props.setSidebarOpen(!props.sidebarOpen)
              }}
            />
          </span>

          <div
            onClick={() => handleNav('/list-shortcuts')}
            className={`short ${location.pathname === '/list-shortcuts' ? 'active opt' : 'opt'}`}
          >     <label className={`${location.pathname === '/list-shortcuts' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>FEG Apps</p>
            <Popover triggerContent={<MdApps size={20} />} popoverContent={<div>FEG Apps</div>} />
          </div>


          <div
            onClick={() => handleNav('/announcements')}
            className={`short ${location.pathname === '/announcements' ? 'active opt' : ''}`}
          >
            <label className={`${location.pathname === '/announcements' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Announcements</p>
            <Popover triggerContent={<FiBookOpen size={20} />} popoverContent={<div>Announcements</div>} />
          </div>





          <div
            onClick={() => handleNav('/chat-fegpt')}
            className={`short ${location.pathname === '/chat-fegpt' ? 'active opt' : ''}`}
            style={{
              display: permissions2.chat_feg.view ? 'flex' : 'none'
            }}
          >     <label className={`${location.pathname === '/chat-fegpt' ? 'bar-active act' : 'bar-active'}`}></label>

            <p>Ask FEG</p>
            <Popover triggerContent={<MdMessage size={20} />} popoverContent={<div>Chat</div>} />
          </div>


          <div
            onClick={() => handleNav('/knowledgebase')}
            className={`short ${location.pathname === '/knowledgebase' ? 'active opt' : ''}`}
            style={{
              display: permissions2.chat_feg_repository.view ? 'flex' : 'none'
            }}
          >
            <label className={`${location.pathname === '/knowledgebase' ? 'bar-active act' : 'bar-active'}`}></label>

            <p>Knowledge Base</p>
            <Popover triggerContent={<FiFolder size={20} />} popoverContent={<div>Knowledge Base</div>} />
          </div>


          <div
            onClick={() => handleNav('/trivia')}
            className={`short ${location.pathname === '/trivia' ? 'active opt' : ''}`}
          >     <label className={`${location.pathname === '/trivia' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Trivia</p>
            <Popover triggerContent={<RiTaskFill size={20} />} popoverContent={<div>Trivia</div>} />
          </div>



          {
            props.sidebarOpen && (
              <div
                onClick={() => openNavAdmin('/admin')}
                className={`short ${activedDownAdmin === '/admin' ? 'active opt' : ''}`}
              >     <label className={`${activedDownAdmin === '/admin' ? 'bar-active act' : 'bar-active'}`}></label>
                <p>Admin</p>

                {activedDownAdmin === '/admin' ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
              </div>
            )
          }

          {
            admin && (
              <>
                <div
                  onClick={() => handleNav('/settings-fegpt')}
                  className={`short ${location.pathname === '/settings-fegpt' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.chat_feg_docs.add ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Knowledge Base Upload</p>
                  <Popover triggerContent={<RiChatSettingsLine size={20} />} popoverContent={<div>Knowledge base Upload</div>} />
                </div>

                <div
                  onClick={() => handleNav('/chat/logs')}
                  className={`short ${location.pathname === '/chat/logs' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.chat_feg_logs.view ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Chat Analytics</p>
                  <Popover triggerContent={<MdInfo size={20} />} popoverContent={<div>Chat Analytics</div>} />
                </div>

                <div
                  onClick={() => handleNav('/announcements/new')}
                  className={`short ${location.pathname === '/announcements/new' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.announcements.add ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>New Announcement</p>
                  <Popover triggerContent={<FiPlusSquare size={20} />} popoverContent={<div>New Announcement</div>} />
                </div>


                <div
                  style={{ display: permissions2.location.view ? 'flex' : 'none' }}
                  onClick={() => handleNav('/locations')}
                  className={`short ${location.pathname === '/locations' ? 'active opt down' : 'down'}`}
                >     <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Location Management</p>
                  <Popover triggerContent={<FiMapPin size={20} />} popoverContent={<div>Location Management</div>} />
                </div>

                <div
                  onClick={() => handleNav('/calendar')}
                  className={`short ${location.pathname === '/calendar' ? 'active opt down' : 'down'}`}
                >     <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Company Calendar</p>
                  <Popover triggerContent={<FiCalendar size={20} />} popoverContent={<div>Company Calendar</div>} />
                </div>

                <div
                  onClick={() => handleNav('/admin-trivia')}
                  className={`short ${location.pathname === '/admin-trivia' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.admin_trivia.view ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Admin Trivia</p>
                  <Popover triggerContent={<GoTasklist size={20} />} popoverContent={<div>Admin Trivia</div>} />
                </div>
                <div
                  onClick={() => handleNav('/analytics')}
                  className={`short ${location.pathname === '/analytics' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.analytics.view ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Analytics</p>
                  <Popover triggerContent={<FiPieChart size={20} />} popoverContent={<div>Analytics</div>} />
                </div>
                <div
                  onClick={() => handleNav('/shortcuts')}
                  className={`short ${location.pathname === '/shortcuts' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.shortcuts.view ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Shortcuts config</p>
                  <Popover triggerContent={<FiMonitor size={20} />} popoverContent={<div>Shortcuts config</div>} />
                </div>
                <div
                  onClick={() => handleNav('/mapJobtitle')}
                  className={`short ${location.pathname === '/mapJobtitle' ? 'active opt down' : 'down'}`}
                  style={{
                    display: superAdmin ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Job title</p>
                  <Popover triggerContent={<WorkOutlineOutlined />} popoverContent={<div>Job title</div>} />
                </div>
                <div
                  onClick={() => handleNav('/permissions')}
                  className={`short ${location.pathname === '/permissions' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.permissions_management.view ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Permissions</p>
                  <Popover triggerContent={<FiShield size={20} />} popoverContent={<div>Permissions</div>} />
                </div>
                <div
                  onClick={() => handleNav('/user')}
                  className={`short ${location.pathname === '/user' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.user_validation.view ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>User Validations</p>
                  <Popover triggerContent={<FiUserCheck size={20} />} popoverContent={<div>User Validations</div>} />
                </div>
                <div
                  onClick={() => handleNav('/management')}
                  className={`short ${location.pathname === '/management' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.user_management.view ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Users Management</p>
                  <Popover triggerContent={<FiUsers size={20} />} popoverContent={<div>Users Management</div>} />
                </div>
                <div
                  onClick={() => handleNav('/notify-dashboard')}
                  className={`short ${location.pathname === '/notify-dashboard' ? 'active opt down' : 'down'}`}
                  style={{
                    display: permissions2.user_management.view ? 'flex' : 'none'
                  }}
                >
                  <label className={`${admin ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Notification Admin</p>
                  <Popover triggerContent={<MdNotificationsActive size={20} />} popoverContent={<div>Users Management</div>} />
                </div>
              </>
            )
          }


          {props.sidebarOpen && (
            <div className='ask-fegpt'>
              <button className="ask-fegpt" onClick={() => handleNav('/chat-fegpt')}>Ask FEG</button>
            </div>
          )}

        </div>

        <div className='spacingT' onClick={() => props.setSidebarOpen(!props.sidebarOpen)}></div>
      </div>


      <div className='HomeContent'>{handleAccessPermissions()}</div>
    </>
  );
};

export default SideBar;
