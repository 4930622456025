import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 86%;
  margin: 0 auto;
`;
export const DivisorContent = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .event-content {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
    button.btn-create-content {
      padding: 12px;
      background-color: ${Theme.primary};
      color: white;
      border: none;
      border-radius: 8px;
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

export const CallendarFull = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  .fc {
    width: 100%;
    height: 100%;
    max-height: 600px;
  }

  .task-question {
    width: 100%;
    background: #1260a7;
    font-weight: bold;
    border: none;
    padding: 5px;
    color: white;
  }
  .task-poll {
    width: 100%;
    background: #b400bd;
    border: none;
    font-weight: bold;
    padding: 5px;
  }
  .task-news {
    width: 100%;
    background: #e7262b;
    color: white;
    border: none;
    font-weight: bold;
    padding: 5px;
  }
  .fc-daygrid-event-dot {
    display: none;
  }
`;
export const DivisorScoreboard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  div.score {
    width: 50%;
    height: 100%;
    background-color: #f8f9fa;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
