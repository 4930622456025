import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const CreateQuestionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  input.answer {
    width: 100%;
    margin-top: 8px;
  }
  button.gradiente {
    background: rgb(255, 255, 0);
    background: linear-gradient(
      310deg,
      rgba(255, 255, 0, 1) 0%,
      rgba(248, 45, 151, 1) 35%,
      rgba(1, 196, 231, 1) 100%
    );
    font-weight: bold;
    font-family: Roboto, sans-serif;
    padding: 10px;
    margin-top: 20px;
    font-size: 17px;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  }
  input.input-create-question {
    padding: 8px;

    border: none;
    border-radius: 8px;
    background-color: #efefef;
  }
  label {
    font-weight: bold;
  }
  input.calendar-select-date {
    width: 100%;
    padding: 8px;
    background-color: #efefef;
    border: none;
    margin-top: 8px;
  }
  div.input-option {
    width: 100%;
    display: flex;
    gap: 8px;
    margin-top: 8px;
    input.input-create-question {
      flex: 1;
    }
    button {
      background-color: ${Theme.primary};
      color: white;
      padding: 12px;
      border: none;
      cursor: pointer;
      border-radius: 9px;
    }
  }
  button.btn-new-option {
    background-color: ${Theme.primary};
    color: white;
    padding: 12px;
    border: none;
    cursor: pointer;
    margin-top: 8px;
    border-radius: 9px;
  }
  .react-calendar__month-view__days__day--weekend {
    background-color: white; /* Altere a cor de fundo */
    color: black; /* Cor do texto */
  }
`;
