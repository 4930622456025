import React, { useState, useEffect } from 'react';
import api from 'src/services/axios';
import { PontuationContainer } from './styled';
import { motion } from 'framer-motion';
import troph from 'src/images/troph.png';

interface RankingItem {
  name: string;
  polls_answered: number;
  total: number;
  correct: number;
  url_image: string;
}
export default function Scoreboard(): JSX.Element {
  const [ranking, setRanking] = useState<RankingItem[]>([]);
  const currentMonth = new Date().getMonth() + 1; // Mês atual em formato numérico (1 a 12)

  const [selectedMonth, setSelectedMonth] = useState(String(currentMonth));
  const fetchRanking = async () => {
    api.get(`/api/v1/trivia/status-admin/${selectedMonth}`).then((response) => {
      if (response.data.pontuation.length > 0) {
        setRanking(response.data.pontuation);
        console.log(response.data);
      } else {
        setRanking([]);
      }
    });
  };

  // Atualiza o ranking quando a opção muda
  useEffect(() => {
    fetchRanking();
  }, [selectedMonth]);
  const months = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];
  const handleMonthChange: React.ChangeEventHandler<HTMLSelectElement> = async (
    e
  ) => {
    const selectedValue: string = e.target.value;
    setSelectedMonth(selectedValue); // Atualiza o estado com o mês selecionado
  };
  return (
    <PontuationContainer>
      <div className='header-pontuation'>
        <div className='header-information'>
          <h1 className='score-board-header'>Scoreboard</h1>

          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{
              marginTop: '1rem'
            }}
          >
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
        </div>

        <motion.img
          animate={{ scale: [1, 1.2, 1] }}
          src={troph}
          alt=''
          className='img-troph'
        />
      </div>

      <div className='scoreboard-container'>
        <header className='header'>
          <h1>FEG HOME SCOREBOARD</h1>
        </header>

        <table className='score-table'>
          <thead>
            <tr>
              <th>POS</th>
              <th>USER NAME</th>
              <th>POLLS</th>
              <th>QUESTION</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {ranking &&
              ranking?.map((data, index) => (
                <motion.tr
                  key={Math.random()}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1, duration: 0.5 }}
                >
                  <motion.td className='img-pontuation'>
                    <div className={`position-place place-${index + 1}`}>
                      <p>{index + 1}</p>
                      <img src={data.url_image} alt='' />
                    </div>
                  </motion.td>
                  <motion.td>{data.name}</motion.td>
                  <motion.td>{data.polls_answered}</motion.td>
                  <motion.td>{data.correct}</motion.td>
                  <motion.td>{data.total}</motion.td>
                </motion.tr>
              ))}
          </tbody>
        </table>
      </div>
    </PontuationContainer>
  );
}
