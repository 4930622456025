import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

const CardContainer = styled.div`
  width: 90%;
  margin: 40px auto;
  min-height: 100%;
  display: flex;
  min-height: 80vh;
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  img.img-one {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
    height: 300px;
  }
  img.img-two {
    position: absolute;
    bottom: 0;
    right: 90px;
    width: 250px;
    height: 250px;
  }
`;

const QuestionContainer = styled.div`
  width: 50%;
  padding: 20px 26px;
  margin: 0 50px;
  display: flex;
  height: min-content;

  backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }
  .task {
    display: flex;
    flex-direction: column;

    width: 100%;
    .submit-button {
      background-color: orange;
      color: white;
      border: none;

      padding: 20px 36px;
      cursor: pointer;
      margin: 26px auto;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    }
  }
  p.text-correction-true {
    color: #39e75f;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 5px;
  }
  p.text-correction-false {
    color: tomato;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 5px;
  }
  h1 {
    color: ${Theme.primary};
    font-size: 24px;
    margin-bottom: 32px;
  }
  .card-responses {
    display: grid;
    grid-template-columns: 1fr;
    gap: 18px;
    width: 100%;
  }
  .responses {
    background-color: white;
    padding: 20px;
    width: 100%;
    border-radius: 12px;
    border: 2px solid #efefef;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    font-size: 20px;
  }
  .responses.selected {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid green !important;
  }
  .responses.correct {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }

  .responses.incorrect {
    background-color: #f1f1f1f1; /* Light red /
color: #721c24; / Dark red text */
    border: 1px solid #fefefe;
  }

  .responses.blueTest {
    background-color: #fabec0; /* Light blue for selected */
    color: #e43d40;
    border: 1px solid #f85c70 !important;
  }
  li {
    text-decoration: none;
    list-style: none;
  }
`;

const PontuationContainer = styled.div`
  width: 50%;
  margin: 0 50px;
  display: flex;
  height: min-content;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  backdrop-filter: blur(10px);

  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  padding: 26px;

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 20px;
    margin: 0 auto;
  }
  .img-pontuation {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 26px;

    div.position-place {
      position: relative;
      p {
        background-color: #d4edda;
        padding: 0;
        margin: 0 auto;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        font-weight: bold;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        font-size: 20px;
        color: white;
      }

      img {
        width: 60px;
        height: 60px;
        padding: 4px;
        border-radius: 50%;
        border: 4px solid #d4edda;
      }
    }
    div.place-1 {
      p {
        background-color: gold !important;
        font-size: 24px;
      }
      img {
        border: 4px solid gold;
        width: 65px;
        height: 65px;
      }
    }
    div.place-2 {
      p {
        background-color: silver !important;
        font-size: 22px;
      }
      img {
        border: 4px solid silver;
        width: 55px;
        height: 55px;
      }
    }
    div.place-3 {
      p {
        background-color: #cd7f32 !important;
        font-size: 20px;
      }
      img {
        border: 4px solid #cd7f32;
        width: 50px;
        height: 50px;
      }
    }
  }
  .header-pontuation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    img.img-troph {
      width: 80px;
      height: 80px;
      margin: 0 16px;
      @media (max-width: 768px) {
        width: 55px;
        height: 55px;
      }
    }
  }
  div.header-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-size: 14px;
      color: #333;
    }
  }
  h1.score-board-header {
    color: ${Theme.primary};
    font-size: 42px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .scoreboard-container {
    background-color: #f5f5f5; /* Light grey background */
    width: 100%;

    border-radius: 8px;
  }
  td.name-td {
    font-size: 14px;
    font-weight: bold;
  }
  tr {
    font-weight: bold;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .header {
    background-color: #000080; /* Navy blue header */
    color: #ffffff; /* White text */
    width: 100%;
    margin: 0;

    display: flex;
    align-items: center;

    text-align: center;
  }

  .user-score {
    background-color: #e0f7fa; /* Light teal */
    color: #000080; /* Navy blue text */
    padding: 10px;
    border-radius: 5px;

    text-align: center;
    margin-top: 0px;
  }

  .score-table {
    width: 100%;
    background-color: #ffffff; /* White table background */
    color: #333333; /* Dark grey text */
    border-radius: 8px;
    overflow: hidden;
  }

  .score-table th {
    background-color: #000080; /* Navy blue header */
    color: #ffffff; /* White text */
    padding: 10px;
    @media (max-width: 768px) {
    }
  }

  .score-table th,
  .score-table td {
    padding: 10px;

    text-align: center;
  }

  .score-table td {
    padding: 10px;
    text-align: center;
  }

  .score-table tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Light grey for even rows */
  }

  .score-table tbody tr:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
  }
`;

export { CardContainer, QuestionContainer, PontuationContainer };
