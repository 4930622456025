import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

const CardContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  border-radius: 12px;
  img.img-one {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
    height: 300px;
  }
  img.img-two {
    position: absolute;
    bottom: 0;
    right: 90px;
    width: 250px;
    height: 250px;
  }
`;

const QuestionContainer = styled.div`
  width: 100%;

  height: min-content;
  margin: 0 auto;
  display: flex;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  background-color: #f8f9fa;
  flex-direction: column;
  .task {
    display: flex;
    flex-direction: column;
    h1 {
      background-color: ${Theme.primary};
      color: ${Theme['secondary-light']};
      padding-bottom: 16px;
      margin-bottom: 10px;
    }
    width: 100%;
    .submit-button {
      background-color: orange;
      color: white;
      border: none;

      padding: 20px 36px;
      cursor: pointer;
      margin: 26px auto;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    }
  }
  p.text-correction-true {
    color: #39e75f;
    font-size: 13px;
    font-weight: bold;
    padding: 18px;
    padding-top: 0;
  }
  p.text-correction-false {
    color: tomato;
    font-size: 13px;
    font-weight: bold;
    margin: 20px 5px;
    padding: 18px;
    padding-bottom: 0;
  }
  h1 {
    font-size: 22px;
    font-weight: bold;
    padding: 22px;
    margin: 0;
    margin-bottom: 0;
    color: ${Theme.primary};
    border-radius: 12px 12px 0 0;
  }
  .card-responses {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    padding: 20px;
    padding-top: 0;
    width: 100%;
  }
  .responses {
    background-color: white;
    padding: 20px;
    width: 100%;
    border-radius: 12px;
    border: 2px solid #efefef;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    font-size: 14px;
  }
  .responses.selected {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid green !important;
  }
  .responses.correct {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }

  .responses.incorrect {
    background-color: #f1f1f1f1; /* Light red /
    border: 1px solid #fefefe;
  }

  .responses.blueTest {
    background-color: #fabec0; /* Light blue for selected */
    color: #e43d40;
    border: 1px solid #f85c70 !important;
  }
  li {
    text-decoration: none;
    list-style: none;
  }
`;
const NoHavePollContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;
  flex-direction: column;
  text-align: center;
  button {
    background-color: ${Theme.primary};
    color: ${Theme['secondary-light']};
    border: none;
    padding: 20px 36px;
    cursor: pointer;
    margin: 26px auto;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  }
  h1 {
    font-size: 24px;
    color: #333;
  }
`;

export { CardContainer, QuestionContainer, NoHavePollContainer };
