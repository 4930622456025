import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios, { api } from '../../services/axios';
import jwt from 'jsonwebtoken';
import { useCookies } from 'react-cookie'

import Loader from '../Loader';

import Select from 'react-select';
import { EmailEditorContainer, UsersEmailTest } from './styled';
import { BiTrash } from 'react-icons/bi';


const GreenCheckbox = withStyles({
    root: {
        color: grey[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="primary" {...props} />);

const Newsletter: React.FC = () => {
    const [cookies, ,] = useCookies(["token"]);
    const token: any = cookies.token
    const [loaded, setLoaded] = useState<boolean>(true);
    const [checked, setChecked] = useState<boolean>(false);
    const [locationChecked, setLocationChecked] = useState<boolean>(false);
    const [emailChecked, setEmailChecked] = useState<boolean>(true);
    const [smsChecked, setSmsChecked] = useState<boolean>(false);
    const [optEveryone, setOptEveryone] = useState<boolean>(false);
    const [group, setGroup] = useState<any>([])
    const [location, setLocation] = useState<any>([])
    const [imageFile, setImageFile] = useState({
        selectedFile: "",
        type: ""
    })
    const [imageName, setImageName] = useState("")
    const [description, setDescription] = useState<any>(EditorState.createEmpty())
    const [subject, setSubject] = useState<string>("")

    const [myPreview, setMyPreview] = useState<boolean>(false)
    const [locationsInfo, setLocationsInfo] = useState([]);
    const [rolesInfo, setRolesInfo] = useState([]);
    const [newsletterAll, setNewsletterAll] = useState([])
    const [draftId, setDraftId] = useState("")
    const [newsletterSelected, setNewsletterSelected] = useState("")
    const history = useHistory();
    const [handleFileDoc, setHandleFileDoc] = useState({
        selectedFile: '',
        type: ''
    });
    const [notifyMobile, setNotifyMobile] = useState<boolean>(false)
    const [notifyDesktop, setNotifyDesktop] = useState<boolean>(false)

    const [strings, setStrings] = useState<string[]>([]); // Estado do array
    const [inputValue, setInputValue] = useState(''); // Estado do campo de input

    // Adicionar string ao array
    const addString = () => {
        if (inputValue.trim() !== '') {
            setStrings([...strings, inputValue]);
            setInputValue(''); // Limpa o campo de input
        }
    };

    // Remover string do array
    const removeString = (index: number) => {
        setStrings(strings.filter((_, i) => i !== index));
    };


    const getAllNews = async () => {
        await axios.get('/api/v1/newsletter-schedule/all').then((response) => {
            setNewsletterAll(response.data.response)
        }
        )

    }

    useEffect(() => {
        getAllNews()
        handlePermissions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        // Adiciona o script Unlayer dinamicamente
        const script = document.createElement('script');
        script.src = 'https://editor.unlayer.com/embed.js';
        script.async = true;
        script.onload = () => {
            // Inicializa o editor
            // @ts-ignore
            window.unlayer.init({
                id: 'editor-container',
                projectId: 264208, // Substitua pelo seu projectId se tiver um
                displayMode: 'email',
            });

        };
        document.body.appendChild(script);

        // Remove o script ao desmontar o componente
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onChangeSelect = (e: any) => {
        console.log(e)
        setDraftId(e)
        if (e === "") {
            setSubject("")

            return
        }
        const findItem = newsletterAll.find((item: any) => item.id === e)
        // @ts-ignore
        setSubject(findItem?.subject)
        // @ts-ignore
        window.unlayer.loadDesign(findItem.draft);
    }

    const fetchLocations = async () => {
        const data = await axios.get('/api/v1/contacts/locations');
        setLocationsInfo(data.data);
    }

    const fetchGroups = async () => {
        const data = await axios.get('/api/v1/contacts/roles');
        setRolesInfo(data.data);
    }
    const exportHtmlAsync = () => {
        return new Promise((resolve, reject) => {
            // @ts-ignore
            window.unlayer.exportHtml((data) => {
                const { html } = data;
                if (html) {
                    resolve(html);
                } else {
                    reject("Failed to export HTML");
                }
            });
        });
    };
    const exportDesign = () => {
        return new Promise((resolve, reject) => {
            // @ts-ignore
            window.unlayer.exportHtml((data) => {
                const { design } = data;
                console.log(data)
                if (design) {
                    resolve(design);
                } else {
                    reject("Failed to export HTML");
                }
            });
        });
    };
    const publishAnnouncement = async () => {
        const id = localStorage.getItem('owner_id');
        let users = [];
        let HtmlText = "";
        try {
            HtmlText = await exportHtmlAsync() as string;
            console.log("HTML :", HtmlText);
        } catch (error) {
            alert("Error HTML: " + error);
            return;
        }
        const formattedLocation = [];
        if (group) {
            for (let index = 0; index < group.length; index++) {
                formattedLocation.push(group[index].value)
            }
        }
        const formattedRoles = [];
        if (location) {
            for (let index = 0; index < location.length; index++) {
                formattedRoles.push(location[index].value)
            }
        }

        if (optEveryone) {
            const req = await axios.get('/api/v1/contacts');
            users = req.data;
        }

        if (checked && !locationChecked) {
            const req = await axios.post('/api/v1/contacts/byLocation', { location: formattedLocation });
            users = req.data;
        }

        if (locationChecked && !checked) {
            const req = await axios.post('/api/v1/contacts/byRole', { role: formattedRoles });
            users = req.data;
        }

        if (checked && locationChecked) {
            const locationsReq = await axios.post('/api/v1/contacts/byLocation', { location: formattedLocation });
            let users2 = locationsReq.data
            let filteredUsers = []
            for (let index = 0; index < users2.length; index++) {
                if (location) {
                    for (let counter = 0; counter < location.length; counter++) {
                        if (users2[index].role === location[counter].value) {
                            filteredUsers.push(users2[index])
                        }
                    }
                }
            }
            if (filteredUsers.length > 0) users = filteredUsers
        }


        let created = new Date()

        const data = {
            groups_ids: [],
            owner_id: id,
            locations_ids: [],
            image: imageFile,
            subject: subject,
            info: HtmlText,
            text: HtmlText,
            users: users,
            createdAt: created.toString().split("").splice(4, 17).join(""),
            emailChecked,
            smsChecked,
            doc: handleFileDoc,
            notify_mobile: notifyMobile,
            notify_desktop: notifyDesktop
        }

        if (subject !== undefined
            && description !== undefined) {
            try {
                if (users.length > 0) {

                    await axios.post(`/api/v1/announcements/newsletter`, data)

                    alert("Announcement created")
                    history.push('/announcements')
                    window.location.reload()
                } else {
                    alert("0 contacts found.")
                }
            } catch (error) {

                alert(error)
            }
        }

    }

    function docSelect(props: any) {
        if (props[0] === undefined) {
            setHandleFileDoc({
                selectedFile: '',
                type: ''
            });
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(props[0]);

        reader.onload = (e: any) => {
            setHandleFileDoc({
                selectedFile: e?.target?.result,
                type: props[0].type
            });
        };
    }

    function handlePermissions() {
        jwt.verify(token!.toString(), '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb', (err: any, decoded: any) => {
            if (err) {
                alert(err)
            } else {
                if (decoded.roles.announcements.add === false) {
                    alert("Permission denied")
                    window.location.href = `${process.env.REACT_APP_URL}`
                } else {
                    setLoaded(false)
                }
            }
        })
    }

    function handleChange() {
        if (checked === false) {
            setGroup([])
            setChecked(true)
        } else if (checked === true) {
            setChecked(false)
        }
    }

    function handleLocation() {
        if (locationChecked === false) {
            setLocation([])
            setLocationChecked(true)
        } else if (locationChecked === true) {
            setLocationChecked(false)
        }
    }

    function handleEveryone() {
        if (optEveryone === false) {
            setOptEveryone(true)
            setGroup([])
            setLocation([])
        } else if (optEveryone === true) {
            setOptEveryone(false)
        }
    }

    function fileSelect(props: any) {
        if (props[0] === undefined) {
            setImageName("")
            setImageFile({
                selectedFile: "",
                type: "",
            })
            return
        }
        setImageName(props[0].name)
        const reader = new FileReader()
        reader.readAsDataURL(props[0])

        reader.onload = (e: any) => {
            setImageFile({
                selectedFile: e?.target?.result,
                type: props[0].type
            })
        }
    }

    const handleSelectGroup = (event: any) => {
        setGroup(event)
    };

    const handleSelectLocation = (event: any) => {
        setLocation(event)
    };



    const groups = (
        <div className="specific-groups">

            <FormControlLabel
                control={
                    <GreenCheckbox
                        onChange={handleChange}
                        name="checkedG"
                    />}
                label="Specific Location"
                checked={checked ? true : false}
            />

            <Select
                isMulti
                name="colors"
                options={locationsInfo}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelectGroup}
            />
        </div>
    )

    const locations = (
        <div className="specific-groups">
            <FormControlLabel
                control={
                    <GreenCheckbox
                        onChange={handleLocation}
                        name="checkedA"
                    />}
                label="Job Title (Group)"
                checked={locationChecked ? true : false}
            />

            <Select
                isMulti
                name="colors"
                options={rolesInfo}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleSelectLocation}
            />

        </div>
    )


    const preview = (
        <div style={{ display: myPreview ? "block" : "none", maxWidth: "100%" }}>
            <span onClick={() => setMyPreview(false)} className="close">&times;</span>
            <h1 style={{ margin: 0 }}>{subject}</h1>
            <div
                dangerouslySetInnerHTML={{
                    __html: draftToHtml(convertToRaw(description.getCurrentContent())).replace(/\n/g, '<br/>')
                        .replace(/<p><\/p>/g, '<br/>')
                }}
            ></div>
        </div >)

    const handleSaveDraft = async () => {
        let HtmlText
        try {
            HtmlText = await exportDesign() as object;
            console.log("HTML :", HtmlText);
        } catch (error) {
            alert("Error HTML: " + error);
            return;
        }

        api.post('/api/v1/newsletter-schedule/create', {
            draft: HtmlText,
            subject: subject,
            target_date: new Date(),
        }).then(() => {
            alert("Draft saved")
            getAllNews()
        })
    }


    const handleUpdateDraft = async () => {
        let HtmlText
        try {
            HtmlText = await exportDesign() as object;
            console.log("HTML :", HtmlText);
        } catch (error) {
            alert("Error HTML: " + error);
            return;
        }

        api.put('/api/v1/newsletter-schedule/update', {
            draft: HtmlText,
            subject: subject,
            target_date: new Date(),
            id: draftId
        }).then(() => {
            alert("Draft updated")
            //window.location.reload()
        })
    }
    const handleTestEmail = async () => {
        let HtmlText
        try {
            HtmlText = await exportHtmlAsync() as object;
            console.log("HTML :", HtmlText);
        } catch (error) {
            alert("Error HTML: " + error);
            return;
        }

        api.post('/api/v1/newsletter-schedule/send-email-test', {
            draft: HtmlText,
            subject: subject,
            users: strings,
            id: draftId
        }).then(() => {
            alert("Email sent")
            //window.location.reload()
        })
    }
    return (
        loaded ? <Loader />
            :
            <div className="new-announcement">
                <h1>Newsletter</h1>

                <EmailEditorContainer>


                    <div id="editor-container" style={{ width: '100%', display: `${emailChecked ? 'flex' : 'hidden'}` }}></div>

                </EmailEditorContainer>
                <div className="create-announcement">
                    <h4>Target</h4>
                    <FormControlLabel
                        control={
                            <GreenCheckbox
                                name="checkedG"
                                onChange={handleEveryone}
                            />}
                        label="Everyone"
                        disabled={checked || locationChecked ? true : false}
                        className='create-announcement-everyone'
                    />
                    <FormControlLabel
                        control={
                            <GreenCheckbox
                                onChange={handleChange}
                                name="checkedG"
                            />}
                        label="Specific Location"
                        style={{ display: checked ? "none" : "block" }}
                        checked={checked ? true : false}
                        onClick={() => fetchLocations()}
                        disabled={optEveryone ? true : false}
                    />
                    {
                        (checked ? groups : <></>)
                    }
                    <FormControlLabel
                        control={
                            <GreenCheckbox
                                onChange={handleLocation}
                                name="checkedA"
                            />}
                        label="Job Title (Group)"
                        style={{ display: locationChecked ? "none" : "block" }}
                        checked={locationChecked ? true : false}
                        onClick={() => fetchGroups()}
                        disabled={optEveryone ? true : false}
                    />
                    {
                        (locationChecked ? locations : <></>)
                    }
                </div>
                <div className="create-announcement">
                    <h4>Channels</h4>
                    <FormControlLabel
                        control={
                            <GreenCheckbox
                                onChange={() => setEmailChecked(!emailChecked)}
                                name="checkedG"
                            />}

                        label="Email"
                        checked={true}
                    />
                    <FormControlLabel
                        control={
                            <GreenCheckbox
                                onChange={() => setSmsChecked(!smsChecked)}
                                name="checkedA"
                            />}
                        label="SMS"

                    />
                    <FormControlLabel
                        control={<Checkbox color='primary' />}
                        label='Push Notification'
                        color='primary'
                        value={notifyMobile}
                        onChange={() => setNotifyMobile(!notifyMobile)}
                    />
                    <FormControlLabel
                        control={<Checkbox color='primary' />}
                        label='Website Notification'
                        color='primary'
                        value={notifyDesktop}
                        onChange={() => setNotifyDesktop(!notifyDesktop)}
                    />

                </div>
                <div className="utils">
                    <h4>Subject</h4>
                    <input
                        type="text"
                        name="nameInput"
                        value={subject}
                        maxLength={250}
                        onChange={e => setSubject(e.target.value)}
                    />
                    <h4>Attachment</h4>
                    <input
                        type='file'
                        maxLength={250}
                        style={{ display: 'flex' }}
                        onChange={(e) => docSelect(e.target.files)}
                    />
                    <h4>Picture</h4>
                    <div className="imageInput">
                        <label style={{ display: (imageName.length <= 0 || imageName === undefined) ? "" : "none" }} htmlFor='inputFileImg'>+</label>
                        <label style={{ display: (imageName.length >= 1 && imageName !== undefined) ? "" : "none", padding: "7px 10px" }} htmlFor='inputFileImg'>{imageName}</label>
                        <input
                            id="inputFileImg"
                            className="typefile"
                            type="file"
                            accept=".jpg,.png"
                            onChange={event => fileSelect(event.target.files)}
                        />
                        <p>images less than 4 mb only</p>
                    </div>
                    <h4>Announcement</h4>



                </div>
                <UsersEmailTest>
                    <select className="newsletter-list" value={draftId} onChange={e => onChangeSelect(e.target.value)}>
                        <option value="">Select a default draft</option>
                        {
                            newsletterAll.length > 0 && newsletterAll.map((item: any) => {
                                return (

                                    <option value={item.id}>Subject: {item.subject}</option>

                                )

                            })
                        }
                    </select>
                    {
                        draftId === "" ? <button style={{ backgroundColor: "#1260a7", padding: '10px 10px', }} onClick={() => handleSaveDraft()}>Save Draft</button> : <button style={{ padding: '10px 10px', }} onClick={() => handleUpdateDraft()}>Update Draft</button>
                    }

                    <div>

                        <p>Test settings</p>
                        <input
                            type="text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Email tester list"
                            style={{
                                padding: '10px',
                                marginRight: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                            }}
                        />
                        <button
                            onClick={addString}
                            style={{
                                padding: '10px 10px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                width: '150px',
                            }}
                        >
                            Add test users
                        </button>
                        <button style={{ backgroundColor: "#aaaaaa", padding: '10px 10px', }} onClick={() => handleTestEmail()}>
                            Send Test Email
                        </button>
                    </div>
                    <ul style={{ marginTop: '20px', listStyleType: 'none', padding: 0 }}>

                        {strings.map((str, index) => (
                            <li
                                key={index}
                                style={{

                                }}
                            >
                                {str}
                                <button className='btn-remove-user' onClick={() => removeString(index)}>
                                    <BiTrash color='tomato' />
                                </button>
                            </li>
                        ))}
                    </ul>
                </UsersEmailTest>
                <div>
                    <div>

                        <button className="button" onClick={() => publishAnnouncement()}>Publish</button>



                    </div>
                    <div>  <button onClick={() => { setMyPreview(myPreview ? false : true) }}>Preview Content</button>

                        {
                            preview
                        }
                    </div>
                </div>

            </div>
    );
}

export default Newsletter;