import React, { useEffect, useState } from 'react';
import { Container, Grid } from './styled';
import { useHistory, useParams, } from 'react-router-dom';
import axios, { api } from 'src/services/axios';
import { Avatar, Input } from '@material-ui/core';
import logoChat from '../../images/logoChat.jpeg'
import {
  AtomicBlockUtils,
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import jwt from 'jsonwebtoken';
import { Theme } from 'src/styles/Theme';
import { FaHeart } from 'react-icons/fa';
import { semanasOuDiasPassados } from './weeks';
import { BiTrash } from 'react-icons/bi';
import LatestNews from '../LatestNews';
import { CiImageOn } from 'react-icons/ci';


interface CommentsLatestNews {
  id: string;
  id_remetente: string;
  latest_news_id: string;
  content: string;
  type: string;
  full_name_remetente: string;
  createdAt: string;
  like: string[];
}
interface Item {
  id: string;
  active: boolean;
  createdAt: string;
  title: string;
  prev_legend: string;
  full_legend: string;
  image_url: string;
}
export default function GridLatestNews(params?: any) {
  const [viewedOpen, setViewedOpen] = useState(false);
  const [latestNewsTarget, setLatestNewsTarget] = useState<any>();
  const [images, setImages] = useState<any>([]);
  const history = useHistory()
  const searchParams = new URLSearchParams(window.location.search);
  const [edit, setEdit] = useState(false);
  const [titleField, setTitleField] = useState('');
  const [prevLegendField, setPrevLegendField] = useState('');
  const [fullLegend, setFullLegend] = useState<any>(EditorState.createEmpty());
  const [superAdmin, setSuperAdmin] = useState(false);
  const [comment, setComment] = useState("")
  const firstName = localStorage.getItem("firstName")
  const lastName = localStorage.getItem("lastName")
  const idAccount = localStorage.getItem('id')
  const [commentsLatestNews, setCommentsLatestNews] = useState<CommentsLatestNews[]>([])
  const [filterComments, setFilterComments] = useState("most-liked")
  const imageUrl = localStorage.getItem("image_url")
  const [publishDate, setPublishDate] = useState('')

  function compararAtivos(a: { active: any }, b: { active: any }) {
    // Os ativos verdadeiros (true) vêm primeiro
    if (a.active && !b.active) {
      return -1;
    } else if (!a.active && b.active) {
      return 1;
    } else {
      return 0;
    }
  }

  const sortItemsByActiveAndDate = (items: Item[]): Item[] => {
    return items.sort((a, b) => {
      // Primeiro, comparar a propriedade ativo
      if (a.active === b.active) {
        // Se ambos têm o mesmo valor de ativo, comparar pela data createdAt em ordem decrescente
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }
      // Colocar os itens com ativo: true antes dos itens com ativo: false
      return a.active ? -1 : 1;
    });
  };

  function HandleComment() {
    api.post('/api/v1/comments-latest-news/create', {
      id_remetente: idAccount,
      latest_news_id: latestNewsTarget.id,
      content: comment,
      type: "TEXT",
      full_name_remetente: `${firstName} ${lastName}`
    }).then(() => {
      setComment("")
      getComments(latestNewsTarget.id)
    })
  }
  function getComments(id: string) {
    api.get(`/api/v1/comments-latest-news/find/${id}/${filterComments}`).then(r => {
      setCommentsLatestNews(r.data)
    })
  }
  useEffect(() => {
    getComments(latestNewsTarget?.id)
  }, [filterComments])

  function handleOpenLatestTarget(latestNew: any) {
    if (!latestNew) return
    setViewedOpen(true);
    setPrevLegendField(latestNew.prev_legend);


    const blocksFromHTML = convertFromHTML(latestNew.full_legend.replace(/\n/g, "<br/>"));
    setFullLegend(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )
      )
    );
    if (latestNew.publish_date) {
      setPublishDate(latestNew?.publish_date.split('T')[0])
    } else {
      setPublishDate('')
    }

    getComments(latestNew?.id)
    setTitleField(latestNew?.title);
    setLatestNewsTarget(latestNew);
  }

  function getImages() {
    const search = searchParams.get('id')
    axios
      .get('/api/v1/latest-news')
      .then((res) => {

        setImages(res.data.sort(compararAtivos))
        console.log(search, params)
        if (search) {

          const result = res.data.find((latestNews: { id: string; }) => {
            return latestNews.id === search
          })
          handleOpenLatestTarget(result)
        }
        if (params?.id) {

          const result = res.data.find((latestNews: { id: string; }) => {
            return latestNews.id === params.id
          })
          handleOpenLatestTarget(result)
        }
      });
  }

  useEffect(() => {
    getImages();

    handlePermissions()
    return () => {
      const videos = document.querySelectorAll('video');
      videos.forEach((video) => {
        video.play();
      });
    };
  }, []);
  useEffect(() => {
    getImages();
  }, [params.id]);

  function handleView({ id, active }: any) {
    axios
      .post('/api/v1/latest-news/active-status', {
        id: id,
        active: active === true ? false : true
      })
      .then((res) => {
        setViewedOpen(false);
        getImages();
      });
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString); // Preserva o horário em UTC

    // Usa timeZone: "UTC" para formatar a data sempre em UTC
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      timeZone: "UTC", // Força o uso de UTC
    }).format(date);
  };


  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        async (err: any, decoded: any) => {
          if (err) {
            alert(err);
          } else {
            const customer_id = localStorage.getItem('id');
            const response = await axios
              .get(`api/v1/accounts/${customer_id}`)
              .then((response) => response.data.results);
            if (response.group_id === '128e5e58-3c0a-4e63-9be1-d0b6fc2f871e') {
              setSuperAdmin(true);
            }
          }
        }
      );
      return;
    }
  }

  function handleUpdate() {
    const htmlDescription = draftToHtml(
      convertToRaw(fullLegend.getCurrentContent())
    );

    const dataToSend: any = {
      id: latestNewsTarget.id,
      title: titleField,
      prev_legend: prevLegendField,
      full_legend: htmlDescription,
    };

    // Verifique se publishDate não está vazio antes de adicionar ao objeto
    if (publishDate !== '') {
      const publishDateFormated = new Date(publishDate);
      publishDateFormated.setUTCHours(0, 0, 0, 0); // Zera o horário em UTC

      dataToSend.publish_date = publishDateFormated;
    }

    api
      .post('/api/v1/latest-news/update', dataToSend)
      .then(() => {
        if (params.id) {
          alert('Latest News updated successfully');
          window.location.reload()
        }
        setEdit(false);
        setViewedOpen(false);
        getImages();

      });
  }
  function deleteLatest() {
    removerParametro()

    axios
      .delete(`/api/v1/latest-news/delete/${latestNewsTarget.id}`, {
      })
      .then(() => {
        setViewedOpen(false);
        getImages();
      });
  }
  function handleLike(id: string) {
    axios
      .post('/api/v1/comments-latest-news/like', {
        id,
        id_remetente: idAccount
      }).then(() => {
        getComments(latestNewsTarget.id)
      })
  }
  function deleteComment(id: string) {
    axios
      .delete(`/api/v1/comments-latest-news/delete/${id}`,).then(() => {
        getComments(latestNewsTarget.id)
        if (params.id) {
          window.location.reload()
        }
      })
  }
  const insertImage = (imgUrl: string) => {
    const contentState = fullLegend.getCurrentContent();

    // Cria uma entidade para a imagem
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE', // Tipo da entidade
      'IMMUTABLE', // Tipo de mutabilidade
      { src: imgUrl, class: 'img-field' } // Dados associados
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Usa o AtomicBlockUtils para inserir a entidade no editor
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      fullLegend,
      entityKey,
      ' '
    );

    // Atualiza o estado do editor
    setFullLegend(
      EditorState.forceSelection(newEditorState, newEditorState.getSelection())
    );

  };

  const createImageUrl = async (file: any) => {
    const dataFile = new FormData();

    if (file) {
      dataFile.append('file', file);
    }
    await axios
      .post('/api/v1/latest-news/uploadFile', dataFile, { timeout: 0 })
      .then((res) => {
        console.log(res.data);
        insertImage(res.data.url);
      });
  };
  const handleFileImageUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      createImageUrl(event.target.files[0]);
    }
  };

  function attParam(id: string) {
    console.log("id")
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('id', id);
    window.history.replaceState(null, '', `?${searchParams.toString()}`);
  }

  function removerParametro() {
    const searchParams = new URLSearchParams(window.location.search);

    // Verifica se o parâmetro existe
    if (searchParams.has('id')) {
      // Remove o parâmetro
      searchParams.delete('id');

      // Atualiza a URL
      const novaURL = searchParams.toString()
        ? `?${searchParams.toString()}` // Se ainda houver parâmetros, mantém o restante
        : window.location.pathname; // Remove o "?" se não houver mais parâmetros

      window.history.replaceState(null, '', novaURL); // Atualiza a URL sem recarregar a página
    }
  }
  const isDateValid = (date: any) => {
    if (!date) {
      return true;
    }
    const today = new Date();
    return date <= today.toISOString();
  };
  if (viewedOpen) {
    return (
      <Container className='t'>
        <Grid>
          <div className='header'>
            <p>{latestNewsTarget?.createdAt && formatDate(latestNewsTarget?.createdAt.slice(0, 10))}</p>
            {
              (superAdmin && latestNewsTarget.idea) && (
                <button className='btn-edit' style={{ background: 'purple' }}>
                  Idea Submission
                </button>
              )
            }
            <div>
              {
                superAdmin && (
                  <button
                    className={latestNewsTarget.active && 'active'}
                    onClick={() =>
                      handleView({
                        id: latestNewsTarget.id,
                        active: latestNewsTarget.active
                      })
                    }
                  >
                    {latestNewsTarget.active ? 'Public' : 'Private'}
                  </button>

                )

              }
              {
                !params.id && superAdmin && latestNewsTarget?.publish_date && (
                  <button
                    style={{ marginLeft: 8, backgroundColor: '#b400bd' }}
                  >
                    {latestNewsTarget.publish_date ? `Publish Date ${latestNewsTarget?.publish_date.split('T')[0]}` : ''}
                  </button>

                )
              }
              {
                superAdmin && (
                  <button className='btn-edit' onClick={() => setEdit(true)}>
                    Edit
                  </button>
                )
              }
              {
                superAdmin && (
                  <button className='btn-delete' style={{ marginLeft: 8 }} onClick={() => deleteLatest()}>
                    Delete
                  </button>
                )
              }
              {!params.id &&
                <button style={{ marginLeft: 8, background: '#dc6601' }} onClick={() => {
                  removerParametro()
                  setViewedOpen(false)
                }}>
                  Back to View News
                </button>
              }

            </div>
          </div>
          <div className='content'>
            {!edit ? (<>
              <h1 className='header-title'>{latestNewsTarget?.title}</h1>
              {latestNewsTarget?.idea && (<>
                <p>This latest news is an idea submission created by: <strong>{latestNewsTarget?.full_name} - location: {latestNewsTarget.store_id}</strong></p>

                <p>Admins Only: Switch to <strong>Public</strong> to publish as a latest news to all users. You can <strong>Edit</strong> before or after if you’d like. </p>
                <br />
              </>
              )}
            </>

            ) : (
              <>
                <p>Title: </p>
                <Input
                  value={titleField}
                  onChange={(e) => setTitleField(e.target.value)}
                />
              </>
            )}
            {edit && publishDate !== '' && <>
              <p>Publish Date</p>
              <Input
                type='date'
                value={publishDate}
                onChange={(e) => setPublishDate(e.target.value)}
              />
            </>}


            {latestNewsTarget?.image_url ? (<img src={latestNewsTarget.image_url} alt='' className='t' />) : (<video src={latestNewsTarget?.video_url} poster={latestNewsTarget?.thumbnail_url} controls={true} className='t' loop ></video>)}
            {edit && (
              <>
                <p>Preview Article: </p>
                <Input
                  value={prevLegendField}
                  onChange={(e) => setPrevLegendField(e.target.value)}
                />
              </>
            )}

            {!edit ? (
              latestNewsTarget?.full_legend && (
                <>
                  <div
                    dangerouslySetInnerHTML={
                      { __html: latestNewsTarget.full_legend.replace(/\n/g, "<br/>").replace(/<p><\/p>/g, "<br/>") }
                    }
                    style={{ lineHeight: 1.3, color: "#303030", margin: 32, marginTop: 12 }}
                    className='full-legend'>

                  </div>

                </>
              )
            ) : (
              <>
                <p>
                  Full Article:
                </p>

                <Editor
                  editorState={fullLegend}
                  stripPastedStyles={true}
                  wrapperClassName='demo-wrapper'
                  editorClassName='demo-editor'
                  onEditorStateChange={(e) => {
                    setFullLegend(e);
                  }}

                  toolbarCustomButtons={[
                    <>
                      <label
                        htmlFor='image-upload-url'
                        style={{
                          padding: 0,

                          borderRadius: 4,
                          margin: 3,
                          cursor: 'pointer'
                        }}
                      >
                        {<CiImageOn size={20} />}
                      </label>

                      <input
                        id='image-upload-url'
                        type='file'
                        style={{ display: 'none' }}
                        onChange={(file) => {
                          handleFileImageUrl(file);
                        }}
                      />
                    </>
                  ]}
                />
              </>
            )}
            {(superAdmin && edit) && (
              <div className='edit-btn'>
                <button
                  style={{ backgroundColor: 'gray' }}
                  onClick={() => {
                    const blocksFromHTML = convertFromHTML(
                      latestNewsTarget.full_legend
                    );
                    setFullLegend(
                      EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          blocksFromHTML.contentBlocks,
                          blocksFromHTML.entityMap
                        )
                      )
                    );
                    setEdit(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ backgroundColor: `${Theme.primary}` }}
                  onClick={() => handleUpdate()}
                >
                  Update
                </button>
              </div>
            )}


            <div className='comments'>
              <p style={{ fontWeight: 'bold' }}>Comment</p>
              <textarea onChange={(e) => setComment(e.target.value)} value={comment} name="" cols={90} placeholder='Write a comment' id=""></textarea>
              <button className='post' onClick={() => HandleComment()}>Post</button>
              <div className="title-comments">
                <p>Responses</p>
                <div>
                  <p style={{ cursor: "pointer" }} onClick={() => setFilterComments('desc')} className={`${filterComments === 'desc' && 'active'}`}>Newest</p>
                  <p style={{ cursor: "pointer" }} onClick={() => setFilterComments('most-liked')} className={`${filterComments === 'most-liked' && 'active'}`}>Most Liked</p>
                  <p style={{ cursor: "pointer" }} onClick={() => setFilterComments('asc')} className={`${filterComments === 'asc' && 'active'}`}>Oldest</p>
                </div>
              </div>
              <hr />
              {
                commentsLatestNews?.map(comment => {
                  return (
                    <>
                      <div key={Math.random()} className='comment-area'>
                        <div>
                          <div className='main-comment'>
                            {
                              (comment.id_remetente === idAccount && imageUrl) ?
                                <img src={imageUrl} alt="" /> :
                                <Avatar style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>{comment.full_name_remetente[0]}</Avatar>
                            }
                            <div className='icon-area'>

                              {
                                idAccount && <FaHeart className='icon-gray' style={{ cursor: "pointer" }} color={comment.like.includes(idAccount) ? 'red' : 'gray'} size={20} onClick={() => handleLike(comment?.id)} />
                              }


                              <small>{comment.like.length >= 1 && comment.like.length}</small>
                            </div>

                            <div>
                              <p style={{ fontWeight: 'bold', color: "#252525" }}>{comment.full_name_remetente}</p>
                              <p>{comment.content}</p>
                            </div>
                          </div>
                          <div className='comment-edit'>
                            <small style={{ color: "#252525", fontWeight: 'bold' }}>{semanasOuDiasPassados(comment.createdAt)}</small>
                            {
                              (superAdmin || comment.id_remetente === idAccount) && (
                                <BiTrash style={{ cursor: 'pointer' }} onClick={() => deleteComment(comment.id)} />
                              )
                            }
                          </div>

                        </div>

                      </div>
                      <hr />
                    </>
                  )
                })
              }

            </div>
            <div>


            </div>
          </div>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container>

        {images &&
          sortItemsByActiveAndDate(images).map((image: any, index: number) => {
            if (!isDateValid(image.publish_date)) {
              if (!superAdmin) {
                return <></>
              }
            }
            if (image.active === false) {
              if (!superAdmin) {
                return <></>
              }
            }
            return (
              <Grid key={Math.random()}>
                <div className='header'>
                  <p>{formatDate(image.createdAt.slice(0, 10))}</p>
                  {
                    (superAdmin && image.idea) && (
                      <button
                        className={image.idea && 'active'}
                        style={{ background: 'purple' }}
                      >
                        {image.idea && 'Idea Submission'}
                      </button>
                    )
                  }
                  <div>
                    {
                      superAdmin && image.publish_date && (
                        <button
                          style={{ marginRight: 8, backgroundColor: '#b400bd' }}
                        >
                          {image?.publish_date ? `Publish Date ${image?.publish_date.split('T')[0]}` : ''}
                        </button>

                      )
                    }
                    {
                      superAdmin && (
                        <button
                          className={image.active && 'active'}
                          onClick={() =>
                            handleView({
                              id: image.id,
                              active: image.active
                            })
                          }
                        >
                          {image.active ? 'Public' : 'Private'}
                        </button>
                      )
                    }

                  </div>

                </div>
                <div
                  className='content'

                >
                  {image.image_url ? (<img src={image.image_url} alt='' />) : (<video src={image.video_url} poster={image.thumbnail_url} loop controls={true} ></video>)}

                  <h1 className='text'>{image.title}</h1>
                  <p className='text'>{image.prev_legend}</p>
                  <button onClick={() => {
                    attParam(image.id)
                    handleOpenLatestTarget(images[index]);
                  }}>Read Full Article</button>
                </div>
              </Grid>
            );
          })}
      </Container>
    );
  }
}
