import React, { useEffect, useState } from 'react';
import { CallendarFull, Container, DivisorContent } from './styled';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import GridLatestNews from '../../components/GridLatestNews';
import api from 'src/services/axios';
import ViewPoll from 'src/components/AdminTriviaComp/ViewPoll';
import { Poll } from '@material-ui/icons';
import ViewQuestion from 'src/components/AdminTriviaComp/ViewQuestion';
import CreateQuestion from 'src/components/AdminTriviaComp/CreateQuestion';
import LatestNews from 'src/components/LatestNews';
import CreatePoll from 'src/components/AdminTriviaComp/CreatePoll';
interface Options {
  option: string;
  id: string;
  count?: number;
  percentage?: string;
}

interface Question {
  id: string;
  question_answer: string;
  options: Options[];
  correct_option_id: string;
  date: string;
  active: boolean;
}
interface Polls {
  id: string;
  polls_answer: string;
  options: Options[];
  date_start: string;
  date_end: string;
  totalUsers?: number;
}
export default function NewCalendar(): JSX.Element {
  const [polls, setPolls] = useState<Polls[]>([]);
  const [latestNews, setLatestNews] = useState<any[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [poll, setPoll] = useState<Polls>();
  const [question, setQuestion] = useState<Question>();
  const [createEvent, setCreateEvent] = useState('question');
  const formatedDateLatest = (item: any) => {
    if (item.publish_date) {
      return item.publish_date;
    } else {
      return item.createdAt;
    }
  };
  const formatedEvents = () => {
    const formatEvent = questions.map((q) => ({
      title: q.question_answer,
      start: q.date,
      end: q.date,
      id: q.id,
      type: 'question',
      data: { ...q }
    }));
    const formatPolls = polls?.map((p) => ({
      title: p.polls_answer,
      start: p.date_start,
      end: p.date_end,
      id: p.id,
      type: 'poll',
      data: {
        id: p.id,
        question_answer: p.polls_answer,
        options: p.options,
        correct_option_id: '',
        date: p.date_start,
        active: true
      }
    }));
    const formatLatestNews = latestNews.map((news) => ({
      title: news.title || 'Latest News',
      start: formatedDateLatest(news),
      end: formatedDateLatest(news),
      id: news.id,
      type: 'news',
      data: {
        id: news.id,
        question_answer: news.title,
        options: [],
        correct_option_id: '',
        date: formatedDateLatest(news),
        active: true
      }
    }));

    return formatEvent.concat(formatPolls).concat(formatLatestNews);
  };
  const getQuestion = () => {
    api.get('/api/v1/trivia/month').then((res) => {
      setQuestions(res.data.question);
    });
  };

  const getPolls = () => {
    api.get('/api/v1/polls/calendar').then((res) => {
      console.log(res.data);
      setPolls(res.data.polls);
    });
  };

  function getLatestNews() {
    api.get('/api/v1/latest-news').then((res) => {
      setLatestNews(res.data);
    });
  }

  useEffect(() => {
    getQuestion();
    getPolls();
    getLatestNews();
  }, []);

  const getEventClassNames = (eventInfo: any) => {
    switch (eventInfo.event.extendedProps.type) {
      case 'question':
        return 'task-question';
      case 'poll':
        return 'task-poll';
      case 'news':
        return 'task-news';
      default:
        return '';
    }
  };
  const handleEventClick = (info: any) => {
    setSelectedEvent(info.event);
    if (info.event.extendedProps.type === 'poll') {
      api.get(`/api/v1/polls/find-poll/${info.event.id}`).then((response) => {
        console.log(response.data);
        setPoll(response.data);
        return;
      });
    }
    if (info.event.extendedProps.type === 'question') {
      api.get(`/api/v1/trivia/find/${info.event.id}`).then((response) => {
        console.log(response.data);
        setQuestion(response.data.question);
        return;
      });
    }
  };
  const contentEvent = () => {
    if (!selectedEvent) return '';

    if (selectedEvent) {
      switch (selectedEvent.extendedProps.type) {
        case 'question':
          return (
            <ViewQuestion
              handleEditQuestion={() => console.log()}
              selectedQuestion={question}
            />
          );
        case 'poll':
          return (
            <ViewPoll
              selectedPoll={poll}
              handleEditPoll={() => {
                console.log('Edit Poll');
              }}
            />
          );
        case 'news':
          return <GridLatestNews id={selectedEvent.id} />;
        default:
          return <div></div>;
      }
    }
  };

  return (
    <Container>
      <DivisorContent>
        <div className='event-content'>
          <button className='btn-create-content'>Create New event</button>
          <select
            style={{ marginLeft: 10 }}
            onChange={(e) => setCreateEvent(e.target.value)}
            value={createEvent}
          >
            <option value=''>Select a event</option>
            <option value='poll'>Poll</option>
            <option value='question'>Question</option>
            <option value='news'>Latest News</option>
            <option value='news'>Newsletter</option>
          </select>
          {createEvent === 'question' && <CreateQuestion />}
          {createEvent === 'poll' && <CreatePoll />}
          {createEvent === 'news' && <LatestNews view={true} />}

          <div className='content'>{contentEvent()}</div>
        </div>

        <CallendarFull>
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            eventClick={handleEventClick}
            events={formatedEvents()}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            eventClassNames={getEventClassNames}
            timeZone='US/Central'
            handleWindowResize={true}
            displayEventTime={false}
          />
        </CallendarFull>
      </DivisorContent>
    </Container>
  );
}
