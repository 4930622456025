export default function formatDate(dateProp: string): string {
  const date = new Date(dateProp);

  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };

  const americanFormatWithTime = date.toLocaleString('en-US', options);

  return americanFormatWithTime;
}
