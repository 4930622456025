import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { PrimarySearchAppBar } from './appbar';
import { WorkOutlineOutlined } from '@material-ui/icons';
import jwt from 'jsonwebtoken';
import { useCookies } from 'react-cookie';
import Monograma from '../../images/Monograma.png';
import Loginbutton from '../LoginButton';
import Notifications from '../Notifications';
import Tooltip from '../SideBar/Tooltip';
import axios from '../../services/axios';
import PrivacyPolitc from '../PrivacyPolicy';
import PasswordExpired from '../PasswordExpired';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Container } from './styled';
import { RiChat4Line, RiChatSettingsLine } from 'react-icons/ri';
import { FaAngleDown, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FiBookOpen, FiCalendar, FiFolder, FiHome, FiMapPin, FiMonitor, FiPieChart, FiPlusSquare, FiShield, FiUserCheck, FiUsers } from 'react-icons/fi';
import { MdMessage } from 'react-icons/md';

const drawerWidth = 240;
interface BasicMenuProps {
  children: React.ReactNode;
}



export default function MiniDrawer({ children }: BasicMenuProps) {
  const theme = useTheme();
  const nav = useHistory();
  const [open, setOpen] = React.useState(true);
  const [drawer, setDrawer] = useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const changeDrawer = () => {
    setDrawer(!drawer);
  };

  const handleSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
    setDrawer(false);
  };
  const [actived, setActived] = useState(window.location.pathname);
  const [cookies, ,] = useCookies(['token']);
  const token: any = cookies.token;
  const repeatTooltip: any = cookies.repeatTooltip;
  const [firstAccess, setFirstAccess] = useState(false);
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(true);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [permissions, setPermissions] = useState({
    announcements: { list: false, read: false, write: false },
    events: { list: false, read: false, write: false },
    groups: { list: false, read: false, write: false },
    locations: { list: false, read: false, write: false },
    users: { list: false, read: false, write: false },
    analytics: { list: false, read: false, write: false }
  });
  const [fegpt, setFegpt] = useState(false);
  const [activedDown, setActivedDown] = useState('');
  const [permissions2, setPermissions2] = useState({
    home_page: { view: false, add: false, edit: false, delete: false },
    announcements: { view: false, add: false, edit: false, delete: false },
    user_validation: { view: false, add: false, edit: false, delete: false },
    user_management: { view: false, add: false, edit: false, delete: false },
    location: { view: false, add: false, edit: false, delete: false },
    events: { view: false, add: false, edit: false, delete: false },
    analytics: { view: false, add: false, edit: false, delete: false },
    shortcuts: { view: false, add: false, edit: false, delete: false },
    permissions_management: {
      view: false,
      add: false,
      edit: false,
      delete: false
    },
    map_jobtitle: { view: false, add: false, edit: false, delete: false }
  });
  useEffect(() => {
    handlePermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openNav(act: string) {
    setFegpt(!fegpt)
    activedDown === act ? setActivedDown("") : setActivedDown(act)

  }
  const location = useLocation();

  function handlePermissions() {
    if (localStorage.getItem('token')) {
      jwt.verify(
        localStorage.getItem('token')!.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        async (err: any, decoded: any) => {
          if (err) {
            alert(err);
          } else {
            console.log(decoded);
            setPermissions(decoded.modules);
            setPermissions2(decoded.roles);

            const customer_id = localStorage.getItem('id');
            const response = await axios
              .get(`api/v1/accounts/${customer_id}`)
              .then((response) => response.data.results);
            setFirstAccess(response.first_access);
            setPasswordExpired(response.password_expired_time);
            setPrivacyPolicy(response.accepted_privacy_policy);
            if (response.first_access) {
              axios.put(`api/v1/accounts/${customer_id}/user/first_access`);
            } else if (repeatTooltip === 'true') {
              setFirstAccess(true);
            }
            if (response.group_id === '128e5e58-3c0a-4e63-9be1-d0b6fc2f871e') {
              setSuperAdmin(true);
            }
          }
        }
      );
      return;
    }
    jwt.verify(
      token!.toString(),
      '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
      async (err: any, decoded: any) => {
        if (err) {
          alert(err);
        } else {
          setPermissions(decoded.modules);
          setPermissions2(decoded.roles);

          const customer_id = localStorage.getItem('id');
          const first_access = await axios
            .get(`api/v1/accounts/${customer_id}`)
            .then((response) => response.data.results.first_access);
          setFirstAccess(first_access);
          if (first_access) {
            axios.put(`api/v1/accounts/${customer_id}/user/first_access`);
          } else if (repeatTooltip === 'true') {
            setFirstAccess(true);
          }
        }
      }
    );
  }
  const handleNav = (url: any) => {
    nav.push(url)
    setActived(url)
    setSidebarOpen(false)
    setDrawer(false)
  };

  function setTooltipPathName(name: string) {
    handleDrawerClose();
    if (name) setActived(name);
  }

  function handleAccessPermissions() {
    /*if (!privacyPolicy) {
      return <PrivacyPolitc setPrivacyTerm={setPrivacyPolicy} />;
    }*/
    if (passwordExpired) {
      return <PasswordExpired setPasswordExpired={setPasswordExpired} />;
    }
    /*if (firstAccess) {
      return <Tooltip setTooltipPath={setTooltipPathName} />;
    }*/
    return <></>;
  }

  return (
    <div>
      <CssBaseline />
      <PrimarySearchAppBar
        handleDrawerOpen={handleDrawerOpen}
        handleSidebarOpen={handleSidebarOpen}
        drawer={changeDrawer}
        sidebarStatus={sidebarOpen}
      />
      <Container style={{ width: `${(sidebarOpen && drawer) ? "100%" : "0"}` }}>
        <div>
          <div
            onClick={() => handleNav('/')}
            className='list'
          >
            <label className={`${actived === '/' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Home</p>
            <FiHome size={20} />
          </div>
          <div
            onClick={() => handleNav('/new-home')}
            className='list'
          >
            <label className={`${actived === '/new-home' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>New Home</p>
            <FiHome size={20} />
          </div>
          <div
            onClick={() => handleNav('/list-shortcuts')}
            className='list'
          >
            <label className={`${actived === '/list-shortcuts' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>New FEG App</p>
            <FiHome size={20} />
          </div>
          {
            drawer && (
              <div
                onClick={() => openNav('/chat-fegpt')}
                className={`short ${activedDown === '/chat-fegpt' ? 'active opt' : ''}`}
              >     <label className={`${activedDown === '/chat-fegpt' ? 'bar-active act' : 'bar-active'}`}></label>
                <p>Ask FEG</p>

                {activedDown === '/chat-fegpt' ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
              </div>
            )
          }
          {
            fegpt && (
              <>
                <div
                  onClick={() => handleNav('/chat-fegpt')}
                  className='list-short'
                >
                  <label className={`${actived === '/chat-fegpt' ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Chat</p>
                  <RiChat4Line size={20} />
                </div>

                <div
                  onClick={() => handleNav('/settings-fegpt')}
                  className='list-short'
                >
                  <label className={`${actived === '/settings-fegpt' ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Upload Document</p>
                  <RiChatSettingsLine size={20} />
                </div>
                <div
                  onClick={() => handleNav('/knowledgebase')}
                  className='list-short'
                >
                  <label className={`${actived === '/knowledgebase' ? 'bar-active act' : 'bar-active'}`}></label>
                  <p>Repository</p>
                  <FiFolder size={20} />
                </div>
              </>
            )
          }
          <div
            className='list'
            onClick={() => handleNav('/announcements')}
          >
            <label className={`${actived === '/announcements' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Company Announ...</p>
            <FiBookOpen size={20} />
          </div>

          <div
            className='list'
            onClick={() => handleNav('/user')}
            style={{
              display: permissions2.user_validation.view ? 'flex' : 'none'
            }}
          >
            <label className={`${actived === '/user' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Users Validations</p>
            <FiUserCheck size={20} />
          </div>

          <div
            className='list'
            onClick={() => handleNav('/management')}
            style={{
              display: permissions2.user_management.view ? 'flex' : 'none'
            }}
          >
            <label className={`${actived === '/management' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Users Management</p>
            <FiUsers size={20} />
          </div>

          <div
            className='list'
            style={{ display: permissions2.announcements.add ? 'flex' : 'none' }}
            onClick={() => handleNav('/announcements/new')}

          >
            <label className={`${actived === '/announcements/new' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>New Announcement</p>
            <FiPlusSquare size={20} />
          </div>

          <div
            className='list'
            style={{ display: permissions2.location.view ? 'flex' : 'none' }}
            onClick={() => handleNav('/locations')}
          >
            <label className={`${actived === '/locations' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Location Management</p>
            <FiMapPin size={20} />
          </div>

          <div
            className='list'
            onClick={() => handleNav('/calendar')}
          >
            <label className={`${actived === '/calendar' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Company Calendar</p>
            <FiCalendar size={20} />
          </div>

          <div
            className='list'
            onClick={() => handleNav('/analytics')}
            style={{ display: permissions2.analytics.view ? 'flex' : 'none' }}
          >
            <label className={`${actived === '/analytics' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Analytics</p>
            <FiPieChart size={20} />
          </div>

          <div
            className='list'
            onClick={() => handleNav('/shortcuts')}
            style={{ display: permissions2.shortcuts.view ? 'flex' : 'none' }}
          >
            <label className={`${actived === '/shortcuts' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>FEG Apps</p>
            <FiMonitor size={20} />
          </div>
          {superAdmin && (
            <div
              className='list'
              onClick={() => handleNav('/mapJobtitle')}

            >
              <label className={`${actived === '/mapJobtitle' ? 'bar-active act' : 'bar-active'}`}></label>
              <p>JobTitle</p>
              <WorkOutlineOutlined />
            </div>
          )}




          <div
            className='list'
            onClick={() => handleNav('/permissions')}
            style={{
              display: permissions2.permissions_management.view ? 'flex' : 'none'
            }}
          >
            <label className={`${actived === '/permissions' ? 'bar-active act' : 'bar-active'}`}></label>
            <p>Permissions</p>
            <FiShield size={20} />
          </div>
          {
            <div className='ask-fegpt'>
              <button className="ask-fegpt" onClick={() => handleNav('/chat-fegpt')}>Ask FEG</button>
            </div>
          }
        </div>
      </Container>


      {children}

    </div>
  );
}



