import styled from 'styled-components';

export const EmailEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
  height: 500px;
  background-color: #f5f5f5;
  border-radius: 5px;

  div#editor-container {
    width: 100%;
    height: 100%;
  }
`;

export const UsersEmailTest = styled.div`
  margin-left: 30px;
  select {
    max-width: 250px;
    margin-top: 10px;
    padding: 10px;
  }
  ul {
    li {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: aliceblue;
      min-width: 300px;
      width: min-content;
      max-width: 500px;
      padding: 10px;
      margin-bottom: 5px;
    }
  }
  .btn-remove-user {
    border: none;
    margin: 0;
    padding: 0px;
    background-color: transparent;
    width: 20px;
    margin-top: 6px;
  }
`;
