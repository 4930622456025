import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { QuestionContainer, CreateQuestionContainer } from './styled';
import { Theme } from 'src/styles/Theme';
import api from 'src/services/axios';
import { set } from 'react-ga';
interface Options {
  option: string;
  id: string;
}
interface Option {
  id: number | string;
  text: string;
}

interface Polls {
  id: string;
  polls_answer: string;
  options: Options[];
  date_start: string;
  date_end: string;
}
const Card = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  gap: 10px;
  padding: 18px;
  width: 100%;
  margin-bottom: 15px;
`;
interface ProgressProps {
  percent: number;
}
const Progress = styled.div<ProgressProps>`
  height: 8px;
  background-color: #4caf50;
  width: ${({ percent }) => percent}%;
  transition: width 0.3s ease;
`;

const ContainerTaskDescription = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    text-align: center;
    justify-content: center;
    align-self: center;
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 8px;
  &.correct {
    border: 2px solid ${Theme.primary};
  }
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
`;

const ProgressBar = styled.div`
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  width: 94%;
  margin: 0 auto;
`;

export default function UpdatePoll({
  selectedPoll
}: {
  selectedPoll: Polls | undefined;
}) {
  const [poll, setPoll] = useState('');
  const [options, setOptions] = useState<Option[]>([{ id: 1, text: '' }]);

  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');

  const handleOptionChange = (id: number | string, text: string) => {
    setOptions(options.map((opt) => (opt.id === id ? { ...opt, text } : opt)));
  };

  useEffect(() => {
    if (!selectedPoll) return;
    setPoll(selectedPoll.polls_answer || '');
    setOptions(
      selectedPoll?.options.map((opt) => ({
        id: opt.id,
        text: opt.option
      }))
    );
    //pegar apenas 10 primeira letras
    setDateEnd(selectedPoll.date_end.slice(0, 10));
    setDateStart(selectedPoll.date_start.slice(0, 10));
  }, [selectedPoll]);

  const handleUpdateQuestion = () => {
    const updatedQuestion = {
      id: selectedPoll?.id,
      question_answer: poll,
      options: options,
      date_start: dateStart,
      date_end: dateEnd
    };
    console.log(updatedQuestion);

    api.put('/api/v1/polls/update', updatedQuestion).then(() => {
      alert('Quizz Updated');
      window.location.reload();
    });
  };

  return (
    <CreateQuestionContainer>
      <h2>Polls</h2>

      <div className='header-create-question'>
        <label>Poll:</label>
        <input
          type='text'
          value={poll}
          placeholder='Type your question here'
          onChange={(e) => setPoll(e.target.value)}
          className='answer input-create-question'
        />
      </div>

      <div>
        <label>Option Answer:</label>
        {options.map((option, index) => (
          <div key={option.id} className='input-option'>
            <input
              type='text'
              value={option.text}
              onChange={(e) => {
                handleOptionChange(option.id, e.target.value);
              }}
              className='input-create-question'
              placeholder={`Option ${index + 1}`}
            />
          </div>
        ))}
      </div>

      <div>
        <label>Date Start:</label>
        <input
          type='date'
          value={dateStart}
          onChange={(e) => setDateStart(e.target.value)}
          className='calendar-select-date'
        />
      </div>

      <div>
        <label>Date End:</label>
        <input
          type='date'
          value={dateEnd}
          onChange={(e) => setDateEnd(e.target.value)}
          className='calendar-select-date'
        />
      </div>

      <button
        style={{
          padding: '10px',
          marginTop: '20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          borderRadius: '8px'
        }}
        onClick={() => handleUpdateQuestion()}
      >
        Update Question
      </button>
    </CreateQuestionContainer>
  );
}
