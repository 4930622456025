import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 86%;
  margin: 0 auto;
  padding-bottom: 50px;
`;

export const ContainerWelcome = styled.div`
  background: rgb(8, 5, 117);

  border-radius: 18px;
  padding: 26px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }
  .welcome-title {
    gap: 18px;
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
      justify-content: start;
      width: 100%;
      padding: 20px;
      h1 {
        font-size: 18px;
        padding: 8px 0;
      }
      p {
        font-size: 11px;
      }
    }

    img {
      width: 90px;
      height: 90px;
      border: 2px solid ${Theme.secondary};
      padding: 3px;
      border-radius: 50%;
    }
    div.welcome-title-container {
      max-width: 70%;
      h1 {
        color: ${Theme.secondary};
      }
      p {
        color: ${Theme.secondary};
      }
    }
  }
  .welcome-icons {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    gap: 22px;
    a {
      color: none;
      text-decoration: none;
      color: ${Theme.primary};
      width: 100%;
    }
    div.welcome-text {
      display: flex;
      flex-direction: column;
      p {
        font-size: 10px;
        margin: 0;
        padding: 0;
      }
      @media (max-width: 700px) {
        small {
          display: none;
        }
      }
    }
    @media (max-width: 1100px) {
      width: 100%;
      gap: 4px;
    }

    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 10px;
      cursor: pointer;
      height: 90px;
      background-color: ${Theme.secondary};
      color: ${Theme.primary};
      border: none;
      border-radius: 18px;

      @media (max-width: 1100px) {
        width: 100%;
        height: 60px;
        padding: 6px;
        margin: 0;
      }
      img {
        width: 40px;
        height: 40px;
        @media (max-width: 1100px) {
          width: 30px;
          height: 30px;
        }
      }
      p {
        font-size: 18px;
        font-weight: bold;
        @media (max-width: 1100px) {
          font-size: 12px;
        }
      }
    }
  }
`;

export const ContainerContent = styled.div`
  width: 100%;
  display: flex;
  div.blog {
    width: 45%;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const FirstContainerContent = styled.div`
  width: 70%;
  padding-right: 28px;
  @media (max-width: 1100px) {
    width: 100%;
    padding: 0;
  }
  h1.carousel {
    color: ${Theme.primary};

    @media (max-width: 1100px) {
      margin: 4px 0;
    }
  }

  div.title-fav {
    display: flex;
    align-items: center;

    justify-content: space-between;
    margin: 24px 0;
    h1 {
      color: ${Theme.primary};
    }
    p {
      color: ${Theme.primary};
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

export const SecondContainerContent = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1100px) {
    width: 100%;
    padding: 0;
  }
  h1 {
    margin: 24px 0;
    color: ${Theme.primary};
  }
`;
export const ContainerIdea = styled.div`
  display: flex;
  align-items: center;
  justify-content: initial;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  gap: 20px;

  h1 {
    width: max-content;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    margin-bottom: 20px;
    gap: 6px;
  }
`;
export const BtnContainerIdea = styled.button`
  border-radius: 34px;
  border: none;
  background-color: ${Theme.primary};
  color: white;
  padding: 15px;
  min-width: 240px;
  min-height: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  @media (max-width: 1100px) {
    min-height: auto;
    min-width: auto;
    padding: 12px;
    font-size: 16px;
    margin: 0;
  }
`;
