import React, { useEffect, useState } from 'react';
import {
  CardContainer,
  NoHavePollContainer,
  QuestionContainer
} from './styled';
import api from 'src/services/axios';
import troph from 'src/images/troph.png';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { Theme } from 'src/styles/Theme';
import {
  FormControlLabel,
  Radio,
  RadioProps,
  withStyles
} from '@material-ui/core';

interface Options {
  option: string;
  id: string;
  count?: number;
  percentage?: string;
}

interface Polls {
  id: string;
  polls_answer: string;
  options: Options[];
  date_start: string;
  date_end: string;
  active: boolean;
}
interface ResponseQuestion {
  user_id: string;
  task_id: string;
  responses_task_id: string;
}

interface Ranking {
  name: string;
  correct: number;
  total: number;
  url_image: string;
}
const Card = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  gap: 10px;
  padding: 18px;
  width: 100%;
  margin-bottom: 15px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 8px;
  &.correct {
    border: 2px solid ${Theme.primary};
  }
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
`;

const ProgressBar = styled.div`
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  width: 94%;
  margin: 0 auto;
`;
const GreenRadio = withStyles({
  root: {
    color: '#ccc',
    '&$checked': {
      color: Theme.primary
    }
  },
  checked: {}
})((props: RadioProps) => <Radio color='default' {...props} />);

interface ProgressProps {
  percent: number;
}

const Progress = styled.div<ProgressProps>`
  height: 8px;
  background-color: #4caf50;
  width: ${({ percent }) => percent}%;
  transition: width 0.3s ease;
`;

const ContainerTaskDescription = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    text-align: center;
    justify-content: center;
    align-self: center;
  }
`;
export const PollsCard = () => {
  const [polls, setPolls] = useState<Polls>();
  const [answers, setAnswers] = useState<ResponseQuestion>();
  const [responseTrivia, setResponseTrivia] = useState(false);
  const [userScore, setUserScore] = useState(0);
  const [ranking, setRanking] = useState<Ranking[]>([]);
  const [userRanking, setUserRanking] = useState(0);
  const [realCorrectOption, setRealCorrectOption] = useState('');
  const [rankingOptions, setRankingOptions] = useState<Options[]>([]);
  const [userId, setUserId] = useState('');
  const [historyQuestion, setHistoryQuestion] = useState(false);
  const languages = [
    { id: 1, name: 'HTML', votes: 25 },
    { id: 2, name: 'Java', votes: 15 },
    { id: 3, name: 'Python', votes: 40 },
    { id: 4, name: 'jQuery', votes: 20 }
  ];
  const [votes, setVotes] = useState(languages);
  const [selected, setSelected] = useState<number | null | string>();
  const [noHavePolls, setNoHavePolls] = useState(false);
  const totalVotes = votes.reduce((sum, lang) => sum + lang.votes, 0);

  function getPolls(userIdParam: string) {
    api
      .post('/api/v1/polls/question', {
        customers_id: userIdParam
      })
      .then((res) => {
        if (res.data?.polls?.existsPontuation) {
          setHistoryQuestion(true);
          setRealCorrectOption(
            res.data?.polls.existsPontuation.response_user_polls_id
          );
          setRankingOptions(res.data.polls.options);
          setPolls(res.data?.polls.question);
        } else if (res.data?.polls?.question) {
          setPolls(res.data?.polls.question);
        } else {
          setNoHavePolls(true);
        }
      });
  }

  useEffect(() => {
    async function getUserId() {
      const userId = await localStorage.getItem('id');
      if (userId) {
        console.log(userId);
        getPolls(userId);
        setUserId(userId);
      }
    }
    getUserId();
  }, []);

  const handleSubmit = () => {
    setResponseTrivia(true);
    console.log('answers:', answers);
    const data = {
      response_user_polls_id: selected,
      polls_id: polls?.id,
      customers_id: userId
    };
    console.log(data);
    api
      .post('/api/v1/polls/user-pontuation', {
        response_user_polls_id: selected,
        polls_id: polls?.id,
        customers_id: userId
      })
      .then((res) => {
        alert('Response sent successfully!');
        window.location.reload();
      });
  };

  return (
    <AnimatePresence>
      <h1 style={{ margin: 0, marginLeft: 18, marginTop: 8 }}>Polls</h1>

      <CardContainer>
        {historyQuestion ? (
          <>
            <QuestionContainer>
              {polls ? (
                <>
                  <div className='task'>
                    <motion.h1 animate={{ scale: [1, 1.2, 1] }}>
                      {polls?.polls_answer}
                    </motion.h1>
                    <Card>
                      {rankingOptions.map((option, index) => (
                        <Item
                          key={option.id}
                          className={`${realCorrectOption === option.id && 'correct'
                            }`}
                        >
                          <ContainerTaskDescription>
                            <Label style={{ fontWeight: 'bold' }}>
                              <FormControlLabel
                                control={<GreenRadio />}
                                label={
                                  <p style={{ fontSize: 18 }}>
                                    {option.option}
                                  </p>
                                }
                                style={{
                                  margin: 0,
                                  padding: 0
                                }}
                                checked={realCorrectOption === option.id}
                              />
                            </Label>
                            <span style={{ fontWeight: 'bold' }}>
                              {option.percentage?.split('.')[0]}%
                            </span>
                          </ContainerTaskDescription>
                          <ProgressBar>
                            <Progress
                              style={{
                                background:
                                  realCorrectOption === option.id
                                    ? Theme.primary
                                    : '#ccc'
                              }}
                              percent={Number(option.percentage?.split('.')[0])}
                            />
                          </ProgressBar>
                        </Item>
                      ))}
                    </Card>
                  </div>
                </>
              ) : noHavePolls ? (
                <p>Nao tem question</p>
              ) : (
                <p>Loading Question</p>
              )}
            </QuestionContainer>
          </>
        ) : (
          <>
            <QuestionContainer>
              {polls ? (
                <>
                  <small>End: {polls.date_end.split('T')[0]}</small>
                  <div className='task'>
                    <motion.h1 animate={{ scale: [1, 1.2, 1] }}>
                      {polls?.polls_answer}
                    </motion.h1>

                    <Card>
                      {polls.options.map((option, index) => (
                        <Item
                          key={option.id}
                          className={`${selected === option.id && 'correct'}`}
                        >
                          <ContainerTaskDescription>
                            <Label style={{ fontWeight: 'bold' }}>
                              <FormControlLabel
                                control={<GreenRadio />}
                                label={
                                  <p style={{ fontSize: 18 }}>
                                    {option.option}
                                  </p>
                                }
                                style={{
                                  margin: 0,
                                  padding: 0
                                }}
                                onChange={() => setSelected(option.id)}
                                checked={selected === option.id}
                              />
                            </Label>
                          </ContainerTaskDescription>
                          <ProgressBar></ProgressBar>
                        </Item>
                      ))}
                    </Card>
                  </div>
                  {selected && (
                    <motion.button
                      animate={{ scale: [1, 1.2, 1.3, 1] }}
                      onClick={handleSubmit}
                      className='submit-button'
                    >
                      Send Responses 🎊
                    </motion.button>
                  )}
                </>
              ) : noHavePolls ? (
                <NoHavePollContainer>
                  No question for this date.
                </NoHavePollContainer>
              ) : (
                <p>Loading Question</p>
              )}
            </QuestionContainer>
          </>
        )}
      </CardContainer>
    </AnimatePresence>
  );
};
