import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import {
  AdminTriviaContainer,
  CallendarFull,
  DivisorScoreboard,
  SelectOption
} from './styled';

import api from 'src/services/axios';
import CreatePoll from 'src/components/AdminTriviaComp/CreatePoll';
import CreateQuestion from 'src/components/AdminTriviaComp/CreateQuestion';
import UpdateQuestion from 'src/components/AdminTriviaComp/UpdateQuestion';
import ViewQuestion from 'src/components/AdminTriviaComp/ViewQuestion';
import ViewPoll from 'src/components/AdminTriviaComp/ViewPoll';
import UpdatePoll from 'src/components/AdminTriviaComp/UpdatePoll';
import Scoreboard from 'src/components/AdminTriviaComp/Scoreboard';

interface Options {
  option: string;
  id: string;
  count?: number;
  percentage?: string;
}

interface Question {
  id: string;
  question_answer: string;
  options: Options[];
  correct_option_id: string;
  date: string;
  active: boolean;
}
interface Polls {
  id: string;
  polls_answer: string;
  options: Options[];
  date_start: string;
  date_end: string;
  totalUsers?: number;
}

const AdminTriviaPage: React.FC = () => {
  const [selectedQuestion, setSelectedQuestion] = useState<Question>();
  const [isEditing, setIsEditing] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [typeForm, setTypeForm] = useState('trivia');
  const [polls, setPolls] = useState<Polls[]>([]);
  const [selectedPolls, setSelectedPolls] = useState<Polls>();
  const [latestNews, setLatestNews] = useState<any[]>([]);

  const formatedEvents = () => {
    const formatEvent = questions.map((q) => ({
      title: q.question_answer,
      start: q.date,
      end: q.date,
      id: q.id,
      type: 'question'
    }));
    const formatPolls = polls?.map((p) => ({
      title: p.polls_answer,
      start: p.date_start,
      end: p.date_end,
      id: p.id,
      type: 'poll'
    }));
    const formatLatestNews = latestNews.map((news) => ({
      title: news.title,
      start: news.createdAt,
      end: news.createdAt,
      id: news.id,
      type: 'news'
    }));

    return formatEvent.concat(formatPolls, formatLatestNews);
  };

  const getQuestion = () => {
    api.get('/api/v1/trivia/month').then((res) => {
      setQuestions(res.data.question);
    });
  };

  const getPolls = () => {
    api.get('/api/v1/polls/calendar').then((res) => {
      setPolls(res.data.polls);
    });
  };

  function getLatestNews() {
    api.get('/api/v1/latest-news').then((res) => {
      setLatestNews(res.data);
    });
  }

  useEffect(() => {
    getQuestion();
    getPolls();
    getLatestNews();
  }, []);

  const handleEditQuestion = () => {
    if (selectedQuestion) {
      setTypeForm('edit_question');
      setIsEditing(true);
    }
  };
  const handleEditPoll = () => {
    if (selectedPolls) {
      setTypeForm('edit_poll');
      setIsEditing(true);
    }
  };

  const handleEventClick = (info: any) => {
    const event = info.event;
    setSelectedQuestion(undefined);
    setSelectedPolls(undefined);
    setIsEditing(false);
    if (event.extendedProps.type === 'question') {
      setTypeForm('view_question');
      setSelectedQuestion(questions.find((q) => q.id === event.id));
      setSelectedPolls(undefined);
      console.log(questions.find((q) => q.id === event.id));
    } else if (event.extendedProps.type === 'poll') {
      setTypeForm('view_poll');
      setSelectedPolls(polls.find((p) => p.id === event.id));
      setSelectedQuestion(undefined);
      console.log(polls.find((p) => p.id === event.id));
    }
  };

  const getEventClassNames = (eventInfo: any) => {
    switch (eventInfo.event.extendedProps.type) {
      case 'question':
        return 'task-question';
      case 'poll':
        return 'task-poll';
      case 'news':
        return 'task-news';
      default:
        return '';
    }
  };
  const handleContent = () => {
    switch (typeForm) {
      case 'trivia':
        return <CreateQuestion />;
      case 'polls':
        return <CreatePoll />;
      case 'edit_question':
        return <UpdateQuestion questionEdit={selectedQuestion} />;
      case 'edit_poll':
        return <UpdatePoll selectedPoll={selectedPolls} />;
      case 'view_question':
        return (
          <ViewQuestion
            handleEditQuestion={handleEditQuestion}
            selectedQuestion={selectedQuestion}
          />
        );
      case 'view_poll':
        return (
          <ViewPoll
            handleEditPoll={handleEditPoll}
            selectedPoll={selectedPolls}
          />
        );
      case 'ranking':
        return <Scoreboard />;
      default:
        return <CreateQuestion />;
    }
  };

  return (
    <>
      <SelectOption>
        <div>
          <button
            onClick={() => {
              setIsEditing(false);
              setTypeForm('trivia');
            }}
            className={`${typeForm === 'trivia' && 'active'}`}
          >
            <h1>Create a Trivia Question</h1>
          </button>

          <button
            className={`${typeForm === 'polls' && 'active'}`}
            onClick={() => {
              setIsEditing(false);
              setTypeForm('polls');
            }}
          >
            <h1>Create a Poll Question</h1>
          </button>
          <button
            onClick={() => {
              setIsEditing(false);
              setTypeForm('ranking');
            }}
            className={`${typeForm === 'ranking' && 'active'}`}
          >
            <h1>View Rankings</h1>
          </button>
          {isEditing && typeForm === 'edit_question' && (
            <button
              className={`${typeForm === 'edit_question' && 'active'}`}
              onClick={() => setTypeForm('edit_question')}
            >
              <h1>Editing Question</h1>
            </button>
          )}
          {typeForm === 'view_question' && (
            <button className={`${typeForm === 'view_question' && 'active'}`}>
              <h1>View Question</h1>
            </button>
          )}
          {typeForm === 'view_poll' && (
            <button className={`${typeForm === 'view_poll' && 'active'}`}>
              <h1>View Poll</h1>
            </button>
          )}
          {isEditing && typeForm === 'edit_poll' && (
            <button className={`${typeForm === 'edit_poll' && 'active'}`}>
              <h1>Edit Poll</h1>
            </button>
          )}
        </div>
      </SelectOption>

      <AdminTriviaContainer>
        <DivisorScoreboard>{handleContent()}</DivisorScoreboard>

        <CallendarFull>
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            events={formatedEvents()}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            eventClick={handleEventClick}
            eventClassNames={getEventClassNames}
            timeZone='US/Central'
            handleWindowResize={true}
            displayEventTime={false} // Remove a exibição do horário dos eventos
          />
        </CallendarFull>
      </AdminTriviaContainer>
    </>
  );
};

export default AdminTriviaPage;
