import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

export const CreateQuestionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  input.answer {
    width: 100%;
    margin-top: 8px;
  }
  label {
    font-weight: bold;
  }
  input.input-create-question {
    padding: 8px;
    background-color: #efefef;
    border: none;
    border-radius: 8px;
  }
  input.calendar-select-date {
    width: 100%;
    padding: 8px;
    background-color: #efefef;
    border: none;
    margin-top: 8px;
    border-radius: 8px;
  }
  div.input-option {
    width: 100%;
    display: flex;
    gap: 8px;

    margin-top: 8px;
    input.input-create-question {
      flex: 1;
    }
    button {
      background-color: ${Theme.primary};
      color: white;
      padding: 12px;
      border: none;
      cursor: pointer;
      border-radius: 8px;
    }
  }
  button.btn-new-option {
    background-color: ${Theme.primary};
    color: white;
    padding: 12px;
    border: none;
    cursor: pointer;
    margin-top: 8px;
    border-radius: 8px;
  }
`;
