import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';

const PontuationContainer = styled.div`
  width: 100%;
  margin: 0 0;
  display: flex;
  height: min-content;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;

  padding: 26px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 20px;
    margin: 0 auto;
  }
  select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: #fff;
  }
  .img-pontuation {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 26px;

    div.position-place {
      position: relative;
      p {
        background-color: #d4edda;
        padding: 0;
        margin: 0 auto;
        border-radius: 50%;
        width: 27px;
        height: 27px;
        font-weight: bold;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        font-size: 20px;
        color: white;
      }

      img {
        width: 60px;
        height: 60px;
        padding: 4px;
        border-radius: 50%;
        border: 4px solid #d4edda;
      }
    }
    div.place-1 {
      p {
        background-color: gold !important;
        font-size: 24px;
      }
      img {
        border: 4px solid gold;
        width: 65px;
        height: 65px;
      }
    }
    div.place-2 {
      p {
        background-color: silver !important;
        font-size: 22px;
      }
      img {
        border: 4px solid silver;
        width: 55px;
        height: 55px;
      }
    }
    div.place-3 {
      p {
        background-color: #cd7f32 !important;
        font-size: 20px;
      }
      img {
        border: 4px solid #cd7f32;
        width: 50px;
        height: 50px;
      }
    }
  }
  .header-pontuation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    img.img-troph {
      width: 80px;
      height: 80px;
      margin: 0 16px;
      @media (max-width: 768px) {
        width: 55px;
        height: 55px;
      }
    }
  }
  div.header-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    p {
      font-size: 14px;
      color: #333;
    }
  }
  h1.score-board-header {
    color: ${Theme.primary};
    font-size: 42px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  .scoreboard-container {
    background-color: #f5f5f5; /* Light grey background */
    width: 100%;

    border-radius: 8px;
  }
  td.name-td {
    font-size: 14px;
    font-weight: bold;
  }
  tr {
    font-weight: bold;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .header {
    background-color: #000080; /* Navy blue header */
    color: #ffffff; /* White text */
    width: 100%;
    margin: 0;

    display: flex;
    align-items: center;

    text-align: center;
  }

  .user-score {
    background-color: #e0f7fa; /* Light teal */
    color: #000080; /* Navy blue text */
    padding: 10px;
    border-radius: 5px;

    text-align: center;
    margin-top: 0px;
  }

  .score-table {
    width: 100%;
    background-color: #ffffff; /* White table background */
    color: #333333; /* Dark grey text */
    border-radius: 8px;
    overflow: hidden;
  }

  .score-table th {
    background-color: #000080; /* Navy blue header */
    color: #ffffff; /* White text */
    padding: 10px;
    @media (max-width: 768px) {
    }
  }

  .score-table th,
  .score-table td {
    padding: 10px;

    text-align: center;
  }

  .score-table td {
    padding: 10px;
    text-align: center;
  }

  .score-table tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Light grey for even rows */
  }

  .score-table tbody tr:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
  }
`;

export { PontuationContainer };
