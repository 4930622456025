import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  width: 90%;
  margin: 0 auto;
`;

export const Form = styled.form`
  max-width: 600px;
  min-width: 500px;
  display: flex;
  flex-direction: column;

  align-items: center;
  input,
  .MuiTextField-root,
  .MuiInputBase-root,
  .basic-multi-select,
  button {
    width: 300px;
  }
  gap: 10px;
`;

export const Divisor = styled.div`
  display: grid;
  grid-template-columns: 1fr 5px 1fr;
  div.border {
    height: 100%;

    width: 5px;
    background-color: #000;
  }
`;
