import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EditorState,
  Modifier,
  convertToRaw,
  AtomicBlockUtils
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios, { api } from '../../services/axios';
import { CiImageOn } from 'react-icons/ci';

import {
  PreviewContent,
  LoadingContainer,
  CallendarFull,
  MainContainer,
  ContainerForm,
  ExportContainerImage
} from './styled';

import { Theme } from 'src/styles/Theme';

import { FadeLoader } from 'react-spinners';
import 'react-calendar/dist/Calendar.css';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import GridLatestNews from '../GridLatestNews';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface Options {
  option: string;
  id: string;
  count?: number;
  percentage?: string;
}

interface Question {
  id: string;
  question_answer: string;
  options: Options[];
  correct_option_id: string;
  date: string;
  active: boolean;
}
interface Polls {
  id: string;
  polls_answer: string;
  options: Options[];
  date_start: string;
  date_end: string;
  totalUsers?: number;
}
interface LatestNewsProps {
  view?: boolean;
}

const LatestNews: React.FC<LatestNewsProps> = ({ view }) => {
  const nav = useHistory();
  const [title, setTitle] = useState<string>('');
  const [fullLegend, setFullLegend] = useState<any>(EditorState.createEmpty());
  const [prevLegend, setPrevLegend] = useState('');
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const [myPreview, setMyPreview] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [polls, setPolls] = useState<Polls[]>([]);
  const [latestNews, setLatestNews] = useState<any[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [publishDate, setPublishDate] = useState<string>('');
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [idLatestNews, setIdLatestNews] = useState<string>('');
  const [imageUrlFile, setImageUrlFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [notifyDesktop, setNotifyDesktop] = useState<boolean>(false);
  const [notifyMobile, setNotifyMobile] = useState<boolean>(false);

  const createImageUrl = async (file: any) => {
    const dataFile = new FormData();
    console.log(imageUrlFile);
    if (file) {
      dataFile.append('file', file);
    }
    await axios
      .post('/api/v1/latest-news/uploadFile', dataFile, { timeout: 0 })
      .then((res) => {
        console.log(res.data);
        insertImage(res.data.url);
      });
  };

  async function createAnnouncement() {
    const htmlDescription = draftToHtml(
      convertToRaw(fullLegend.getCurrentContent())
    );
    const dataFile = new FormData();
    console.log(file);
    if (file) {
      dataFile.append('file', file);
    }
    if (!title || !prevLegend) {
      alert('Please fill all fields');
      return;
    }
    if (!file) {
      alert('Please upload a file');
      return;
    }
    setLoading(true);
    await axios
      .post('/api/v1/latest-news/uploadFile', dataFile, { timeout: 0 })
      .then((res) => {
        if (file?.type === 'video/mp4' && thumbnail) {
          const dataThumbnail = new FormData();
          dataThumbnail.append('file', thumbnail);
          axios
            .post('/api/v1/latest-news/uploadFile', dataThumbnail, {
              timeout: 0
            })
            .then((thumbnail) => {
              const data = {
                image: res.data.type === 'image' ? res.data.url : '',
                video: res.data.type === 'video' ? res.data.url : '',
                prev_legend: prevLegend,
                full_legend: htmlDescription,
                publish_date: publishDate,
                title: title,
                idea: false,
                thumbnail: thumbnail.data.url,
                notify_desktop: notifyDesktop,
                notify_mobile: notifyMobile
              };
              axios
                .post('/api/v1/latest-news/create', data, {
                  headers: {
                    ContentType: 'multipart/form-data'
                  },
                  timeout: 0
                })
                .then(() => {
                  setLoading(false);
                  setTitle('');
                  setPrevLegend('');
                  setFullLegend('');
                  setConfirmation(false);
                  alert('Latest News created');
                  nav.push('/announcements');
                })
                .catch((err) => {
                  setLoading(false);
                  alert('Error creating Latest News');
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
              alert('Error creating Latest News');
              setLoading(false);
            });
        } else {
          const data = {
            image: res.data.type === 'image' ? res.data.url : '',
            video: res.data.type === 'video' ? res.data.url : '',
            prev_legend: prevLegend,
            full_legend: htmlDescription,
            publish_date: publishDate,
            title: title,
            idea: false,
            notify_desktop: notifyDesktop,
            notify_mobile: notifyMobile
          };
          axios
            .post('/api/v1/latest-news/create', data, {
              headers: {
                ContentType: 'multipart/form-data'
              },
              timeout: 0
            })
            .then(() => {
              setLoading(false);
              setTitle('');
              setPrevLegend('');
              setFullLegend('');
              setConfirmation(false);
              alert('Latest News created');
              nav.push('/announcements');
            })
            .catch((err) => {
              setLoading(false);
              alert('Error creating Latest News');
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Error creating Latest News');
        setLoading(false);
      });
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };
  const handleFileImageUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      createImageUrl(event.target.files[0]);
    }
  };
  const handleThumbnail = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setThumbnail(event.target.files[0]);
    }
  };

  window.onclick = function (event: any) {
    if (loading) {
      return;
    }
    if (event.target.className === 'modal') {
      setConfirmation(false);
    }
  };

  const formatedDateLatest = (item: any) => {
    if (item.publish_date) {
      return item.publish_date;
    } else {
      return item.createdAt;
    }
  };

  const formatedEvents = () => {
    const formatEvent = questions.map((q) => ({
      title: q.question_answer,
      start: q.date,
      end: q.date,
      id: q.id,
      type: 'question'
    }));
    const formatPolls = polls?.map((p) => ({
      title: p.polls_answer,
      start: p.date_start,
      end: p.date_end,
      id: p.id,
      type: 'poll'
    }));
    const formatLatestNews = latestNews.map((news) => ({
      start: formatedDateLatest(news),
      end: formatedDateLatest(news),
      id: news.id,
      createAt: news.createdAt,
      type: 'news',
      ...news
    }));

    return formatEvent.concat(formatPolls, formatLatestNews);
  };
  const getQuestion = () => {
    api.get('/api/v1/trivia/month').then((res) => {
      setQuestions(res.data.question);
    });
  };

  const getPolls = () => {
    api.get('/api/v1/polls/calendar').then((res) => {
      console.log(res.data);
      setPolls(res.data.polls);
    });
  };

  function getLatestNews() {
    api.get('/api/v1/latest-news').then((res) => {
      setLatestNews(res.data);
    });
  }

  useEffect(() => {
    getQuestion();
    getPolls();
    getLatestNews();
  }, []);

  const getEventClassNames = (eventInfo: any) => {
    switch (eventInfo.event.extendedProps.type) {
      case 'question':
        return 'task-question';
      case 'poll':
        return 'task-poll';
      case 'news':
        return 'task-news';
      default:
        return '';
    }
  };

  const myModal = (
    <div
      id='myModal'
      style={{ display: confirmation ? 'block' : 'none' }}
      className='modal'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <span
            onClick={() => {
              if (loading) {
                return;
              }
              setConfirmation(false);
            }}
            className='close'
          >
            &times;
          </span>
          {publishDate ? (
            <h2>
              You are able to schedule a Latest News for {publishDate}. This
              will go to the Home Page and Latest News feed for all users to
              view. Please go to “Latest News” to make any edits or changes
              to your article.
            </h2>
          ) : (
            <h2>
              You are about to publish a new Latest News. This will go to the
              Home Page and Latest News feed for all users to view.
            </h2>
          )}
        </div>
        {loading && (
          <LoadingContainer>
            <p>
              Your photo/video is now uploading. Please do not close this window
              while it uploads.
            </p>
            <FadeLoader color={Theme.primary} />
          </LoadingContainer>
        )}

        <div className='modal-confirmation'>
          <form>
            <>
              <button
                type='button'
                disabled={loading}
                style={{ opacity: loading ? 0.5 : 1 }}
                onClick={() => createAnnouncement()}
                className='cancelbtn'
              >
                Yes
              </button>

              <button
                type='button'
                disabled={loading}
                onClick={() => setConfirmation(false)}
                className='deletebtn'
              >
                No
              </button>
            </>
          </form>
        </div>
      </div>
    </div>
  );

  const insertImage = (imgUrl: string) => {
    const contentState = fullLegend.getCurrentContent();

    // Cria uma entidade para a imagem
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE', // Tipo da entidade
      'IMMUTABLE', // Tipo de mutabilidade
      { src: imgUrl, class: 'img-field' } // Dados associados
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Usa o AtomicBlockUtils para inserir a entidade no editor
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      fullLegend,
      entityKey,
      ' '
    );

    // Atualiza o estado do editor
    setFullLegend(
      EditorState.forceSelection(newEditorState, newEditorState.getSelection())
    );
    setImageUrl('');
  };

  const preview = (
    <PreviewContent
      style={{ display: myPreview ? 'block' : 'none', maxWidth: '100%' }}
    >
      <span onClick={() => setMyPreview(false)} className='close'>
        &times;
      </span>
      <h1>Title:</h1>
      <h1 className='primary'>{title}</h1>
      <hr />
      <h1>Preview:</h1>
      <h1 className='primary'>{prevLegend}</h1>
      <hr />
      <h2>Full Article: </h2>
      {fullLegend && (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: draftToHtml(convertToRaw(fullLegend.getCurrentContent()))
                .replace(/\n/g, '<br/>')
                .replace(/<p><\/p>/g, '<br/>')
            }}
            style={{
              lineHeight: 1.3,
              marginTop: 12
            }}
            className='full-legend'
          ></div>
        </>
      )}
    </PreviewContent>
  );
  const handleEventClick = (info: any) => {
    setSelectedEvent(info.event);

    if (info?.event.extendedProps?.type === 'news') {
      setIdLatestNews(info.event.id);
    }
  };
  const contentEvent = () => {
    if (!selectedEvent) return '';
    if (selectedEvent) {
      switch (selectedEvent.extendedProps.type) {
        case 'question':
          return (
            <div>
              <h1>Question</h1>
              <h2>{selectedEvent.title}</h2>
            </div>
          );
        case 'poll':
          return (
            <div>
              <h1>Poll</h1>
            </div>
          );
        case 'news':
          return <GridLatestNews id={selectedEvent.id} />;
        default:
          return <div></div>;
      }
    }
  };

  return (
    <MainContainer>
      <ContainerForm>
        <div className='new-announcement-latest'>
          <h1>FEG Latest News</h1>
          <div className='utils'>
            <FormControlLabel
              control={<Checkbox color='primary' />}
              label='Push Notification'
              color='primary'
              value={notifyMobile}
              onChange={() => setNotifyMobile(!notifyMobile)}
            />
            <FormControlLabel
              control={<Checkbox color='primary' />}
              label='Website Notification'
              color='primary'
              value={notifyDesktop}
              onChange={() => setNotifyDesktop(!notifyDesktop)}
            />
            <h4>Title</h4>
            <input
              type='text'
              name='nameInput'
              value={title}
              maxLength={250}
              onChange={(e) => setTitle(e.target.value)}
            />
            <h4>Preview Field</h4>
            <input
              type='text'
              name='nameInput'
              value={prevLegend}
              maxLength={250}
              onChange={(e) => setPrevLegend(e.target.value)}
            />
            <h4>Date Publish</h4>
            <input
              style={{ padding: 10 }}
              type='date'
              name='nameInput'
              value={publishDate}
              onChange={(e) => setPublishDate(e.target.value)}
            />
            <h4>Picture or Video</h4>
            <label
              htmlFor='video-upload'
              style={{
                padding: 10,
                background: `#efefef`,
                color: '#4c4c4c',
                borderRadius: 4,
                marginRight: 10,
                cursor: 'pointer'
              }}
            >
              Choose a video or photo to upload:
            </label>

            {file?.name}
            <input
              id='video-upload'
              type='file'
              onChange={(file) => {
                handleFileChange(file);
              }}
            />
            <p style={{ marginTop: 15 }}>
              The system does not accept files larger than 1TB.
            </p>
            {file?.type === 'video/mp4' && (
              <>
                <h4>Thumbnail</h4>
                <label
                  htmlFor='thumbnail'
                  style={{
                    padding: 10,
                    background: `#efefef`,
                    color: '#4c4c4c',
                    borderRadius: 4,
                    marginRight: 10,
                    cursor: 'pointer'
                  }}
                >
                  Choose photo to upload:
                </label>

                {thumbnail?.name}
                <input
                  id='thumbnail'
                  type='file'
                  accept='image/*'
                  onChange={(file) => {
                    handleThumbnail(file);
                  }}
                />
              </>
            )}

            <h4>Full Article</h4>
            <ExportContainerImage>
              <Editor
                editorState={fullLegend}
                wrapperClassName='demo-wrapper'
                stripPastedStyles={true}
                toolbarCustomButtons={[
                  <>
                    <label
                      htmlFor='image-upload-url'
                      style={{
                        padding: 0,

                        borderRadius: 4,
                        margin: 3,
                        cursor: 'pointer'
                      }}
                    >
                      {<CiImageOn size={20} />}
                    </label>

                    <input
                      id='image-upload-url'
                      type='file'
                      onChange={(file) => {
                        handleFileImageUrl(file);
                      }}
                    />
                  </>
                ]}
                editorClassName='demo-editor'
                onEditorStateChange={(e) => {
                  setFullLegend(e);
                }}
              />
            </ExportContainerImage>
          </div>
          <div className='divisor-buttons'>
            <button
              onClick={() => {
                setMyPreview(myPreview ? false : true);
              }}
            >
              Preview Content
            </button>
            <button className='button' onClick={() => setConfirmation(true)}>
              Publish
            </button>
          </div>
          {myModal}
          {preview}
        </div>
      </ContainerForm>
      {!view && (
        <CallendarFull>
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            eventClick={handleEventClick}
            events={formatedEvents()}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            eventClassNames={getEventClassNames}
            timeZone='US/Central'
            handleWindowResize={true}
            displayEventTime={false}
          />
          <div className='event-content'>{contentEvent()}</div>
        </CallendarFull>
      )}
    </MainContainer>
  );
};

export default LatestNews;
