import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';
export const MainContainer = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 auto;
  width: 86%;
  @media (max-width: 768px) {
    gap: 0;
  }
`;
export const PreviewContent = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  padding: 0;
  h1 {
    margin: 0;
    padding: 0;
    color: ${Theme.primary};
  }
  h1.primary {
    color: ${Theme.grey};
  }

  img {
    max-width: 300px;
    object-fit: cover;
  }
`;
export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  color: ${Theme.primary};
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  p {
    margin-bottom: 20px;
  }
`;
export const ContainerForm = styled.div`
  width: 40%;
  .new-announcement-latest {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: 100%;

    height: 100%;
    background: white;
    border-radius: 20px;

    h1 {
      font-weight: bold;
      color: ${Theme.primary};
      margin-left: 2rem;
      margin-top: 1rem;
    }

    .utils {
      margin-left: 2rem;

      input[type='file'] {
        display: none;
      }

      .imageInput label {
        cursor: pointer;
        align-items: center;
        border-radius: 10px;
        font-size: 20px;
        background-color: #efefef;
        color: #4c4c4c;
        padding: 7px 150px;
      }

      .imageInput p {
        color: $grey;
        margin: 0.7rem 0 0 0.2rem;
      }

      textarea {
        align-items: center;
        width: 100%;
        max-width: 450px;
        min-height: 20rem;
        box-sizing: border-box;
        border: 1px solid transparent;
        border-radius: 10px;
        font-size: 16px;
        background-color: #efefef;
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding: 5px 20px 12px 40px;
      }

      h4 {
        margin: 1rem 0;
      }
    }

    .utils input[type='text'],
    .typefile {
      align-items: center;
      width: 100%;
      max-width: 500px;
      height: 2.5rem;
      box-sizing: border-box;
      border: 1px solid transparent;
      border-radius: 10px;
      font-size: 16px;
      background-color: #efefef;
      background-position: 10px 10px;
      background-repeat: no-repeat;
      padding: 5px 20px 8px 10px;
    }

    button {
      border-radius: 10px !important;
      border: 0px;
      padding: 1rem;
      background: ${Theme.primary};
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      transition: 0.2s;
      margin: 0.7rem 0;
      margin-left: 2rem;
    }

    button:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
  .create-announcement {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-left: 2rem;
  }

  .create-announcement-everyone {
    align-self: flex-start;
  }

  .specific-groups {
    width: 22%;

    .MuiFormControl-root {
      width: 40%;
    }
  }

  .MuiFormControlLabel-root {
    margin-top: 16px;
  }

  .new-announcement-latest .modal {
    display: none;
    position: fixed;
    z-index: 2;
    padding-top: 150px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: #0000001a;
  }

  .new-announcement-latest .modal-content {
    position: relative;
    background-color: #fefefe;
    border-radius: 20px;
    margin: auto;
    padding: 0;
    width: 50%;
    animation-name: animatetop;
    animation-duration: 0.5s;

    form .export {
      width: 100%;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      height: 50%;
      border-radius: 15px !important;
      border: 0px;
      padding: 1rem !important;
      background: $bg-color;
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      transition: 0.2s;
      margin: 5rem 0;
    }

    button:hover {
      opacity: 0.5;
    }

    .deletebtn button {
      background-color: gray;
      cursor: pointer;
    }
  }

  .new-announcement-latest .modal-header {
    padding: 40px 50px;
    background-color: $bg-color;
    color: white;
    border-radius: 20px 20px 0 0;
    text-align: center;

    h2 {
      font-weight: 300;
    }
  }

  .new-announcement-latest .modal-confirmation form {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .rdw-editor-wrapper {
    width: 100%;
    max-width: 500px;
  }

  .rdw-editor-main {
    background: #efefef;
    border-radius: 10px;
    border: 1px solid transparent;
    min-height: 150px;
  }

  .rdw-link-modal,
  .rdw-embedded-modal {
    min-height: 250px;
  }

  .rdw-image-modal-btn,
  .rdw-embedded-modal-btn,
  .rdw-link-modal-btn {
    margin: 0 5px;
    width: 100% !important;
    height: 30px;
    border: 1px solid #f1f1f1;
    margin-left: 0 !important;
    border-radius: 2px;
    padding: 0 !important;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
  }

  @media (max-width: 700px) {
    .new-announcement-latest {
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 1rem;
      width: 100%;

      h1 {
        margin: 0;
      }

      button {
        margin: 0;
        width: 80%;
      }

      .utils {
        input[type='file'] {
          width: 30%;
        }
        textarea {
          width: 90%;
        }
        input[type='file'] {
          width: 90%;
        }
      }
    }

    .create-announcement {
      width: 90%;
      margin: 0;
    }

    .new-announcement-latest .utils input[type='text'],
    .typefile {
      width: 90%;
    }

    .specific-groups {
      margin-left: 1rem;
      margin-top: 0;
      width: 100%;
    }

    .rdw-editor-wrapper {
      width: 100%;
    }

    .rdw-editor-main {
      margin: 1rem 0;
    }

    .rdw-image-modal-btn,
    .rdw-embedded-modal-btn,
    .rdw-link-modal-btn {
      margin-left: 0 !important;
      width: 75px;
      height: 30px;
      border: 1px solid #f1f1f1;
      border-radius: 2px;
      padding: 0 !important;
      cursor: pointer;
      background: white;
      text-transform: capitalize;
    }
  }

  @media (max-width: 500px) {
    .new-announcement-latest .modal-content {
      width: 90%;
    }

    .new-announcement-latest .modal {
      padding-top: 60px;
    }

    .new-announcement-latest .utils {
      margin: 0;

      label {
        font-size: 15px;
      }
    }
  }
`;
export const CallendarFull = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
  .fc {
    width: 100%;
    height: 100%;
    max-height: 600px;
  }

  .task-question {
    width: 100%;
    background: #1260a7;
    font-weight: bold;
    border: none;
    padding: 5px;
    color: white;
  }
  .task-poll {
    width: 100%;
    background: #b400bd;
    border: none;
    font-weight: bold;
    padding: 5px;
  }
  .task-news {
    width: 100%;
    background: #e7262b;
    color: white;
    border: none;
    font-weight: bold;
    padding: 5px;
  }
  .fc-daygrid-event-dot {
    display: none;
  }
`;
export const DivisorScoreboard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  div.score {
    width: 50%;
    height: 100%;
    background-color: #f8f9fa;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
export const ExportContainerImage = styled.div`
  img {
    max-width: 300px;
    height: auto;
  }
  .rdw-image-wrapper {
    display: none !important;
  }
`;
