import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ActiveShortcuts from '../../components/Shortcuts';
import InactiveShortcuts from '../../components/Shortcuts/InactivesPage';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';
import { Theme } from 'src/styles/Theme';

const Shortcuts: React.FC = () => {
  const [btn, setBtn] = useState({
    content: 1,
    btnActive: Theme.primary,
    btnInactive: ''
  });
  const [permissions, setPermissions] = useState({
    view: false,
    add: false,
    edit: false,
    delete: false
  });
  const [cookies, ,] = useCookies(['token']);
  const history = useHistory();
  const token = cookies.token;

  useEffect(() => {
    handlePermissions();
  }, []);

  const handlePermissions = () => {
    if (token) {
      jwt.verify(
        token.toString(),
        '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
        (err: any, decoded: any) => {
          if (err) {
            history.push('/');
          } else {
            if (decoded.roles.shortcuts.view !== true) {
              alert('Insufficient Permissions');
              history.push('/');
            } else {
              setPermissions(decoded.roles.shortcuts);
            }
          }
        }
      );
    }
  };

  function changeContent() {
    if (btn.content === 1) {
      return <ActiveShortcuts />;
    }
    if (btn.content === 2) {
      return <InactiveShortcuts />;
    }
  }

  return (
    <div className='announcement-types'>
      <div className='types-sidebar'>
        <button
          className={`btn-custom ${btn.content === 1 && 'active'}`}
          style={{ backgroundColor: btn.btnActive }}
          onClick={() =>
            setBtn({
              content: 1,
              btnActive: Theme.primary,
              btnInactive: ''
            })
          }
        >
          Active Apps
        </button>
        <button
          className={`btn-custom ${btn.content === 2 && 'active'}`}
          style={{
            backgroundColor: btn.btnInactive,
            display: permissions.delete ? 'flex' : 'none'
          }}
          onClick={() =>
            setBtn({
              content: 2,
              btnActive: '',
              btnInactive: Theme.primary
            })
          }
        >
          Inactive Apps
        </button>
      </div>
      <div className='types-content'>{changeContent()}</div>
    </div>
  );
};

export default Shortcuts;
