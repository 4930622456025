import React, { useEffect, useState } from 'react';
import { Container, MainContainer } from './styled';
import axios, { api } from '../../services/axios';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

interface ShortcutInterface {
  id: string;
  name: string;
  display_name1: string;
  display_name2: string;
  desktop_image: string;
  mobile_image: string;
  link_desktop: string;
  link_mobile: string;
  createdAt: string;
  updatedAt?: Date;
  order: number;
  group_ids: string[];
  favorite: boolean;
}
export const CardFav = (props: any): JSX.Element => {
  const [shortcuts, setShortcuts] = useState<ShortcutInterface[]>([]);
  const [favShortcut, setFavShortcut] = useState<string[]>([]);

  function filterArrFav(arrFav: ShortcutInterface[]) {
    const arrayFav = arrFav.filter((item) => item.favorite === true);
    return arrayFav;
  }

  function getShortcuts() {
    const customer_id = localStorage.getItem('id');
    axios.get(`/api/v1/shortcuts`).then((shortcutsResponse) => {
      setShortcuts(shortcutsResponse.data);
    });
    axios
      .get(`/api/v1/accounts/favorite_shortcut/${customer_id}`)
      .then((shortcutsResponse) => {
        setFavShortcut(shortcutsResponse.data);
      });
  }
  function favoriteShortCurt(short: ShortcutInterface) {
    const target: any = shortcuts.find((shortcut) => shortcut.id === short.id);

    if (target) {
      target.mobile_image = {
        selectedFile: '',
        type: ''
      };
      target.desktop_image = {
        selectedFile: '',
        type: ''
      };
      const customer_id = localStorage.getItem('id');
      api
        .put(`/api/v1/accounts/${customer_id}/favorite_shortcut`, target)
        .then((res) => {
          axios.get(`/api/v1/shortcuts`).then((shortcutsResponse) => {
            setShortcuts(shortcutsResponse.data);
          });
          axios
            .get(`/api/v1/accounts/favorite_shortcut/${customer_id}`)
            .then((shortcutsResponse) => {
              setFavShortcut(shortcutsResponse.data);
            });
        })
        .catch((e) => console.log(e));
    }
  }
  useEffect(() => {
    getShortcuts();
  }, []);

  function renderFav() {
    const result = shortcuts.filter((element) =>
      favShortcut?.includes(element.id)
    );
    return result;
  }
  return (
    <MainContainer>
      <div className='title-fav'>
        <h1>FEG Apps</h1>
      </div>
      <Container>
        {renderFav()?.map((item) => {
          return (
            <div key={Math.random()} className='list'>
              <FaHeart color='purple' onClick={() => favoriteShortCurt(item)} />

              <img src={item.desktop_image} alt='FEG LOGO' />
              <p> {item.display_name1}</p>
              <button onClick={() => window.open(item.link_desktop, '_blank')}>
                login
              </button>
            </div>
          );
        })}
      </Container>
    </MainContainer>
  );
};
