import React, { useState, useEffect } from 'react';
import Company from './AnnouncementList';

import jwt from 'jsonwebtoken';
import { useCookies } from 'react-cookie'
import { DivContainer } from './style';
import GridLatestNews from '../GridLatestNews';
import BenefitsComp from '../Benefits';
import Newsletter from '../Newsletter';
import GridNewsletter from '../GridNewsletter';

const Announcements: React.FC = () => {
    const [permissions, setPermissions] = useState(false);
    const [permissions2, setPermissions2] = useState({
        view: false,
        add: false,
        edit: false,
        delete: false,
    })
    const [btn, setBtn] = useState(
        {
            content: 6,
            btnCompany: '',
            btnLocal: '',
            btnGroup: '',
            btnOthers: '',
            btnLatestNews: "#fff",
            btnCommunications: '',
            btnBenefits: "",
            btnNewsletter: ""
        },
    );
    const [cookies, ,] = useCookies(["token"]);
    const token: any = cookies.token

    useEffect(() => {
        handlePermissions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handlePermissions() {
        if (localStorage.getItem('token')) {
            jwt.verify(localStorage.getItem('token')!.toString(), '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb', (err: any, decoded: any) => {
                if (err) {
                    alert(err)
                } else {
                    setPermissions(decoded.modules.announcements.write)
                    setPermissions2(decoded.roles.announcements)
                }
            })
        } else {
            jwt.verify(token!.toString(), '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb', (err: any, decoded: any) => {
                if (err) {
                    alert(err)
                } else {
                    setPermissions(decoded.modules.announcements.write)
                    setPermissions2(decoded.roles.announcements)
                }
            })
        }
    }

    function changeContent() {
        console.log('a')
        if (btn.content === 1) {
            return <Company link="company" type="companies" description="General" />
        }
        if (btn.content === 2) {
            return <Company link="local" type="locations" description="Local" />
        }
        if (btn.content === 3) {
            return <Company link="group" type="groups" description="Group" />
        }
        if (btn.content === 4) {
            return <Company link="others" type="others" description="Others" />
        }
        if (btn.content === 5) {
            return <Company link="company" type="paylocity_announcements" description="Paylocity Announcements" />
        }

        if (btn.content === 6) {
            return <GridLatestNews />
        }
        if (btn.content === 7) {
            return <BenefitsComp />
        }
        if (btn.content === 8) {
            return <GridNewsletter />
        }
    }

    return (
        <div className="announcements">
            <DivContainer>
                <button className={`LatestNews`} id={`${btn.content === 6 ? 'active' : ""}`} style={{ display: permissions2.view ? "" : "none", color: btn.btnLatestNews }} onClick={() => setBtn({ content: 6, btnCompany: '', btnLocal: '', btnGroup: '', btnOthers: '', btnCommunications: '', btnLatestNews: '#fff', btnBenefits: "", btnNewsletter: "" })}>FEG Latest News</button>
                <button className="GeneralAnnouncement" id={`${btn.content === 1 ? 'active' : ""}`} onClick={() => setBtn({ content: 1, btnCompany: '#fff', btnLocal: '', btnGroup: '', btnOthers: '', btnCommunications: '', btnLatestNews: '', btnBenefits: "", btnNewsletter: "" })}>General Announcements</button>
                <button className="LocalAnnouncement" id={`${btn.content === 2 ? 'active' : ""}`} style={{ display: permissions2.view ? "" : "none", color: btn.btnLocal }} onClick={() => setBtn({ content: 2, btnCompany: '', btnLocal: '#fff', btnGroup: '', btnOthers: '', btnCommunications: '', btnLatestNews: '', btnBenefits: "", btnNewsletter: "" })}>Local Announcements</button>
                <button className={`GroupAnnouncement`} id={`${btn.content === 3 ? 'active' : ""}`} style={{ display: permissions2.view ? "" : "none", color: btn.btnGroup }} onClick={() => setBtn({ content: 3, btnCompany: '', btnLocal: '', btnGroup: '#fff', btnOthers: '', btnCommunications: '', btnLatestNews: '', btnBenefits: "", btnNewsletter: "" })}>Group Announcements</button>
                <button className={`OthersAnnouncement`} id={`${btn.content === 4 ? 'active' : ""}`} style={{ display: permissions2.view ? "" : "none", color: btn.btnOthers }} onClick={() => setBtn({ content: 4, btnCompany: '', btnLocal: '', btnGroup: '', btnOthers: '#fff', btnCommunications: '', btnLatestNews: '', btnBenefits: "", btnNewsletter: "" })}>Others Announcements</button>
                <button className={`FEGGeneralCommunications`} id={`${btn.content === 5 ? 'active' : ""}`} style={{ display: permissions2.view ? "" : "none", color: btn.btnCommunications }} onClick={() => setBtn({ content: 5, btnCompany: '', btnLocal: '', btnGroup: '', btnOthers: '', btnCommunications: '#fff', btnLatestNews: '', btnBenefits: "", btnNewsletter: "" })}>FEG General Communications</button>
                <button className={`LatestNews`} id={`${btn.content === 7 ? 'active' : ""}`} style={{ display: permissions2.view ? "" : "none", color: btn.btnBenefits }} onClick={() => setBtn({ content: 7, btnCompany: '', btnLocal: '', btnGroup: '', btnOthers: '', btnCommunications: '', btnLatestNews: '', btnBenefits: "#fff", btnNewsletter: "" })}>Benefits Announcements</button>
                <button className={`LatestNews`} id={`${btn.content === 8 ? 'active' : ""}`} style={{ display: permissions2.view ? "" : "none", color: btn.btnNewsletter }} onClick={() => setBtn({ content: 8, btnCompany: '', btnLocal: '', btnGroup: '', btnOthers: '', btnCommunications: '', btnLatestNews: '', btnBenefits: "", btnNewsletter: "#fff" })}>Newsletter</button>
            </DivContainer>
            <div className="announcements-content">
                {
                    changeContent()
                }
            </div>
        </div>
    )
}

export default Announcements;
