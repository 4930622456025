import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { QuestionContainer, PontuationContainer } from './styled';
import { Theme } from 'src/styles/Theme';
import api from 'src/services/axios';
interface Options {
  option: string;
  id: string;
  count?: number;
  percentage?: string;
}
interface Option {
  id: number | string;
  text: string;
}

interface Polls {
  id: string;
  polls_answer: string;
  options: Options[];
  date_start: string;
  date_end: string;
  totalUsers?: number;
}
const Card = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  gap: 10px;
  padding: 18px;
  width: 100%;
  margin-bottom: 15px;
`;
interface ProgressProps {
  percent: number;
}
const Progress = styled.div<ProgressProps>`
  height: 8px;
  background-color: #4caf50;
  width: ${({ percent }) => percent}%;
  transition: width 0.3s ease;
`;

const ContainerTaskDescription = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    text-align: center;
    justify-content: center;
    align-self: center;
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 8px;
  &.correct {
    border: 2px solid ${Theme.primary};
  }
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
`;

const ProgressBar = styled.div`
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  width: 94%;
  margin: 0 auto;
`;

export default function ViewPoll({
  selectedPoll,
  handleEditPoll
}: {
  selectedPoll: Polls | undefined;
  handleEditPoll: () => void;
}) {
  const [usersPontuation, setUsersPontuation] = useState<any>([]);
  const handleDeletePoll = () => {
    if (selectedPoll) {
      api.delete(`/api/v1/polls/delete/${selectedPoll.id}`).then(() => {
        alert('Poll Deleted');
      });
    }
  };
  const getUsersPontuation = () => {
    api
      .get(`/api/v1/polls/find/${selectedPoll?.id}`)
      .then((response) => {
        setUsersPontuation(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (!selectedPoll) return;
    getUsersPontuation();
  }, [selectedPoll]);
  const optionUser = (id: string) => {
    const index = selectedPoll?.options.findIndex((obj) => obj.id === id);
    switch (index) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
      case 4:
        return 'E';
      default:
        return 'A';
    }
  };
  return (
    <QuestionContainer>
      <Card>
        <motion.h1 animate={{ opacity: [0.5, 1] }}>
          {selectedPoll?.polls_answer} <br />
        </motion.h1>

        {selectedPoll?.options.map((option, index) => (
          <Item key={option.id}>
            <ContainerTaskDescription>
              <Label style={{ fontWeight: 'bold' }}>
                <p style={{ fontSize: 18, marginLeft: 20 }}>{option.option}</p>
              </Label>
              <span style={{ fontWeight: 'bold' }}>
                {option.percentage?.split('.')[0]}%
              </span>
            </ContainerTaskDescription>
            <ProgressBar>
              <Progress
                style={{
                  background: '#ccc'
                }}
                percent={Number(option.percentage?.split('.')[0]) || 0}
              />
            </ProgressBar>
          </Item>
        ))}
      </Card>

      <div className='btn-actions'>
        <button onClick={handleEditPoll} className='btn'>
          Update Poll
        </button>
        <button onClick={() => handleDeletePoll()} className='btn-delete'>
          Delete Poll
        </button>
      </div>
      <h1>Total Votes: {usersPontuation.length}</h1>
      {usersPontuation.length > 0 && (
        <PontuationContainer>
          <div className='scoreboard-container'>
            <table className='score-table'>
              <thead>
                <tr>
                  <th>USER NAME</th>
                  <th>ANSWER</th>
                </tr>
              </thead>
              <tbody>
                {usersPontuation.map(
                  (
                    user: {
                      url_image: string;
                      full_name: string;
                      response_user_polls_id: string;
                    },
                    index: number
                  ) => (
                    <motion.tr
                      key={Math.random()}
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1, duration: 0.5 }}
                    >
                      <motion.td>
                        <div className='box-profile'>
                          <img src={user?.url_image} alt='' />
                          <p> {user?.full_name}</p>
                        </div>
                      </motion.td>
                      <motion.td>
                        {optionUser(user.response_user_polls_id)}
                      </motion.td>
                    </motion.tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </PontuationContainer>
      )}
    </QuestionContainer>
  );
}
