export const HOME = '/home';
export const DEFAULT = '/*';
export const USER = '/user';
export const MYPROFILE = '/profile';
export const PROFILE = '/user/:id';
export const ANNOUNCEMENTPAYLOCITY = '/ap/:id/:userId';
export const ANNOUNCEMENTBENEFITS = '/ab/:id/:contactPhone';
export const ANNOUNCEMENTBENEFITSALL = '/ab/:id';
export const ANNOUNCEMENTS = '/announcements';
export const NEWANNOUNCEMENT = '/announcements/new';
export const ANNOUNCEMENTLOCAL = '/announcements/local/:id';
export const ANNOUNCEMENTOTHERS = '/announcements/others/:id';
export const ANNOUNCEMENTLATESTNEWS = '/announcements/latest-news/:id';
export const ANNOUNCEMENTGROUP = '/announcements/group/:id';
export const ANNOUNCEMENTCOMPANY = '/announcements/company/:id';
export const MANAGEMENT = '/management';
export const CALENDAR = '/calendar';
export const NEWHOME = '/';
export const ANALYTICS = '/analytics';
export const SPECIALEVENT = '/special-event/:id';
export const LOCATIONMANAGEMENT = '/locations';
export const SHORTCUTMANAGEMENT = '/shortcuts';
export const PERMISSIONMANAGEMENT = '/permissions';
export const MAPJOBTITLE = '/mapJobtitle';
export const CHATBOT = '/chat-fegpt';
export const HOMENEWDESIGN = '/new-home';
export const SHORTCUTS = '/list-shortcuts';
export const SETTINGSCHATBOT = '/settings-fegpt';
export const REPOCHATBOT = '/knowledgebase';
export const CARDCHATBOT = '/knowledgebase/:idParam';
export const USERLOCATIONSCHAT = '/locations/chat/:id';
export const LOGCASKFEG = '/chat/logs';
export const TRIVIA = '/trivia';
export const ADMINTRIVIA = '/admin-trivia';
export const NEWCALENDAR = '/new-calendar';
export const NOTIFYDASHBOARD = '/notify-dashboard';
