import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Monograma from '../../images/logoChat.jpeg';
import { Theme } from 'src/styles/Theme';
import Drawer from '../../components/Drawer';
import api from 'src/services/axios';
import { ToastContainer, toast } from 'react-toastify';
import { formaterTextSlug } from 'src/utils/formatSlug';
import 'react-toastify/dist/ReactToastify.css';
import { BeatLoader, CircleLoader, SyncLoader } from 'react-spinners';
// Then use it like this (assuming 'cloneElement' is the default export):

const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 70%;
  margin: 0 auto;
  margin-top: 50px;

  padding-bottom: 20px;
  margin-bottom: 40px;
  min-height: 800px;
  hr {
    margin-top: 10px;
  }
  @media (max-width: 500px) {
    width: 100%;
    margin-top: 20px;
  }
`;
const Header = styled.div`
  width: 100%;
  background-color: ${Theme.primary};
  text-align: center;
  padding: 20px;
  color: ${Theme.secondary};

  div {
    .mySelect {
      font-size: 18px;
      color: ${Theme.primary};
      padding: 3px;
      background-color: #fff;
      border: none;
    }
    .myOption {
      color: green;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 56px;
  background-color: ${Theme.light};

  height: max-content;
  @media (max-width: 500px) {
    width: 100%;
    margin-top: 20px;
  }
  button {
    width: 100px;
    background-color: ${Theme.secondary};
    color: ${Theme.primary};
    border: none;
    font-weight: bold;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
`;

const DrawerContent = styled.div`
  padding: 28px 0;
  display: flex;
  flex-direction: column;
  .inp-text {
    padding: 10px;
  }
  .btn-choose {
    width: 100%;
  }
`;

const Paragrah = styled.p`
  margin: 8px 0;
  font-size: 16px;
`;

const InputTextTitle = styled.input`
  padding: 10px;
  width: 160px;
  border: none;
  background-color: #9f9f9fcc;
`;
interface Categories {
  id: string;
  name: string;
  slug: string;
}
const Modal = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  background-color: #9f9f9fcc;
  div.content {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 500px;
    margin: 0 auto;
    background-color: #fff;
    height: 200px;
    border-radius: 14px;
    margin-top: 80px;
    padding: 0;
    .title {
      width: 100%;
      background-color: ${Theme.primary};
      color: white;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      padding: 5px;
    }
    p {
      padding: 20px;
    }
    div.buttons-container {
      gap: 10px;

      padding: 10px;

      button {
        border: none;
        padding: 10px;
        cursor: pointer;
      }
      .closed {
        background-color: ${Theme.primary};
        color: white;
      }
      .delete {
        margin-left: 20px;
        background-color: red;
        color: white;
      }
    }
  }
`;

const App: React.FC = () => {
  const [openFile, setOpenFile] = useState(false);
  const [openText, setOpenText] = useState(false);
  const [openUrl, setOpenUrl] = useState(false);
  const [openNewRepo, setOpenNewRepo] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [errCheckRepository, setErrCheckRepository] = useState(false);
  const [newRepositoryInpt, setNewRepositoryInpt] = useState('');
  const [errLocal, setErrLocal] = useState('');
  const [categories, setCategories] = useState<Categories[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [filenameInput, setFilenameInput] = useState('');
  const [textInput, setTextInput] = useState('');
  const [url, setUrl] = useState('');
  const [urlLoading, setUrlLoading] = useState(false);
  const [textLoading, setTextLoading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setErrCheckRepository(false);
    setSelectedValue(e.target.value);
  };

  function checkRepository(local: string) {
    if (selectedValue === '') {
      setErrLocal(local);
      return setErrCheckRepository(true);
    }

    if (local === 'FILE') {
      return uploadFileFunc();
    } else if (local === 'TEXT') {
      return uploadTextFunc();
    } else if (local === 'URL') {
      return uploadUrl();
    }
  }
  function uploadTextFunc() {
    setTextLoading(true);
    if (textInput === '' || filenameInput === '') {
      toast.error('fill in all fields');
      return;
    }
    api
      .post('https://openai.fegsystem.com/documents', {
        category_id: selectedValue,
        text: textInput,
        filename: filenameInput
      })
      .then((r) => {
        setTextLoading(false);
        setTextInput('');
        setFilenameInput('');
        toast.success(
          'Success! Your Text is currently being indexed. You can view the status of the upload in the repository.'
        );
      })
      .catch((e) => {
        setTextLoading(false);
        toast.error('err');
      });
  }
  function uploadFileFunc() {
    if (!selectedFile) {
      console.error('Nenhum arquivo selecionado');
      return;
    }
    setLoadingUploadFile(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('category_id', selectedValue);
    formData.append('timeout', '0');

    api
      .post('https://openai.fegsystem.com/documents', formData)
      .then((r) => {
        setSelectedFile(null);
        toast.success(
          'Success! Your Document is currently being indexed. You can view the status of the upload in the repository.'
        );
        setLoadingUploadFile(false);
      })
      .catch((e) => {
        toast.error('Err');
        setLoadingUploadFile(false);
      });
  }
  function uploadUrl() {
    setUrlLoading(true);
    api
      .post(
        'https://openai.fegsystem.com/documents',
        {
          category_id: selectedValue,
          url: url
        },
        { timeout: 0 }
      )
      .then((r) => {
        setUrlLoading(false);
        toast.success(
          'Success! Your Scribe link is currently being indexed. You can view the status of the upload in the repository.'
        );
        setUrl('');
      })
      .catch((e) => {
        setUrlLoading(false);
        toast.error('err');
      });
  }
  function getCategories() {
    api
      .get('https://openai.fegsystem.com/categories?per_page=1000', {
        timeout: 0
      })
      .then((r) => {
        setCategories(r.data.data);
      });
  }

  function addRepository() {
    api
      .post('https://openai.fegsystem.com/categories', {
        name: newRepositoryInpt,
        slug: formaterTextSlug(newRepositoryInpt)
      })
      .then((r) => {
        toast.success('New Knowledge Base Created');
        setNewRepositoryInpt('');
        getCategories();
      });
  }

  useEffect(() => {
    getCategories();
  }, []);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files[0]);
      setSelectedFile(event.target.files[0]);
    }
  };

  return (
    <Container>
      <ToastContainer />
      <Container>
        <Header>
          <h1>Add Documents Ask FEG</h1>
        </Header>
        <Content>
          <Drawer
            open={openFile}
            title='Add a single document to the knowledge base'
            setOpen={() => {
              setErrCheckRepository(false);
              setOpenFile(!openFile);
            }}
          >
            <hr />
            <DrawerContent>
              <Paragrah>
                Upload a document to add it to the knowledge base
              </Paragrah>
              <input type='file' onChange={handleFileChange} />

              <button
                onClick={() => checkRepository('FILE')}
                disabled={loadingUploadFile}
                style={{ backgroundColor: `${Theme.light}` }}
              >
                {loadingUploadFile ? (
                  <BeatLoader size={11} color={`${Theme.primary}`} />
                ) : (
                  'Submit'
                )}
              </button>
              <Paragrah>
                Your document will be saved in:{' '}
                {categories ? categories[Number(selectedValue) - 1]?.name : ''}
                {errCheckRepository && errLocal === 'FILE' && (
                  <div>
                    <p
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                        marginTop: 12,
                        marginBottom: 0
                      }}
                    >
                      You need to choose a repository before adding a document.
                    </p>
                    <button
                      className='btn-choose'
                      onClick={() => setOpenNewRepo(true)}
                      style={{ backgroundColor: `${Theme.light}` }}
                    >
                      click here to choose a Knowledge Base.
                    </button>
                  </div>
                )}
              </Paragrah>
            </DrawerContent>
          </Drawer>

          {/*
          urlLoading && (
            <Modal>
              <div className='content'>
                <div className='title'>
                  <h1>Loading</h1>
                </div>
                <p>
                  Processing your submitted URL. Please wait patiently while we
                  complete the indexing.
                </p>
                <SyncLoader color={`${Theme.primary}`} />
              </div>
            </Modal>
          )*/}

          <Drawer
            open={openText}
            title='Add text to the knowledge base'
            setOpen={() => {
              setErrCheckRepository(false);
              setOpenText(!openText);
            }}
          >
            <hr />
            <DrawerContent>
              <Paragrah>
                Add new text content and click on the button submit
              </Paragrah>
              <p>Filename</p>
              <InputTextTitle
                type='text'
                value={filenameInput}
                onChange={(e) => setFilenameInput(e.target.value)}
              />
              <p>Content</p>
              <textarea
                name=''
                id=''
                cols={30}
                rows={10}
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
              ></textarea>
              <button
                onClick={() => checkRepository('TEXT')}
                disabled={textLoading}
                style={{ backgroundColor: `${Theme.light}` }}
              >
                {textLoading ? (
                  <BeatLoader size={11} color={`${Theme.primary}`} />
                ) : (
                  'Submit'
                )}
              </button>
              <Paragrah>
                Your document will be saved in:{' '}
                {categories ? categories[Number(selectedValue) - 1]?.name : ''}
              </Paragrah>
              {errCheckRepository && errLocal === 'TEXT' && (
                <div>
                  <p
                    style={{
                      color: 'red',
                      fontWeight: 'bold',
                      marginTop: 12,
                      marginBottom: 0
                    }}
                  >
                    You need to choose a repository before adding a document.
                  </p>
                  <button
                    className='btn-choose'
                    onClick={() => setOpenNewRepo(true)}
                    style={{ backgroundColor: `${Theme.light}` }}
                  >
                    click here to choose a repository.
                  </button>
                </div>
              )}
            </DrawerContent>
          </Drawer>

          <Drawer
            open={openUrl}
            title='Add URLs to the knowledge base'
            setOpen={() => {
              setErrCheckRepository(false);
              setOpenUrl(!openUrl);
            }}
          >
            <hr />
            <DrawerContent>
              <Paragrah>
                Add a URL, select your Knowledge Base and click on Submit
              </Paragrah>
              <input
                className='inp-text'
                type='text'
                placeholder='Paste your URL'
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
              <button
                onClick={() => checkRepository('URL')}
                disabled={urlLoading}
                style={{ backgroundColor: `${Theme.light}` }}
              >
                {urlLoading ? (
                  <BeatLoader size={11} color={`${Theme.primary}`} />
                ) : (
                  'Submit'
                )}
              </button>
              <Paragrah>
                Your document will be saved in:{' '}
                {categories ? categories[Number(selectedValue) - 1]?.name : ''}
              </Paragrah>
              {errCheckRepository && errLocal === 'URL' && (
                <div>
                  <p
                    style={{
                      color: 'red',
                      fontWeight: 'bold',
                      marginTop: 12,
                      marginBottom: 0
                    }}
                  >
                    You need to choose a knowledge base before adding a
                    document.
                  </p>
                  <button
                    className='btn-choose'
                    onClick={() => setOpenNewRepo(true)}
                    style={{ backgroundColor: `${Theme.light}` }}
                  >
                    click here to choose a repository.
                  </button>
                </div>
              )}
            </DrawerContent>
          </Drawer>

          <Drawer
            open={openNewRepo}
            title='Assign to Existing Knowledge Base'
            setOpen={() => setOpenNewRepo(!openNewRepo)}
          >
            <hr />
            <DrawerContent>
              <Paragrah>Assign to Existing Knowledge Base: </Paragrah>
              <Paragrah>
                <select
                  className='mySelect'
                  value={selectedValue}
                  onChange={handleChange}
                >
                  <option value='' disabled>
                    Select a option
                  </option>

                  {categories.map((category) => {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
                </select>
              </Paragrah>
              <Paragrah>Or</Paragrah>
              <input
                className='inp-text'
                value={newRepositoryInpt}
                onChange={(e) => setNewRepositoryInpt(e.target.value)}
                type='text'
                placeholder='Type the name of your new repository'
              />
              <button
                style={{ backgroundColor: `${Theme.light}` }}
                onClick={() => addRepository()}
              >
                Create New Knowledge Base
              </button>
            </DrawerContent>
          </Drawer>
        </Content>
      </Container>
    </Container>
  );
};

export default App;
