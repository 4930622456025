import { Theme } from 'src/styles/Theme';
import styled from 'styled-components';
export const MainContainer = styled.div`
  width: 90%;
  margin: 0 auto;

  div.itens {
    display: flex;
    gap: 20px;
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  gap: 16px;

  @media (max-width: 700px) {
    gap: 8px;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  svg {
    align-self: flex-end;
    cursor: pointer;
    margin-right: 15px;
    margin-top: 10px;
    position: absolute;
  }
  div.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 140px;
    background: ${Theme.light};
    border-radius: 14px;
    min-height: 130px;
    min-width: 140px;
    width: 100%;
    list-style-type: none;

    img {
      width: auto;
      max-width: 120px;
      min-width: 120px;
      margin-top: 16px;
      border: 2px solid ${Theme.primary};
      padding: 8px;
    }
    p {
      color: #151515;

      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      padding: 2px 5px;
    }
    button {
      width: 100%;
      padding: 8px;
      margin: 0;
      margin-top: 10px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      cursor: pointer;
      background-color: transparent;
      border: none;
      text-transform: uppercase;
      background-color: #ddd;
      color: ${Theme.primary};
      font-weight: bold;
    }
  }
`;
