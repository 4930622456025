import React, { useEffect, useState } from 'react';
import { CreateQuestionContainer } from './styled';
import api from 'src/services/axios';
import { set } from 'react-ga';

interface Option {
  id: number | string;
  text: string;
}

interface Options {
  option: string;
  id: string;
}
interface Question {
  id: string;
  question_answer: string;
  options: Options[];
  correct_option_id: string;
  date: string;
  active: boolean;
}

interface CreateQuestionProps {
  questionEdit?: Question;
}

export default function UpdateQuestion({
  questionEdit
}: CreateQuestionProps): JSX.Element {
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState<Option[]>([{ id: 1, text: '' }]);
  const [correctAnswer, setCorrectAnswer] = useState<number | null | string>(
    null
  );
  const [date, setDate] = useState('');

  const handleAddOption = () => {
    setOptions([...options, { id: options.length + 1, text: '' }]);
  };

  const handleOptionChange = (id: number | string, text: string) => {
    setOptions(options.map((opt) => (opt.id === id ? { ...opt, text } : opt)));
  };

  useEffect(() => {
    if (!questionEdit) return;
    setQuestion(questionEdit.question_answer || '');
    setOptions(
      questionEdit?.options.map((opt) => ({
        id: opt.id,
        text: opt.option
      }))
    );
    setCorrectAnswer(questionEdit?.correct_option_id || '');
    setDate(questionEdit?.date || '');
  }, [questionEdit]);

  const handleUpdateQuestion = () => {
    const updatedQuestion = {
      question: {
        id: questionEdit?.id,
        question_answer: question,
        options: options,
        correct_option_id: correctAnswer?.toString() || ''
      },
      date: date
    };
    console.log(updatedQuestion);

    api.post('/api/v1/trivia/update', updatedQuestion).then(() => {
      alert('Quizz Updated');
      window.location.reload();
    });
  };

  const clearInputs = () => {
    setQuestion('');
    setOptions([{ id: 1, text: '' }]);
    setCorrectAnswer(null);
    setDate('');
  };

  return (
    <CreateQuestionContainer>
      <h2>Trivia</h2>

      <div className='header-create-question'>
        <label>Question:</label>
        <input
          type='text'
          className='answer input-create-question'
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder='Type your question here'
        />
      </div>

      <div>
        <label>Answer Options:</label>
        {options.map((option, index) => (
          <div key={option.id} className='input-option'>
            <input
              type='text'
              value={option.text}
              className='input-create-question'
              onChange={(e) => handleOptionChange(option.id, e.target.value)}
              placeholder={`Option ${index + 1}`}
            />
            <input
              type='radio'
              name='correctAnswer'
              checked={correctAnswer === option.id}
              onChange={() => setCorrectAnswer(option.id)}
            />
          </div>
        ))}

        <button onClick={handleAddOption} className='btn-new-option'>
          Add New Option
        </button>
      </div>
      <div>
        <button
          onClick={clearInputs}
          style={{
            padding: '10px',
            marginTop: '20px',
            backgroundColor: '#ddd',
            color: 'white',
            border: 'none',
            marginRight: '10px',
            cursor: 'pointer',
            borderRadius: '8px'
          }}
        >
          Cancel
        </button>
        <button
          onClick={handleUpdateQuestion}
          style={{
            padding: '10px',
            marginTop: '20px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '8px'
          }}
        >
          Update Question
        </button>
      </div>
    </CreateQuestionContainer>
  );
}
